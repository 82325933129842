import React from 'react';
import { Typography, Grid, Divider, CircularProgress } from '@material-ui/core';
import PerfectScrollbar from "react-perfect-scrollbar";
import SocialDonutChart from "./SocialDonutChart";
import Analytics from "../analytics/analytics.component.js";
import Paper from "@material-ui/core/Paper";
import CountUp from "react-countup";
import GetAppIcon from '@material-ui/icons/GetApp';
import * as APIController from '../../../api/APIController.js';
import clicks from '../../../assets/images/clicks.svg';
import impressions from '../../../assets/images/impressions.svg';
import engagements from '../../../assets/images/engagements.svg';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import './DashboardStyle.css';
import DateRangePicker from 'react-daterange-picker'
import 'react-daterange-picker/dist/css/react-calendar.css' // For some basic styling. (OPTIONAL)
import DateRangeIcon from '@material-ui/icons/DateRange';
import moment from 'moment';
const Style = {
    txttitleStyle: {
        fontSize: '16px',
        letterSpacing: '0.3px',
        lineHeight: 1.6,
        textAlign: 'center',
        color: '#9E9E9E'
    },
    txtdesStyle: {
        fontSize: '22px',
        letterSpacing: '0.3px',
        lineHeight: 1.6,
        textAlign: 'center',
        // color: '#9E9E9E'

    },
    AdsNameBox: {
        padding: '10px',
        margin: '0px',
        width: '200px',
    },

    BoxStyle: {
        borderRadius: 5,
        border: '0.5px solid #1588ba',
        backgroundColor: '#fff',
        padding: '15px',
        margin: '0px',
        elevation: 4,
        boxShadow: '1px 4px 2px #9E9E9E'
    },
    BoxStyle1: {
        borderRadius: 5,
        border: '0.5px solid #0f0f',
        backgroundColor: '#fff',
        padding: '10px',
        margin: '7.5px',
        elevation: 4,
        boxShadow: '1px 4px 2px #9E9E9E'
    },
    listStyle: {
        fontSize: '15px',
        letterSpacing: '0.3px',
        lineHeight: 1.6,
        textAlign: 'center',
        color: '#0A0A0A',
    },
    contentTitle: {
        fontSize: '25px',
        fontWeight: 'bold',
        color: '#0A5C7F',
        marginBottom: '5px'

    },
    Content: {
        fontSize: '12px',
        color: '#646464',
        // lineHeight:1.5,

    },
    portalWidgetHeading: {
        fontSize: '18px',
        fontWeight: 'bold',
        fontFamily: 'Raleway',
        color: '#4B4C4C',
        borderLeftStyle: 'solid',
        '&:after': {
            content: '""',
            width: 2,
            height: '0%',
            position: 'absolute',
            bottom: 0,
            left: -2,
            transition: 'height .5s'
        },

    },


}

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            StartDate: moment(new Date()).format('MM/DD/YYYY'),
            EndDate: moment(new Date()).format('MM/DD/YYYY'),
            DownloadName: '',
            open: false,
            IsLoading: true,
            IsCard: 'impressions',
            IsLoadingDropdown: true,
            IsLoadingMailChip: true,
            IsLoadingFbInst: true,
            IsShowTopChart: false,
            ReportDate: Date.now(),
            OrderDetailsID: '',
            FinalSocialReports: [],
            TotalAmount: 0,
            hover: false,
            hoverView: '',
            TotalClicks: 0,
            TotalImpression: 0,
            TotalEngagement: 0,
            TotalCost: 0,
            Engagement: [],
            DataForDropDown: [],
            EmailReports: [],
            IsMailChimpShow: false,
            PerformanceReports: [],
            GoogleDisplayAds: [],
            GoogleSearchAds: [],
            Facebook: [],
            Instagram: [],
            Data: [{
                'Appointment_Clicks': "0",
                'Certificate_Downloads': "0",
                'Certificate_Emails': "0",
                'Clicks': "0",
                'Cost': "0",
                'Impressions': "0",
                'Title': 'ABCD'
            }],
            activeTabIndex: 0,
            isLoading: false,
            dataSource: '',
            ReportTypeID: 0,
            AllWeeks: [],
            LineChartLabel: [],
            SearchData: [],
            GoogleData: [],
            MasterImpressions: [],
            MasterImpressions1: [],
            MasterClicks: [],
            MasterClicks1: [],
            MasterCTR: [],
            MasterCTR1: [],
            MasterConversions: [],
            MasterConversions1: [],
            Tabs: [],
            TotalImpressions: 0,
            PracticeLocations: [],
            LocationID: 0
        };
    }
    componentDidMount() {
        this.wsGetOrderListForDashboard()
        // this.wsGetDashboardDataForAll("06/01/2022", "06/30/2022", "F61B5FD1-3C44-4064-B151-DB7CF6897422")
    }

    PressCards(value) {
        this.setState({ IsCard: value })
        this.ReloadChart(value)
    }

    wsGetOrderListForDashboard() {
        APIController.GetOrderListForDashboard('', '0').then((response) => {
            if (response.error == null && response.object.data.length) {
                this.setState({
                    DataForDropDown: response.object.data,
                    TotalAmount: response.object.data[0].TotalAmount,
                    DownloadName: response.object.data[0].Name,
                    OrderDetailsID: response.object.data[0].OrderDetailsID,
                    StartDate: response.object.data[0].OrderDate,
                    PracticeLocations: JSON.parse(response.object.data[0].Locations)
                })
                let Locations = JSON.parse(response.object.data[0].Locations)
                Locations.length > 0
                    ? this.wsGetDashboardDataForAll(moment(response.object.data[0].OrderDate).format('MM/DD/YYYY'), this.state.EndDate, response.object.data[0].OrderDetailsID, this.state.LocationID)
                    : this.wsGetDashboardDataForAll(moment(response.object.data[0].OrderDate).format('MM/DD/YYYY'), this.state.EndDate, response.object.data[0].OrderDetailsID, Locations[0].ID)


            }
            else { this.setState({ IsLoadingDropdown: false, IsLoading: false }) }
        }).catch((err) => {
            this.setState({ IsLoadingDropdown: false, IsLoading: false })
        })
    }

    wsGetDashboardDataForAll(StartDate, EndDate, OrderDetailsID, LocationID) {
        this.setState({
            FinalSocialReports: [{
                'Appointment_Clicks': "0",
                'Certificate_Downloads': "0",
                'Certificate_Emails': "0",
                'Clicks': "0",
                'Cost': "0",
                'Impressions': "0",
                'Title': 'ABCD'
            }]
        })
        this.GetMailChimpReports(StartDate, EndDate, "MailChimp", OrderDetailsID, LocationID) // ReportTypeID = 1
        this.GetFacebookAndInstaReports(StartDate, EndDate, "Facebook", OrderDetailsID, LocationID)  // ReportTypeID = 2
        this.GetFacebookAndInstaReports(StartDate, EndDate, "Display", OrderDetailsID, LocationID)  // ReportTypeID = 3
        this.GetAdsReports(StartDate, EndDate, "Search", OrderDetailsID, LocationID) // ReportTypeID = 4
        // this.GetGoogleDisplayAndSearchReports(StartDate, EndDate, "Display", OrderDetailsID)  // ReportTypeID = 3
    }

    GetMailChimpReports(StartDate, EndDate, ReportTypeID, OrderDetailsID, LocationID) {
        this.setState({ IsMailChimpShow: false })
        APIController.GetDashboardData(StartDate, EndDate, ReportTypeID, OrderDetailsID, LocationID, 0).then((response) => {
            if (response.error == null && response.object.data != "") {
                this.setState({ IsMailChimpShow: true, EmailReports: response.object.data.MailChimp, IsLoadingMailChip: false })
            }
        }).catch((err) => {
            this.setState({ IsLoadingMailChip: false })
        })
    }

    GetFacebookAndInstaReports(StartDate, EndDate, ReportTypeID, OrderDetailsID, LocationID) {
        APIController.GetDashboardData(StartDate, EndDate, ReportTypeID, OrderDetailsID, LocationID, 0).then((response) => {
            if (response.object.data != "") {
                let SocialData = this.state.FinalSocialReports
                if (ReportTypeID == 'Facebook') {
                    let Facebook = response.object.data.Facebook.length && response.object.data.Facebook[0]
                    SocialData.push(Facebook)
                    this.setState({ FinalSocialReports: SocialData, TotalAmount: response.object.data.NetAmount })
                }
                else if (ReportTypeID == 'Display') {
                    let Display = response.object.data.Display.length && response.object.data.Display[0]
                    SocialData.push(Display)
                    this.setState({ FinalSocialReports: SocialData })
                }
                this.GetTop3CardsData()
            } else {
                this.setState({
                    FinalSocialReports: [{
                        'Appointment_Clicks': "0",
                        'Certificate_Downloads': "0",
                        'Certificate_Emails': "0",
                        'Clicks': "0",
                        'Cost': "0",
                        'Impressions': "0",
                        'Title': 'ABCD'
                    }]
                })
                this.GetTop3CardsData()
            }
        })
    }

    GetAdsReports(StartDate, EndDate, ReportTypeID, OrderDetailsID, LocationID) {
        APIController.GetDashboardData(StartDate, EndDate, ReportTypeID, OrderDetailsID, LocationID, 1).then((response) => {
            if (response.object.data != "") {
                this.BindData(response)
                this.setState({ isLoading: 0, dataSource: response, });
                this.setState({
                    Tabs: [...this.state.Tabs, { title: 'Impressions', value: this.state.TotalImpressions }]
                })
                this.setState({
                    Tabs: [...this.state.Tabs, {
                        title: 'Clicks',
                        value: this.state.MasterClicks.reduce((a, b) => +a + +b, 0)
                    }]
                })
            } else {
                this.setState({ isLoading: false });
            }
        })
    }

    GetGoogleDisplayAndSearchReports(StartDate, EndDate, ReportTypeID, OrderDetailsID, LocationID) {
        APIController.GetDashboardData(StartDate, EndDate, ReportTypeID, OrderDetailsID, LocationID).then((response) => {
            if (response.object.message == '') {
                let SocialData = this.state.FinalSocialReports
                let dataDisplay = response.object.data.Display
                let dataSearch = response.object.data.Search
                let Display = [{
                    'Appointment_Clicks': dataDisplay.Appointment_Clicks,
                    'Certificate_Downloads': dataDisplay.Certificate_Download,
                    'Certificate_Emails': dataDisplay.Certificate_Email,
                    'Clicks': dataDisplay.Display[0].Clicks,
                    'Cost': dataDisplay.Display[0].Cost,
                    'Impressions': dataDisplay.Display[0].Impressions,
                    'Title': dataDisplay.Display[0].Title
                }]
                let Search = [{
                    'Appointment_Clicks': dataSearch.Appointment_Clicks,
                    'Certificate_Downloads': dataSearch.Certificate_Download,
                    'Certificate_Emails': dataSearch.Certificate_Email,
                    'Clicks': dataSearch.Search[0].Clicks,
                    'Cost': dataSearch.Search[0].Cost,
                    'Impressions': dataSearch.Search[0].Impressions,
                    'Title': dataSearch.Search[0].Title
                }]
                SocialData.push(Display[0])
                SocialData.push(Search[0])
                // this.setState({ IsLoadingFbInst: false, GoogleDisplayAds: response.object.data.Display, GoogleSearchAds: response.object.data.Search, FinalSocialReports: SocialData, })
                // this.GetTop3CardsData()
            } else {
                this.setState({ IsLoadingFbInst: false })
                // this.GetTop3CardsData()
            }
        }).catch((err) => {
            // this.GetTop3CardsData()
            this.setState({ IsLoadingFbInst: false, })
        })
    }

    GetTop3CardsData() {
        let TotalImpressions = 0;
        let TotalCost = 0;
        let TotalClicks = 0;
        let TotalEngagement = 0;
        let Engagement = []
        let finalData = this.state.FinalSocialReports.map((item, index) => {
            if (item.Impressions != -1) {
                TotalImpressions = (TotalImpressions) + (parseInt(item.Impressions))
            }
            if (item.Cost != -1) {
                TotalCost = (TotalCost) + (parseInt(item.Cost))
            }
            if (item.Clicks != -1) {
                TotalClicks = (TotalClicks) + (parseInt(item.Clicks))
                TotalEngagement = (TotalEngagement) + (parseInt(item.Appointment_Clicks)) + (parseInt(item.Certificate_Downloads)) + (parseInt(item.Certificate_Emails))
                Engagement.push((parseInt(item.Appointment_Clicks)) + (parseInt(item.Certificate_Downloads)) + (parseInt(item.Certificate_Emails)))
            }
        })
        this.setState({ Engagement: Engagement, TotalImpression: TotalImpressions, TotalCost: TotalCost * 1.3, TotalClicks: TotalClicks, TotalEngagement: TotalEngagement })
        TotalImpressions != 0 ? this.setState({ IsShowTopChart: true }) : this.setState({ IsShowTopChart: false })
        setTimeout(() => {
            this.setState({ IsLoadingDropdown: false, IsLoading: false, IsLoadingFbInst: false })
        }, 2000);
    }
    onMouseEnterHandler(value) {
        this.setState({ hoverView: value })
    }
    onMouseLeaveHandler() {
        this.setState({ hoverView: '-1' })
    }

    ReloadChart(data) {
        if (data == 'impressions') {
            this.setState({ GoogleData: this.state.MasterImpressions.length && this.state.MasterImpressions[0].split(','), SearchData: this.state.MasterImpressions1.length && this.state.MasterImpressions1[0].split(',') })
        }
        else if (data == 'clicks') {
            this.setState({ GoogleData: this.state.MasterClicks, SearchData: this.state.MasterClicks1 })
        }
        else if (data == 'engagement') {
            this.setState({ GoogleData: this.state.MasterConversions, SearchData: this.state.MasterConversions1 })
        }
    }

    BindData(response) {
        let MasterConversions = [];
        let MasterClicks = [];
        let MasterCTR = [];
        let TotalImpressions = 0;
        let TempImpression = '';

        const AllWeeks = response.object.message.split(',');
        const MasterData = response.object.data

        AllWeeks.map((item) => {
            var temp = MasterData.find((data) => {
                return data['Week'] == item && (data['ReportType'] == "Display" || data['ReportType'] == "Search")
            });
            if (temp == undefined) {
                TempImpression = TempImpression + '0,';
                MasterConversions.push('0');
                MasterClicks.push('0');
                MasterCTR.push('0');
            } else {
                TempImpression = TempImpression + temp["Impressions"] + ',';
                MasterConversions.push(temp["Conversions"]);
                MasterClicks.push(temp["Clicks"]);
                MasterCTR.push(temp["CTR"]);
                TotalImpressions = TotalImpressions + parseInt(temp["Impressions"]);
            }
            this.setState({
                MasterConversions: MasterConversions,
                MasterClicks: MasterClicks,
                MasterCTR: MasterCTR,
                LineChartLabel: AllWeeks,
            })
        })
        this.setState({
            AllWeeks: AllWeeks,
            MasterImpressions: [...this.state.MasterImpressions, TempImpression.slice(0, -1)],
            TotalImpressions: this.state.TotalImpressions + parseInt(TotalImpressions),
            MasterClicks: [...this.state.MasterClicks],
            MasterConversions: [...this.state.MasterConversions],
        });
        this.setState({ GoogleData: this.state.IsCard == 'impressions' ? this.state.MasterImpressions[0].split(',') : this.state.IsCard == 'clicks' ? this.state.MasterClicks : this.state.MasterConversions })
        this.SetSearchData(response)
    }
    SetSearchData(response) {
        let MasterConversions = [];
        let MasterClicks = [];
        let MasterCTR = [];
        let TotalImpressions = 0;
        let TempImpression = '';

        const AllWeeks = response.object.message.split(',');
        const MasterData = response.object.data;
        AllWeeks.map((item) => {
            var temp = MasterData.find((data) => {
                return data['Week'] == item && (data['ReportType'] == "Facebook" || data['ReportType'] == "Instagram")
            });
            if (temp == undefined) {
                TempImpression = TempImpression + '0,';
                MasterConversions.push('0');
                MasterClicks.push('0');
                MasterCTR.push('0');
            } else {
                TempImpression = TempImpression + temp["Impressions"] + ',';
                MasterConversions.push(temp["Conversions"]);
                MasterClicks.push(temp["Clicks"]);
                MasterCTR.push(temp["CTR"]);
                TotalImpressions = TotalImpressions + parseInt(temp["Impressions"]);
            }
            this.setState({
                MasterConversions1: MasterConversions,
                MasterClicks1: MasterClicks,
                MasterCTR1: MasterCTR,
                LineChartLabel: AllWeeks,
            })
        })
        this.setState({
            AllWeeks: AllWeeks,
            MasterImpressions1: [...this.state.MasterImpressions1, TempImpression.slice(0, -1)],
            TotalImpressions1: this.state.TotalImpressions1 + parseInt(TotalImpressions),
            MasterClicks1: [...this.state.MasterClicks1],
            MasterConversions1: [...this.state.MasterConversions1],
        });
        this.setState({ SearchData: this.state.IsCard == 'impressions' ? this.state.MasterImpressions1[0].split(',') : this.state.IsCard == 'clicks' ? this.state.MasterClicks1 : this.state.MasterConversions1 })
    }

    onSelect = dates => {
        this.setState({ StartDate: moment(dates.start._d).format("MM/DD/YYYY"), EndDate: moment(dates.end._d).format("MM/DD/YYYY"), open: false })
        this.wsGetDashboardDataForAll(moment(dates.start._d).format("MM/DD/YYYY"), moment(dates.end._d).format("MM/DD/YYYY"), this.state.OrderDetailsID)
    }

    render() {
        const { IsLoadingDropdown, open, StartDate, EndDate, IsLoadingMailChip, IsMailChimpShow, IsLoadingFbInst, IsShowTopChart, IsLoadingGoogle, IsCard, hover, hoverView } = this.state
        return (
            this.state.IsLoading ?
                <Grid container flexDirection='row' justify='center' alignItems='center' style={{ width: '100%', height: window.innerHeight - 200 }}>
                    <CircularProgress style={{ color: '#0a5c7f' }} />
                </Grid> :
                <>
                    <section className="content-right-main">
                        {/* For Title  */}
                        <Grid container spacing={0} lg={12} xs={12} xl={12} md={12} sm={12} justify={'space-between'} alignItems='center'>
                            <Grid container spacing={0} xs={4} sm={4} md={3} lg={3} xl={3}  >
                                <div className="content-right-heading">Dashboard</div>
                            </Grid>
                            <Grid container spacing={0} direction={'row'} justify={'flex-end'} xs={12} sm={8} md={9} lg={9} xl={9} >
                                {this.state.DataForDropDown.length > 0 &&
                                    <Grid container xs={12} sm={8} md={4} lg={4} xl={4} style={{ marginLeft: '10px' }}>
                                        {IsLoadingDropdown ? <CircularProgress style={{ color: '#0a5c7f' }} /> :
                                            <select className={'title-content'} style={{ borderRadius: '3px', border: '1px solid #CCCCCC', padding: '8px', fontSize: '13px', textOverflow: 'ellipsis' }}
                                                name='ReportTypeID'
                                                onChange={(e, index) => {
                                                    let data = JSON.parse(e.target.value)
                                                    this.setState({ OrderDetailsID: data.OrderDetailsID, DownloadName: data.Name });
                                                }}>
                                                {this.state.DataForDropDown.map((item, index) => { return <option key={index} value={JSON.stringify(item)}>{item.Name}</option> })}
                                            </select>}
                                    </Grid>}

                                {this.state.PracticeLocations.length > 1 &&
                                    <Grid container xs={12} sm={8} md={4} lg={4} xl={4} >
                                        {IsLoadingDropdown ? <CircularProgress style={{ color: '#0a5c7f' }} /> :
                                            <select className={'title-content'} style={{ marginLeft: '10px', borderRadius: '3px', border: '1px solid #CCCCCC', padding: '8px', fontSize: '13px', textOverflow: 'ellipsis' }}
                                                name='LocationID'
                                                onChange={(e, index) => {
                                                    this.setState({ LocationID: JSON.parse(e.target.value).ID });
                                                    this.wsGetDashboardDataForAll(moment(this.state.StartDate).format('MM/DD/YYYY'), this.state.EndDate, this.state.OrderDetailsID, JSON.parse(e.target.value).ID)
                                                }}>
                                                <option value={'0'}>{'All Locations'}</option>
                                                {this.state.PracticeLocations.map((item, index) => { return <option key={index} value={JSON.stringify(item)}>{item.Location}</option> })}
                                            </select>}
                                    </Grid>}

                                <Grid container xs={12} sm={8} md={3} lg={3} xl={3} style={{ marginLeft: '10px', padding: '8px', position: 'relative', border: '1px solid #cccc', justifyContent: 'space-between', alignItems: 'center' }} >
                                    <Typography style={{ fontSize: '13px', }} className={'title-content'} variant="subheading">{StartDate}</Typography>
                                    {'-'}
                                    <Typography style={{ fontSize: '13px', }} className={'title-content'} variant="subheading">{EndDate}</Typography>
                                    <DateRangeIcon style={{ color: '#CCCCCC' }} onClick={() => this.setState({ open: !this.state.open })} />
                                    {open &&
                                        <div style={{ border: '1px solid #cccc', zIndex: 1000, position: 'absolute', top: 50, backgroundColor: '#fff', right: 0 }}>
                                            <DateRangePicker selectionType='range' onSelect={this.onSelect} />
                                        </div>}
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* For Top Cards */}
                        <Grid key={2} container xs={12} sm={12} md={12} xl={12} lg={12}>
                            <Grid onMouseEnter={() => this.onMouseEnterHandler('0')} onMouseLeave={() => this.onMouseLeaveHandler('0')} container key={1} item xs={12} sm={12} md={4} onClick={() => this.PressCards('impressions')} >
                                <Paper style={{ cursor: 'pointer', width: '100%', padding: "15px", margin: '5px', backgroundColor: hoverView == '0' ? '#00010110' : '#fff', border: IsCard == 'impressions' ? '1px solid #0a5c7f' : 0 }}>
                                    <Grid container direction={'row'} justify={'space-between'} alignItems='center'>
                                        <Grid item key={1} xs={8} sm={6} md={8} >
                                            <CountUp separator={','} duration={3} className={'title'} end={this.state.TotalImpression} />
                                            <Typography className={'title-content'}>Total Impressions</Typography>
                                        </Grid>
                                        <Grid container key={1} item xs={4} sm={6} md={4} justify={'flex-end'}>
                                            <div style={{ width: '50px', borderRadius: 100, height: '50px' }}><img src={impressions} alt="" /> </div>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid onMouseEnter={() => this.onMouseEnterHandler('1')} onMouseLeave={() => this.onMouseLeaveHandler('1')} container key={2} item xs={12} sm={12} md={4} onClick={() => this.PressCards('clicks')} >
                                <Paper style={{ cursor: 'pointer', width: '100%', padding: "15px", margin: '5px', backgroundColor: hoverView == '1' ? '#00010110' : '#fff', border: IsCard == 'clicks' ? '1px solid #0a5c7f' : 0 }}>
                                    <Grid container direction={'row'} justify={'space-between'} alignItems='center'>
                                        <Grid item key={1} xs={8} sm={6} md={8} >
                                            <CountUp separator={','} duration={3} className={'title'} end={this.state.TotalClicks} />
                                            <Typography className={'title-content'}>Total Clicks</Typography>
                                        </Grid>
                                        <Grid container key={1} item xs={4} sm={6} md={4} justify={'flex-end'}>
                                            <div style={{ width: '50px', borderRadius: 100, height: '50px' }}><img src={clicks} alt="" /> </div>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid onMouseEnter={() => this.onMouseEnterHandler('2')} onMouseLeave={() => this.onMouseLeaveHandler('2')} onClick={() => this.PressCards('engagement')} container key={3} item xs={12} sm={12} md={4} >
                                <Paper style={{ cursor: 'pointer', width: '100%', padding: "15px", margin: '5px', backgroundColor: hoverView == '2' ? '#00010110' : '#fff', border: IsCard == 'engagement' ? '1px solid #0a5c7f' : 0 }}>
                                    <Grid container direction={'row'} justify={'space-between'} alignItems='center'>
                                        <Grid item key={1} xs={8} sm={6} md={8} >
                                            <CountUp separator={','} duration={3} className={'title'} end={this.state.TotalEngagement} />

                                            <Typography className={'title-content'}>Total Engagements</Typography>
                                        </Grid>
                                        <Grid container key={1} item xs={4} sm={6} md={4} justify={'flex-end'}>
                                            <div style={{ width: '50px', borderRadius: 100, height: '50px' }}> <img src={engagements} alt="" /> </div>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>

                        {/* For Top Charts Report */}
                        {IsShowTopChart && <Grid item key={3} xs={12} sm={12} md={12} lg={12} xl={12} >
                            <Analytics GoogleData={this.state.GoogleData} AllWeeks={this.state.AllWeeks} SearchData={this.state.SearchData} Engagement={this.state.Engagement} IsCard={IsCard} data={this.state.data} PerformanceReports={this.state.PerformanceReports} />
                        </Grid>}

                        {/* For All Social Ads And budget Progress Data */}
                        <Grid item key={4} xs={12} sm={12} md={12} lg={12} xl={12}>
                            {this.state.FinalSocialReports.length > 1 &&
                                <SocialDonutChart IsLoadingFbInst={IsLoadingFbInst} data={this.state.Data} FinalSocialReports={this.state.FinalSocialReports} TotalAmount={this.state.TotalAmount} TotalCost={this.state.TotalCost} />}
                        </Grid>

                        {/* For MailChimp Data */}
                        {IsMailChimpShow && <Grid item key={5} xs={12} sm={12} md={8} lg={8} xl={8} >
                            <Paper style={{ padding: "15px", margin: '5px', textAlign: 'center' }}>
                                <div style={{ position: 'relative' }}>
                                    <Grid container direction={'row'} style={{ width: '100%' }} justify={'space-between'}>
                                        <Typography style={Style.portalWidgetHeading} variant="subheading">{'Free Trial Certificate Emails'}</Typography>
                                        {/* <GetAppIcon style={{ color: '#0A5C7F' }} /> */}
                                    </Grid>
                                    <Grid style={{ marginBottom: '10px', marginTop: '10px', borderBottom: '2.5px solid #6464641A', width: '100%' }} />

                                    {/* <ReactHTMLTableToExcel
                                        id="test-table-xls-button"
                                        className={'download-btn'}
                                        style={Style.BoxStyle}
                                        table="table-to-xls"
                                        filename={this.state.DownloadName}
                                        sheet="tablexls"
                                    buttonText=" " /> */}

                                    {IsLoadingMailChip ? <CircularProgress style={{ color: '#0a5c7f' }} /> :
                                        <PerfectScrollbar component='div' style={{ height: '350px', overflow: 'scroll' }}>
                                            <table style={{ width: '100%', }} id="table-to-xls">
                                                <tr style={{ width: '100%', padding: '15px' }} className={'table-hed'}>
                                                    <th className={'table-column'} style={{ width: '10%' }}>No</th>
                                                    <th className={'table-column'} style={{ width: '30%' }}>Date</th>
                                                    <th className={'table-column'} style={{ width: '60%' }}>Email Address</th>
                                                </tr>
                                                {this.state.EmailReports.length && this.state.EmailReports.map((item, index) => {
                                                    return (
                                                        <tr className={'table-row'}>
                                                            <td className={'table-column1'} style={{ width: '10%' }}  >{index + 1}</td>
                                                            <td className={'table-column1'} style={{ width: '30%' }}  >{item.ReportDate}</td>
                                                            <td className={'table-column1'} style={{ width: '60%' }} >{item.EmailAddress}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </table>
                                        </PerfectScrollbar>}
                                </div>
                            </Paper>
                        </Grid>}
                    </section>
                </>
        )
    }
}

export default Dashboard;
