import React from 'react';
import PageTitle from "../../../config/pageTitle";
import * as AppConstants from "../../../config/AppConstant";

const PrivacyPolicy = () => {
  return (
    <>
      <section className='content-right-main'>
        <PageTitle Title={AppConstants.ContactDetais.PrivacyPolicyTitle} />
        <div className='two-col-sec' style={{ fontSize: '16px' }}>
          <nowrap>
            <div style={{ marginTop: "20px" }}>
              <span id="_10.9" style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#0000ff' }}>
                A. COBRAND MEDIA PRIVACY POLICY</span>
            </div>
            <div style={{ marginTop: "5px" }}>
              <span id="_10.9" style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                What information do we collect?</span>
            </div>
            <div style={{ marginTop: "5px" }}>
              <span id="_10.9" style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                We receive information on you from your corporate sponsor and collect information from you when you sign onto our site, place an
                order, modify your information, send us an email, respond to a survey, or fill out a form.
                When ordering on our site, as appropriate, you may be asked to enter additional banking or credit care information, etc.</span>
            </div>
            <div style={{ marginTop: "10px" }}>
              <span id="_10.9" style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                What do we use your information for?</span>
            </div>
            <div style={{ marginTop: "5px" }}>
              <span id="_10.9" style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                Any of the information we collect from you may be used in one of the following ways: to personalize your experience (your
                information helps us to better respond to your individual needs); to improve our website (we continually strive to improve our website
                offerings based on the information and feedback we receive from you); to improve customer service (your information helps us to
                more effectively respond to your customer service requests and support needs); to process transactions (your information, whether
                public or private, will not be sold, exchanged, transferred, or given to any other company for any reason whatsoever, without your
                consent, other than for the express purpose of delivering the purchased advertising requested.); to send periodic emails.</span>
            </div>
            <div style={{ marginTop: "5px" }}>
              <span id="_10.9" style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                <b>Note:</b> Your corporate sponsor has provided us with your contact and other information so that we may assist you by providing low
                cost promotional benefits available only through CoBrand Media. However, if at any time you would like to be removed from that
                corporate provided advertiser database, and consequently from receiving future notifications, you may send your request to
                CustomerService@CoBrandMedia.com.</span>
            </div>
            <div style={{ marginTop: "10px" }}>
              <span id="_10.9" style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                How do we protect your information?</span>
            </div>
            <div style={{ marginTop: "5px" }}>
              <span id="_10.9" style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                We implement a variety of security measures to maintain the safety of your personal information when you place an order or enter,
                submit, or access your personal information.</span>
            </div>
            <div style={{ marginTop: "5px" }}>
              <span id="_10.9" style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                We offer the use of a secure server. All supplied sensitive/credit information is transmitted via Secure Socket Layer (SSL)
                technology and then encrypted into our Payment gateway providers database only to be accessible by those authorized with special
                access rights to such systems, and are required to keep the information confidential.</span>
            </div>
            <div style={{ marginTop: "5px" }}>
              <span id="_10.9" style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                After a transaction, your private information (credit cards, social security numbers, financials, etc.) will not be stored on our servers.</span>
            </div>
            <div style={{ marginTop: "10px" }}>
              <span id="_10.9" style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                Do we use cookies?</span>
            </div>
            <div style={{ marginTop: "5px" }}>
              <span id="_10.9" style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                We use 3 cookies: to store the user login, user name, and last login.</span>
            </div>
            <div style={{ marginTop: "10px" }}>
              <span id="_10.9" style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                Do we disclose any information to outside parties?</span>
            </div>
            <div style={{ marginTop: "5px" }}>
              <span id="_10.9" style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted
                third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to
                keep this information confidential. We may also release your information when we believe release is appropriate to comply with the
                law, enforce our site policies, or protect ours or others’ rights, property, or safety. Your information as approved by you in each
                order, will be provided to the service providers with whom you chose to advertise. That information will then be in the public domain.</span>
            </div>
            <div style={{ marginTop: "10px" }}>
              <span id="_10.9" style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                Third party links</span>
            </div>
            <div style={{ marginTop: "5px" }}>
              <span id="_10.9" style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                We may offer third party products or services on our website. These third party sites have separate and independent privacy
                policies. We therefore have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to
                protect the integrity of our site and welcome any feedback about these sites.</span>
            </div>
            <div style={{ marginTop: "10px" }}>
              <span id="_10.9" style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                California Online Privacy Protection Act Compliance</span>
            </div>
            <div style={{ marginTop: "5px" }}>
              <span id="_10.9" style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                Because we value your privacy we have taken the necessary precautions to be in compliance with the California Online Privacy
                Protection Act. We therefore will not distribute your personal information to outside parties without your consent.</span>
            </div>
            <div style={{ marginTop: "5px" }}>
              <span id="_10.9" style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                As part of the California Online Privacy Protection Act, all users of our site may make any changes to their information at anytime by
                logging into CoBrandMedia.com, and making those changes their personal profile page.</span>
            </div>
            <div style={{ marginTop: "10px" }}>
              <span id="_10.9" style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                Children’s Online Privacy Protection Act Compliance</span>
            </div>
            <div style={{ marginTop: "5px" }}>
              <span id="_10.9" style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                We are in compliance with the requirements of COPPA (Children’s Online Privacy Protection Act); we do not collect any information
                from anyone under 13 years of age. Our website, products and services are all directed to people who are at least 13 years old or
                older.</span>
            </div>
            <div style={{ marginTop: "10px" }}>
              <span id="_11.1" style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                Your Consent</span>
            </div>
            <div style={{ marginTop: "5px" }}>
              <span id="_11.1" style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, }}>
                By using our site, you consent to our Privacy Policy
                Changes to our Privacy Policy
                If we decide to change our privacy policy, we will update the Privacy Policy modification date below.</span>
            </div>
            <div style={{ marginTop: "15px" }}>
              <span id="_11.1" style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                This policy was last modified on August 17, 2012</span>
            </div>
            <div style={{ marginTop: "10px" }}>
              <span id="_11.1" style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                Contacting Us
                If there are any questions regarding this privacy policy you may contact us using the information below.</span>
            </div>
            <div style={{ marginTop: "5px" }}>
              <span id="_11.1" style={{ fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
                CoBrand Media, P.O. Box 1654, Rancho Santa Fe, California 92067</span>
            </div>
            <div style={{ marginTop: "5px" }}>
              <span id="_11.1" style={{ fontFamily: 'Raleway', fontSize: '14px', letterSpacing: '0.3px', lineHeight: 1.6, color: '#000000' }}>
              </span>
            </div>
          </nowrap>
        </div>
      </section>
    </>
  );
}


export default PrivacyPolicy