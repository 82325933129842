/*eslint-disable eqeqeq*/
import React from "react";
import Table from "../../../../shared/components/table/table";
import TableHead from "../../../../shared/components/table/tableHead";
import TableRow from "../../../../shared/components/table/tableRow";
import TableBody from "../../../../shared/components/table/tableBody";
import TableCell from "../../../../shared/components/table/tableCell";
import double_left_arrow from '../../../../assets/images/left-doble-arow.svg';
import left_arrow from '../../../../assets/images/left-arrow.svg';
import double_right_arrow from '../../../../assets/images/right-doble-arow.svg';
import right_arrow from '../../../../assets/images/right-arrow.svg';
import facebook from '../../../../assets/images/icon/facebook.png'
import instagram from '../../../../assets/images/icon/instagram.png'
import deleteIcon from '../../../../assets/images/delete.svg'
import filtericon from '../../../../assets/images/ic_feather_filter.svg'
import "react-perfect-scrollbar/dist/css/styles.css";

import PerfectScrollbar from "react-perfect-scrollbar";
import CircularProgress from "../../../../shared/components/CircularProgress/CircularProgress";

const PostScheduleList = (props) => {
	const headerList = ['action', "Subscription No", "Practice Name", "Email Address", "Ship To No", "Details", "FB", "Insta"]
	const { currentPage, count, rowsPerPage, rowsPerPageOptions, list, to, from } = props;
	let countryList = list;
	countryList = list.slice(from, to)
	const page = Math.floor(count / rowsPerPage) + 1;

	const handleChangeInCurrentPage = (value) => {
		if (value <= page && Number(parseInt(value))) {
			props.handleChangePage(Number(value));
		}
	}

	return (
		<>
			<div className="table-main-wrap">
				<PerfectScrollbar component='div'>
					<Table>
						<TableHead>
							<TableRow> {headerList.map((obj, index) => { return <TableCell style={index == 0 ? { minWidth: "90px" } : { width: '10%', minWidth: '110px' }} key={index}>{obj}</TableCell>; })} </TableRow>
							<TableRow>
								<TableCell style={{ minWidth: "90px" }}> <span><img src={filtericon} alt="delete" /></span> </TableCell>
								<TableCell style={{ minWidth: "150px" }}> <input type="text" name="" placeholder="Subscription No" onChange={(e) => props.applyFilter('SubscriptionNo', e.target.value, 'input')} /> </TableCell>
								<TableCell style={{ minWidth: "210px" }}> <input type="text" name="" placeholder="Practice Name" onChange={(e) => props.applyFilter('PracticeName', e.target.value, 'input')} /> </TableCell>
								<TableCell style={{ minWidth: "180px" }}> <input type="text" name="" placeholder="Email Address" onChange={(e) => props.applyFilter('EmailAddress', e.target.value, 'input')} /> </TableCell>
								<TableCell style={{ minWidth: "100px" }}> <input type="text" name="" placeholder="Ship To No" onChange={(e) => props.applyFilter('ShipToNo', e.target.value, 'input')} /> </TableCell>
								<TableCell style={{ minWidth: "100px" }}> </TableCell>
								<TableCell style={{ minWidth: "130px" }}> </TableCell>
								<TableCell style={{ minWidth: "100px" }}> </TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{!!countryList.length ? countryList.map((obj, idnex) => {
								return (
									<TableRow key={idnex}>
										<TableCell style={{ minWidth: "90px" }}>
											<span>
												{obj.Status == 3 ? <img src={deleteIcon} alt="delete" style={{ marginRight: '10px', width: "12px" }} /> :
													<>
														{obj.FBURL != null && <img src={facebook} alt="fb" style={{ cursor: 'pointer', marginRight: '10px', width: "17px" }}
															onClick={() => { window.open(obj.FBURL) }} />}
														{obj.InstaURL != null && <img src={instagram} alt="insta" style={{ cursor: 'pointer', marginRight: '10px', width: "17px" }}
															onClick={() => { window.open(obj.InstaURL) }} />}
													</>}
											</span>
										</TableCell>
										<TableCell style={{ minWidth: "100px" }}>{obj.SubscriptionNo}</TableCell>
										<TableCell style={{ minWidth: "135px" }}>{obj.PracticeName}</TableCell>
										<TableCell style={{ minWidth: "180px" }}>{obj.EmailAddress}</TableCell>
										<TableCell style={{ minWidth: "100px" }}>{obj.ShipToNo}</TableCell>
										<TableCell style={{ minWidth: "100px" }}>
											<div className='button-field'>
												<button style={{ minWidth: '100px', padding: '6px', fontSize: '13px' }} onClick={() => { props.handleRemarksDialog(obj.Description, obj.ImageURL) }}> Details </button>
											</div></TableCell>
										<TableCell style={{ minWidth: "130px" }}>
											{obj.Status != null && obj.Status != 3 && obj.FBURL != null &&
												<div>
													{obj.Remarks == null ?
														<div className={!obj.IsFacebookAccessTokenValid ? `button-field disable-btn` : `button-field`}>
															<button style={{ minWidth: '100px', padding: '6px', fontSize: '13px' }} onClick={() => { props.handleScheduledPost(obj.ID, "1") }}> Post </button>
														</div>
														: <div className={obj.Status == 5 ? `button-field-status-redcolor` : `button-field`}>
															<button style={{ minWidth: '100px', padding: '6px', fontSize: '13px' }} onClick={() => { props.handleRemarksDialog(obj.Remarks, '') }}> Remarks </button>
														</div>}
												</div>}

										</TableCell>
										<TableCell style={{ minWidth: "100px" }}>
											{obj.InstagramStatus != null && obj.InstagramStatus != 3 && obj.InstaURL != null &&
												<div>
													{obj.InstaPostID_Remarks == null ?
														<div className={!obj.IsFacebookAccessTokenValid ? `button-field disable-btn` : `button-field`}>
															<button style={{ minWidth: '100px', padding: '6px', fontSize: '13px' }} onClick={() => { props.handleScheduledPost(obj.ID, "2") }}> Post </button>
														</div>
														: <div className={obj.InstagramStatus == 5 ? `button-field-status-redcolor` : `button-field`}>
															<button style={{ minWidth: '100px', padding: '6px', fontSize: '13px' }} onClick={() => { props.handleRemarksDialog(obj.InstaPostID_Remarks, '') }}> Remarks </button>
														</div>}
												</div>}
										</TableCell>
									</TableRow>
								);
							}) : <TableRow>
								<TableCell colSpan={headerList.length} className="not_found">
									{props.progress ?
										<CircularProgress /> :
										<label>Not Currently Available</label>}
								</TableCell>
							</TableRow>}
						</TableBody>
					</Table>
				</PerfectScrollbar>
			</div>

			<div className="table-pagination">
				<div className="pagination-info">
					<p>Showing {from + 1} - {to > count ? count : to} of {count}</p>
				</div>
				<div className="pagination-control">
					<a href="#!" title="" className={`double-left-arrow ${currentPage === 1 || currentPage === 0 ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(1)
						}}
					><img src={double_left_arrow} alt="left" /></a>
					<a href="#!" title="" className={`simple-left-arrow ${currentPage === 1 || currentPage === 0 ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(currentPage - 1)
						}}
					><img src={left_arrow} alt="single_left" /></a>
					<span>Page</span>
					<input type="text" name="textbox" value={currentPage === 0 ? currentPage + 1 : currentPage} onChange={(e) => handleChangeInCurrentPage(e.target.value)} />
					<span>of {page}</span>
					<a href="#!" title="" className={`simple-right-arrow ${currentPage === page ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(currentPage + 1)
						}}
					><img src={right_arrow} alt="right" /></a>
					<a href="#!" title="" className={`double-right-arrow ${currentPage === page ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(page)
						}}><img src={double_right_arrow} alt="single_right" /></a>
					<select onChange={(e) => props.handleChangeRowsPerPage(e.target.value)} defaultValue={rowsPerPage}>
						{
							rowsPerPageOptions.map((obj, index) => {
								return <option value={obj} key={index}>{obj}</option>
							})
						}
					</select>
				</div>
			</div>
		</>
	);
};

export default PostScheduleList;
