/*eslint-disable eqeqeq*/
import React from "react";
import { Grid, SvgIcon, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from "@material-ui/core";
import map_ic from "../../../assets/images/map-ic.svg";
import CircularProgress from "../../../shared/components/CircularProgress/CircularProgress";
import up_arrow from "../../../assets/images/up-arrow.png";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import PageTitle from "../../../config/pageTitle";
import * as AppConsatnt from "../../../config/AppConstant";
import "./location.css";
import LocationValidationComponent from "./location-validation";
import * as AppConstants from "../../../config/AppConstant";

const LocationSelectionComponent = (props) => {
	if (props.scrollTop) {
		window.scrollTo(0, 0);
		props.setScrollTop();
	}
	const [expanded, setExpanded] = React.useState(null);
	const handleChange = (panel) => {
		setExpanded(panel);
	};
	const [Title, setTitle] = React.useState('');
	const [modalNumber, setModalNumber] = React.useState(null);
	const [AllowMultiSelectPractice, setAllowMultiSelectPractice] = React.useState(AppConstants.GetLocalStorage("localStorageMultiSelectPractice"));

	React.useEffect(() => {
		//code for Default Select when One(1) Location found
		if (props.DefaultOpenPanel == true) {
			setExpanded('panel1')
			setModalNumber(0)
		}
	}, []);

	const CheckBoxComponent = ({
		locationDetail,
		index,
		onSelectAddress,
		selectedLocations,
		onDeSelectAddress,
		updateLocationDetails,
	}) => {
		return (
			<div className='location-inner-content'>
				<div className='location-name'>
					<div className={AllowMultiSelectPractice == "1" ? 'custom-checkbox' : 'custom-radio'}  >
						{/* radio */}
						{props.budgetDetail[0].budget != "" &&
							<input
								type={AllowMultiSelectPractice == "1" ? 'checkbox' : 'radio'}
								name='location'
								id={`${locationDetail.id}`}
								checked={!!selectedLocations.length &&
									selectedLocations.find((ob) => ob.id === locationDetail.id)
								}
								onChange={(e) => {
									if (e.target.checked) {
										onSelectAddress(locationDetail);
									} else {
										onDeSelectAddress(locationDetail);
									}
								}}
							/>
						}
						<label htmlFor={`${locationDetail.id}`} style={{ fontWeight: 400 }}>
							{locationDetail.location}
						</label>
					</div>
				</div>
				<div className='location-info'>
					{props.budgetDetail[0].budget != "" || AllowMultiSelectPractice != "1" && <p>
						<img src={map_ic} alt='ic' />
						{locationDetail.unit} Radius
					</p>
					}
				</div>
				{/* <div className={`location-link ${Title == 'inValid' && index === modalNumber ? "red-link" : locationDetail.needsToReview ? 'red-link' : ''}`}>
					<a >
						{Title == 'inValid' && index === modalNumber ? "Update Location Details" : locationDetail.needsToReview ? "Info Needs Review"
							: expanded == null ? "View Location Details" : "Hide Location Details"}
					</a>
				</div> */}

				<div className="theme-option theme-option-location" onClick={(e) => {
					e.preventDefault();
					props.SetPracticeId(locationDetail.id)
					setModalNumber(index);
					handleChange(expanded === `panel${index + 1}` ? null : `panel${index + 1}`);
				}}>
					{locationDetail.needsToReview || Title == 'inValid' && index === modalNumber ? <div className="red-link">{"Info Needs Review"}<SvgIcon fontSize="large"><ArrowDropDownIcon /></SvgIcon></div>
						: expanded != null && index == modalNumber ? <div>{"Hide Location Details"} <SvgIcon fontSize="large"><ArrowDropUpIcon /></SvgIcon> </div>
							: <div>{"View Location Details"} <SvgIcon fontSize="large"><ArrowDropDownIcon /></SvgIcon> </div>}

					{/* {Title == 'inValid' && index === modalNumber ? <div>{"Update Location Details"} <SvgIcon fontSize="large"><ArrowDropDownIcon /></SvgIcon></div>
						: locationDetail.needsToReview ? <div>{"Info Needs Review "}<SvgIcon fontSize="large"><ArrowDropDownIcon /></SvgIcon></div>
							: expanded != null && index == modalNumber ? <div>{"Hide Location Details"} <SvgIcon fontSize="large"><ArrowDropUpIcon /></SvgIcon> </div>
							: <div>{"View Location Details"} <SvgIcon fontSize="large"><ArrowDropDownIcon /></SvgIcon> </div>} */}
				</div>
			</div>
		);
	};

	const handalExpand = () => {
		setExpanded(null)
		setModalNumber(null)
	}

	const handleTitle = (data) => {
		props.category.locations.map((obj, index) => {
			if (modalNumber == index) {
				setTitle(data)
			}
		})
	}

	return (
		<section className='content-right-main'>
			{AppConsatnt.GetMainClassName() == "app-main" ?
				props.isSocialAutomation == "YES" ?
					<PageTitle Title={AppConsatnt.ContactDetais.ConfirmLocationTitle} /> :
					props.budgetDetail[0].budget == "" ?
						<PageTitle Title={AppConsatnt.ContactDetais.MyLocationTitle} /> :
						props.ShowAddToCart == 0 || props.budgetDetail[0].duration == "1" ?
							<PageTitle Title={AppConsatnt.ContactDetais.Step2Confirm} /> :
							props.category.locations.length != 1 && props.fromCart != '0' ?
								<PageTitle Title={AppConsatnt.ContactDetais.Step3Select} /> :
								<PageTitle Title={AppConsatnt.ContactDetais.CartLocationTitle} />

				: props.budgetDetail[0].budget == "" ?
					<PageTitle Title={AppConsatnt.ContactDetais.MyLocationTitle} /> :
					props.budgetDetail[0].duration == 1 ?
						<PageTitle Title={AppConsatnt.ContactDetais.Step2locationAssetYomi} /> :
						<PageTitle Title={AppConsatnt.ContactDetais.Step2locationYomi} />}

			<div className='location-screen-sec'>
				{AppConsatnt.GetMainClassName() == "app-main" && <div className='accordian_title'>
					<div className='title-left'>
						<h3>{props.category.name}</h3>
					</div>
				</div>}
				<div className='loc-content-wrap'>
					{props.progress ?
						<div style={{ padding: '3%' }}> <CircularProgress /> </div>
						: <div className='loc-content-main'>
							{props.category.locations != undefined && props.category.locations.map((obj, index) => {
								return (
									<ExpansionPanel
										expanded={expanded === `panel${index + 1}`}
										key={index}>
										{/* {AppConsatnt.GetMainClassName() == "app-main" &&  }*/}
										<ExpansionPanelSummary>
											<CheckBoxComponent
												locationDetail={obj}
												key={index}
												index={index}
												onSelectAddress={props.onSelectAddress}
												updateLocationDetails={props.updateLocationDetails}
												selectedLocations={props.selectAddress}
												onDeSelectAddress={props.onDeSelectAddress}
											/>
										</ExpansionPanelSummary>
										<ExpansionPanelDetails>
											{modalNumber === index && (
												<LocationValidationComponent
													locationDetail={obj.locationDetail}
													needsToReview={obj.needsToReview}
													practiceId={props.practiceId}
													budgetDetail={props.budgetDetail}
													isSocialAutomation={props.isSocialAutomation}
													cancel={() => handalExpand()}
													handleTitle={handleTitle.bind(this)}
													updateLocationDetails={(values) => {
														props.updateLocationDetails(values, obj.id);
														handleChange(null);
														setModalNumber(null);
													}}
												/>
											)}
										</ExpansionPanelDetails>

									</ExpansionPanel>
								);
							})}
						</div>
					}
				</div>
			</div>
			{props.budgetDetail[0].budget != "" &&
				<Grid container flexDirection='row' justify='space-between' style={{ marginTop: '-25px' }}>
					<div className='button-field'>
						<input type='button' name='button' value={AppConsatnt.ButtonText.Back} onClick={props.back} />
					</div>
					<div className={`button-field ${props.selectAddress.length === 0 || props.selectAddress.find((ob) => ob.needsToReview === true) ? "disable-btn" : ""}`}>
						<input type='button' name='button' value={AppConsatnt.ButtonText.Continue} onClick={props.nextForm}
							disabled={Title == 'inValid' || props.selectAddress.length === 0 || props.selectAddress.find((ob) => ob.needsToReview === true)}
						/>
					</div>
				</Grid>}
		</section>
	);
};

export default LocationSelectionComponent;


// style={{
// 	backgroundColor:
// 		props.selectAddress.length && props.selectAddress.find((ob) => ob.id === obj.id) ?
// 			'#f5f7fa' : '#ddd'
// }}

// , color: selectedLocations.length &&
// 								selectedLocations.find((ob) => ob.id === locationDetail.id) ? '#000' : '#E0E0E0'