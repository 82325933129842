/*eslint-disable eqeqeq*/
import React from "react";
import { Formik, Field, ErrorMessage } from "formik";

import TextField from "@material-ui/core/TextField";

import * as APIController from '../../../api/APIController.js';
import "./clinic.css";
import * as yup from 'yup';

import Notification from '../../../shared/components/notification/notification';
import ModalComponent from "../../../shared/components/modal/modal";
import RejectForm from "../component/rejectForm";

const validateStateForm = yup.object().shape({
	// Representative: yup.string().required('Representative is required'),
	WebSite: yup.string().required('WebSite is required'),
});

const Clinic = (props) => {
	const initialValues = {
		Representative: props.RepresentativeID || '',
		WebSite: props.WebSite,
		isActive: props.IsCoOpEligibleId == 1 ? true : false,
	};

	const [IsLoading, setIsLoading] = React.useState(false);
	const [IsDisable, setIsDisable] = React.useState(false);
	const [Id, setId] = React.useState('');
	const [isModalOpen, setisModalOpen] = React.useState(false);

	const [openRepresentSuggestion, setOpenRepresentSuggestion] = React.useState(false);
	const [SearchRepresentativeName, setSearchRepresentativeName] = React.useState([]);
	const [RepresentativeNeme, setRepresentative] = React.useState(props.Representative);
	const [RepresentativeID, setRepresentativeID] = React.useState(props.RepresentativeID || '');

	const wsApprovePractice = (PracticeID, RepresentativeID) => {
		setIsLoading(true)
		APIController.ApprovePractice(PracticeID, RepresentativeID)
			.then((response) => {
				setIsLoading(false)
				if (response.error == null) {
					handleNotification(response.object.message);
					if (response.object.status == 1) {
						// props.history.replace({
						// pathname: '/country',
						// state: { showMessage: response.object.message }
						// })
					}
					else {
						handleNotification(response.object.message, true);
					}
				}
				else if (response.error.message && response.error.message.length > 0) {
					handleNotification(response.error.message, true);
				}
			})
	}

	const wsEditPractice = (IsCoOpEnabled, VTMID, WebSite) => {
		setIsLoading(true)
		APIController.EditPractice(props.PracticeID, IsCoOpEnabled, VTMID, WebSite)
			.then((response) => {
				setIsLoading(false)
				if (response.error == null && response.object.status != '0') {
					handleNotification(response.object.message)
					props.history.push('/practices')
				}
				else if (response.object.status && response.object.status == "0") {
					handleNotification(response.object.message, true);
				}
			})
	}

	const wsSearchRepresentatives = (value) => {
		setRepresentative(value)
		if (value == '') {
			setIsDisable(true)
			setOpenRepresentSuggestion(false)
		} else {
			APIController.SearchRepresentatives(value)
				.then((response) => {
					setSearchRepresentativeName(response.object.data)
					setOpenRepresentSuggestion(true)
					setIsDisable(false)
				})
		}
	}

	const SelectRepresentName = (item) => {
		if (item != undefined) {
			setRepresentativeID(item.ID)
			setRepresentative(item.FullName)
			setOpenRepresentSuggestion(false)
			setIsDisable(false)
		}
	}

	const [message, setMessage] = React.useState("");
	const [showNotification, setShowNotification] = React.useState(false);
	const [isError, setIsError] = React.useState(false);

	const handleNotification = (message, isError) => {
		setMessage(message)
		setShowNotification(true)
		setIsError(isError)

		setTimeout(() => {
			handleClearNotification();
		}, 4000)
	}

	const handleClearNotification = () => {
		setMessage('')
		setShowNotification(false)
		setIsError(false)
	}

	const OpenPopup = (ID) => {
		setId(ID)
		setisModalOpen(true)
	}

	return (
		<>
			<div className='tab-content'>
				<div className='form-main'>
					<Formik
						enableReinitialize={true}
						validationSchema={validateStateForm}
						initialValues={initialValues}
						onSubmit={(values) => {
							// wsApprovePractice(props.PracticeID)
						}}>
						{({ handleSubmit, values, setFieldValue, errors, touched, }) => {
							return (
								<form onSubmit={handleSubmit}>
									<div className={`form_field`}>
										<div className='field-left'>
											<label>Representative Name</label>
										</div>
										<div className='field-right'>
											<input type='text' placeholder='Enter Representative Name' name='Representative'
												onChange={(e) => wsSearchRepresentatives(e.target.value)}
												value={RepresentativeNeme}
											/>
											{/* {errors && touched.Representative && errors.Representative && errors.Representative !== '' && (
												<span className='error-msg'>
													<ErrorMessage name='Representative' render={(err) => { return err; }} />
												</span>
											)} */}
											{openRepresentSuggestion ? (
												<div className='Suggetion-wrap'>
													{SearchRepresentativeName.length == 0 ?
														<div> {'No Suggetion Found'} </div>
														: SearchRepresentativeName.map((item, index) => {
															return (<div onClick={() => SelectRepresentName(item)} style={{ cursor: 'pointer', padding: '5px' }}>
																{item.FullName}
															</div>)
														})
													}
												</div>
											) : null}
										</div>
									</div>
									<div className={`form_field ${errors && touched.WebSite && errors.WebSite && errors.WebSite !== '' ? 'error' : ''}`}>
										<div style={{ textAlign: 'left' }} className="field-left">
											<label>Website</label>
										</div>
										<div className="field-right">
											<Field type="text" placeholder="Enter Website" id={'WebSite'} name="WebSite" value={values.WebSite} maxLength="50" />
											{errors && touched.WebSite && errors.WebSite && errors.WebSite !== '' && (
												<span className='error-msg'>
													<ErrorMessage name='WebSite' render={(err) => { return err; }} />
												</span>
											)}
										</div>
									</div>
									{props.StatusID == 2 && <div className='form_field'>
										<div className='field-left'>
											<label>Enable CoOp?</label>
										</div>
										<div className='field-right'>
											<div className='custom-radio'>
												<input type='radio' name='isActive' id='yes-text' defaultChecked={!!values.isActive} onClick={() => setFieldValue("isActive", true)} />
												<label htmlFor='yes-text'>Yes</label>
											</div>
											<div className='custom-radio'>
												<input type='radio' name='isActive' id='no-text' defaultChecked={!values.isActive} onClick={() => setFieldValue("isActive", false)} />
												<label htmlFor='no-text'>No</label>
											</div>
										</div>
									</div>}

									<Notification
										isError={isError}
										message={message}
										showNotification={showNotification}
										clearNotification={handleClearNotification.bind(this)}
										closeNotification={handleClearNotification.bind(this)}
									/>

									{isModalOpen &&
										<ModalComponent
											show={isModalOpen}
											handleClose={() => setisModalOpen(false)}>
											<RejectForm PracticeID={Id} closeModal={() => setisModalOpen(false)} />
										</ModalComponent>}

									{props.StatusID == 2 ?
										<div className="form-submit">
											<div className={IsLoading || IsDisable ? "button-field disable-btn" : "button-field"}>
												<input type="button" value={IsLoading ? "Saving..." : "Save"} name="Save " onClick={() => wsEditPractice(values.isActive, RepresentativeID, values.WebSite)} />
											</div>
											<div className={"button-field"}>
												<input type="button" value={'Close'} name="Close" onClick={() => props.history.goBack()} />
											</div>
										</div> :
										<div className="form-submit">
											<div className={IsLoading ? "button-field disable-btn" : "button-field"}>
												<input type="submit" value={IsLoading ? "approveing..." : "approve"} name="approve" onClick={() => wsApprovePractice(props.PracticeID, RepresentativeID)} />
											</div>
											<div className={"button-field"}>
												<input type="button" value={"reject"} name="reject" onClick={() => OpenPopup(props.PracticeID)} />
											</div>
										</div>
									}
								</form>
							);
						}}
					</Formik>
				</div>
			</div>
		</>
	);
};

export default Clinic;