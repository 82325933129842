/*eslint-disable eqeqeq*/
import React from "react";
import Table from "../../../../shared/components/table/table";
import TableHead from "../../../../shared/components/table/tableHead";
import TableRow from "../../../../shared/components/table/tableRow";
import TableBody from "../../../../shared/components/table/tableBody";
import TableCell from "../../../../shared/components/table/tableCell";
import double_left_arrow from '../../../../assets/images/left-doble-arow.svg';
import left_arrow from '../../../../assets/images/left-arrow.svg';
import double_right_arrow from '../../../../assets/images/right-doble-arow.svg';
import right_arrow from '../../../../assets/images/right-arrow.svg';
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";

import Notification from '../../../../shared/components/notification/notification';
import CircularProgress from "../../../../shared/components/CircularProgress/CircularProgress";
import * as AppConstants from "../../../../config/AppConstant";

const AssetDownloadReportList = (props) => {
	const headerList = AppConstants.GetMainClassName() == "app-main"
		? ["Download At", "User", "Ship To #", "Practice Name", "Campaign", "Brand Name", "Asset Category", "Asset Name", "VTM First Name", "VTM Last Name", "VTM #", "Veeva Code", "VS", "VSP",]
		: ["Download At", "User", "Netsuite ID", "Practice Name", "Brand Name", "Asset Category", "Asset Name", "CSR First Name", "CSR Last Name", "VTM #", "Veeva Code"]
	const { currentPage, count, rowsPerPage, rowsPerPageOptions, list, to, from } = props;
	let countryList = list;
	// countryList = list.slice(from, to)

	const page = Math.floor(count / rowsPerPage) + 1;

	/**
	 * handleChangeInCurrentPage method is used to change page number
	 * it will check that page number does not exist last page
	 * @param {*} value 
	 */
	const handleChangeInCurrentPage = (value) => {
		if (value <= page && Number(parseInt(value))) {
			props.handleChangePage(Number(value), DownloadedAt, FullName, PracticeName, VS, VSP, ShipToNo, CampaignName, BrandName, AssetCategory, AssetName, CSRFirstName, CSRLastName, VeevaCode, VTM_Territory);
		}
	}

	const [message, setMessage] = React.useState("");
	const [showNotification, setShowNotification] = React.useState(false);
	const [isError, setIsError] = React.useState(false);

	const [DownloadedAt, setDownloadedAt] = React.useState("");
	const [FullName, setFullName] = React.useState("");
	const [ShipToNo, setShipToNo] = React.useState("");
	const [PracticeName, setPracticeName] = React.useState("");
	const [CampaignName, setCampaignName] = React.useState("");
	const [BrandName, setBrandName] = React.useState("");
	const [VS, setVS] = React.useState("");
	const [VSP, setVSP] = React.useState("");
	const [AssetCategory, setAssetCategory] = React.useState("");
	const [AssetName, setAssetName] = React.useState("");
	const [CSRFirstName, setCSRFirstName] = React.useState("");
	const [CSRLastName, setCSRLastName] = React.useState("");
	const [VeevaCode, setVeevaCode] = React.useState("");
	const [VTM_Territory, setVTM_Territory] = React.useState("");


	const handleClearNotification = () => {
		setMessage('')
		setShowNotification(false)
		setIsError(false)
	}

	const _handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			props.handleApplyFilter(DownloadedAt, FullName, PracticeName, VS, VSP, ShipToNo, CampaignName, BrandName, AssetCategory, AssetName, CSRFirstName, CSRLastName, VeevaCode, VTM_Territory)
		}
	}

	return (
		<>
			<div className="table-main-wrap">
				<PerfectScrollbar component='div'>
					<Table>
						<TableHead>
							<TableRow> {headerList.map((obj, index) => { return <TableCell style={{ minWidth: '125px', textAlign: 'center', padding: '5px' }} key={index}>{obj}</TableCell>; })} </TableRow> <TableRow>
								<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}> <input type="text" name="" placeholder="Downloaded At" onKeyDown={_handleKeyDown} onChange={(e) => setDownloadedAt(e.target.value)} /> </TableCell>
								<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}> <input type="text" name="" placeholder="User Name" onKeyDown={_handleKeyDown} onChange={(e) => setFullName(e.target.value)} /> </TableCell>
								<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}> <input type="text" name="" placeholder={AppConstants.GetMainClassName() == "app-main" ? "Ship To #" : "Netsuite No"} onKeyDown={_handleKeyDown} onChange={(e) => setShipToNo(e.target.value)} /> </TableCell>
								<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}> <input type="text" name="" placeholder="Practice Name" onKeyDown={_handleKeyDown} onChange={(e) => setPracticeName(e.target.value)} /> </TableCell>
								<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}> <input type="text" name="" placeholder="Campaign Name" onKeyDown={_handleKeyDown} onChange={(e) => setCampaignName(e.target.value)} /> </TableCell>
								<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}> <input type="text" name="" placeholder="Brand Name" onKeyDown={_handleKeyDown} onChange={(e) => setBrandName(e.target.value)} /> </TableCell>
								<TableCell style={{ minWidth: '150px', maxWidth: '250px', padding: '5px', textAlign: "center" }}> <input type="text" name="" placeholder="Asset Category" onKeyDown={_handleKeyDown} onChange={(e) => setAssetCategory(e.target.value)} /> </TableCell>
								<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}> <input type="text" name="" placeholder="Asset Name" onKeyDown={_handleKeyDown} onChange={(e) => setAssetName(e.target.value)} /> </TableCell>
								<TableCell style={{ minWidth: '150px', maxWidth: '250px', padding: '5px', textAlign: "center" }}> <input type="text" name="" placeholder={AppConstants.GetMainClassName() == "app-main" ? "VTM First Name" : "CSR First Name"} onKeyDown={_handleKeyDown} onChange={(e) => setCSRFirstName(e.target.value)} /> </TableCell>
								<TableCell style={{ minWidth: '150px', maxWidth: '250px', padding: '5px', textAlign: "center" }}> <input type="text" name="" placeholder={AppConstants.GetMainClassName() == "app-main" ? "VTM First Name" : "CSR Last Name"} onKeyDown={_handleKeyDown} onChange={(e) => setCSRLastName(e.target.value)} /> </TableCell>
								<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}> <input type="text" name="" placeholder="VTM #" onKeyDown={_handleKeyDown} onChange={(e) => setVTM_Territory(e.target.value)} /> </TableCell>
								<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}> <input type="text" name="" placeholder="Veeva Code" onKeyDown={_handleKeyDown} onChange={(e) => setVeevaCode(e.target.value)} /> </TableCell>
								<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}>
									<select onChange={(e) => {
										setVS(e.target.value)
										props.handleApplyFilter(DownloadedAt, FullName, PracticeName, e.target.value, VSP, ShipToNo, CampaignName, BrandName, AssetCategory, AssetName, CSRFirstName, CSRLastName, VeevaCode, VTM_Territory)
									}}>
										<option value={""}>All</option>
										<option value={"1"}>Yes</option>
										<option value={"0"}>No</option>
									</select>
								</TableCell>
								<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}>
									<select onChange={(e) => {
										setVSP(e.target.value)
										props.handleApplyFilter(DownloadedAt, FullName, PracticeName, VS, e.target.value, ShipToNo, CampaignName, BrandName, AssetCategory, AssetName, CSRFirstName, CSRLastName, VeevaCode, VTM_Territory)
									}}>
										<option value={""}>All</option>
										<option value={"1"}>Yes</option>
										<option value={"0"}>No</option>
									</select>
								</TableCell>

							</TableRow>
						</TableHead>
						<TableBody>
							{!!countryList.length ? countryList.map((obj, idnex) => {
								return (
									<TableRow key={idnex}>
										<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}>{obj.DownloadedAt}</TableCell>
										<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}>{obj.FullName} </TableCell>
										<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}>{obj.ShipToNo} </TableCell>
										<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}>{obj.PracticeName} </TableCell>
										<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}>{obj.CampaignName} </TableCell>
										<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}>{obj.BrandName} </TableCell>
										<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}>{obj.AssetCategory} </TableCell>
										<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}>{obj.AssetName} </TableCell>
										<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}>{obj.CSRFirstName} </TableCell>
										<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}>{obj.CSRLastName} </TableCell>
										<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}>{obj.VTM_Territory} </TableCell>
										<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }}>{obj.VeevaCode} </TableCell>
										<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }} className={obj.VS === "Yes" ? 'green' : 'red'}> <span>{obj.VS == "Yes" ? 'Yes' : 'No'}</span> </TableCell>
										<TableCell style={{ minWidth: '100px', maxWidth: '250px', padding: '5px', textAlign: "center" }} className={obj.VSP === "Yes" ? 'green' : 'red'}> <span>{obj.VSP == "Yes" ? 'Yes' : 'No'}</span> </TableCell>
									</TableRow>
								);
							}) : <TableRow>
								<TableCell colSpan={headerList.length} className="not_found">
									{props.progress ? <CircularProgress /> : <label>Not Currently Available</label>}
								</TableCell>
							</TableRow>}
						</TableBody>
					</Table>
				</PerfectScrollbar>
			</div>
			<Notification
				isError={isError}
				message={message}
				showNotification={showNotification}
				closeNotification={handleClearNotification.bind(this)}
			/>

			<div className="table-pagination">
				<div className="pagination-info">
					<p>Showing {from + 1} - {to > count ? count : to} of {count}</p>
				</div>
				<div className="pagination-control">
					<a href="#!" title="" className={`double-left-arrow ${currentPage === 1 || currentPage === 0 ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(1)
						}}
					><img src={double_left_arrow} alt="left" /></a>
					<a href="#!" title="" className={`simple-left-arrow ${currentPage === 1 || currentPage === 0 ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(currentPage - 1)
						}}
					><img src={left_arrow} alt="single_left" /></a>
					<span>Page</span>
					<input type="text" name="textbox" value={currentPage === 0 ? currentPage + 1 : currentPage} onChange={(e) => handleChangeInCurrentPage(e.target.value)} />
					<span>of {page}</span>
					<a href="#!" title="" className={`simple-right-arrow ${currentPage === page ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(currentPage + 1)
						}}
					><img src={right_arrow} alt="right" /></a>
					<a href="#!" title="" className={`double-right-arrow ${currentPage === page ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(page)
						}}><img src={double_right_arrow} alt="single_right" /></a>
					<select onChange={(e) => props.handleChangeRowsPerPage(e.target.value, DownloadedAt, FullName, PracticeName, VS, VSP, ShipToNo, CampaignName, BrandName, AssetCategory, AssetName, CSRFirstName, CSRLastName, VeevaCode, VTM_Territory)} defaultValue={rowsPerPage}>
						{
							rowsPerPageOptions.map((obj, index) => {
								return <option value={obj} key={index}>{obj}</option>
							})
						}
					</select>
				</div>
			</div>
		</>
	);
};

export default AssetDownloadReportList;
