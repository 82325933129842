/*eslint-disable eqeqeq*/
import React from 'react';
import Table from "../../../../shared/components/table/table";
import TableHead from "../../../../shared/components/table/tableHead";
import TableRow from "../../../../shared/components/table/tableRow";
import TableBody from "../../../../shared/components/table/tableBody";
import TableCell from "../../../../shared/components/table/tableCell";
import * as APIController from "../../../../api/APIController.js";
import CircularProgress from "../../../../shared/components/CircularProgress/CircularProgress";
import { Typography, } from '@material-ui/core';
import PerfectScrollbar from "react-perfect-scrollbar";
import PageTitle from "../../../../config/pageTitle";
import * as AppConsatnt from "../../../../config/AppConstant";
import double_left_arrow from '../../../../assets/images/left-doble-arow.svg';
import left_arrow from '../../../../assets/images/left-arrow.svg';
import double_right_arrow from '../../../../assets/images/right-doble-arow.svg';
import right_arrow from '../../../../assets/images/right-arrow.svg';

class Order extends React.Component {
    state = {
        list: [],
        headerList:
            localStorage.getItem("headerMenuAdmin") == 1 ? ["Order Date", "Order #", "Practice Name", "Products Ordered", "Product Cost"]
                : ["Order Date", "Order #", "Products Ordered", "Product Cost"],
        progress: false,
        currentPage: 1,
        count: 0,
        rowsPerPage: 25,
        rowsPerPageOptions: [25, 50, 100, 'All'],
        from: 0,
        to: 25,
        page: Math.floor(0 / 25) + 1,
        Title: '', NotificationAt: '', TypeModule: ''
    };
    componentDidMount() {
        this.wsGetOrderList(this.state.rowsPerPage, this.state.currentPage, this.state.from, this.state.to);
    }

    wsGetOrderList(RecordsPerPage, CurrentPage, from, to) {
        this.setState({ progress: true, });
        APIController.GetOrderList().then((response) => {
            if (response.object.data.length) {
                this.setState({
                    to: isNaN(to) ? 1000000 : to,
                    from: from,
                    count: response.object.data[0].MaxRows,
                    list: response.object.data,
                    page: 25 == 1000000 ? 1 : Math.floor(response.object.data[0].MaxRows / 25) + 1,
                    progress: false,
                });
            } else {
                this.setState({
                    list: [],
                    count: 0,
                    progress: false,
                });
            }
        });
    }

    handleChangeInCurrentPage = (value) => {
        if (value <= this.state.page && Number(parseInt(value))) {
            this.handlePageChange(Number(value), this.state.Title, this.state.NotificationAt, this.state.TypeModule);
        }
    }

    handleRowsPerPageChange = (value, Title, NotificationAt, TypeModule) => {
        let from = 0
        let to = value;
        this.setState({ rowsPerPage: value, currentPage: 1, to: to, from: from })
        this.wsGetNotifications(Title, NotificationAt, TypeModule, isNaN(value) ? 1000000 : value, isNaN(value) ? '' : this.state.currentPage, from, to);
    }

    handlePageChange = (values, Title, NotificationAt, TypeModule) => {
        let from = values === 1 ? 0 * this.state.rowsPerPage : (values - 1) * this.state.rowsPerPage
        let to = values === 1 ? this.state.rowsPerPage : (from + this.state.rowsPerPage);
        this.setState({ currentPage: Number(values), to: to, from: from })
        this.wsGetNotifications(Title, NotificationAt, TypeModule, this.state.rowsPerPage, Number(values), from, to);
    }

    render() {
        const { currentPage, count, rowsPerPage, rowsPerPageOptions, list, from, to, page, } = this.state;
        return (
            <>
                <section className='content-right-main'>
                    <PageTitle Title={AppConsatnt.ContactDetais.YourOrders} />
                    <div className="table-main-wrap countrys-class">
                        <PerfectScrollbar component='div' style={{ width: '100%' }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {this.state.headerList.map((obj, index) => {
                                            return <TableCell style={index == 0 ?
                                                { width: '15%', textAlign: 'start' } : index == this.state.headerList.length - 1 ?
                                                    { width: '150px', minWidth: '150px', textAlign: 'end' } : index == this.state.headerList.length - 2 ?
                                                        { width: '50%', textAlign: 'left' } :
                                                        { width: '10%', textAlign: 'center' }} key={index}>{obj}</TableCell>;
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.list.length ? this.state.list.map((obj, index) => {
                                        return (
                                            <TableRow>
                                                <TableCell> <Typography style={{ color: '#0A0A0A', fontSize: '13px', textAlign: 'start' }}>{obj.OrderDate}</Typography> </TableCell>
                                                <TableCell> <Typography style={{ color: '#0A0A0A', fontSize: '13px', textAlign: 'center' }}>{obj.OrderNo}</Typography> </TableCell>
                                                {localStorage.getItem("headerMenuAdmin") == 1 && <TableCell> <Typography style={{ color: '#0A0A0A', fontSize: '13px', textAlign: 'center' }}>{obj.PracticeName}</Typography> </TableCell>}
                                                <TableCell>
                                                    {JSON.parse(obj.Products) != null ? JSON.parse(obj.Products).map((obj, index) => {
                                                        return (
                                                            <Typography style={{ color: '#0A0A0A', fontSize: '13px', fontWeight: 500, }}>{obj.Product}</Typography>
                                                        );
                                                    }) : null}
                                                </TableCell>
                                                <TableCell style={{ width: '150px', minWidth: '150px' }}>
                                                    <Typography style={{ color: '#0A0A0A', fontSize: '13px', textAlign: 'end', fontWeight: 500, }}>{obj.ProductCost}</Typography>
                                                    <Typography style={{ color: '#177BC9', fontSize: '13px', textAlign: 'end', marginTop: '5px', cursor: 'pointer' }}
                                                        onClick={() => this.props.history.push({ pathname: '/orderdetail', state: { OrderIDDetails: obj.ID, OrderNo: obj.OrderNo } })}>View Order Details</Typography>
                                                    {localStorage.getItem("headerMenuAdmin") == 1 && <>
                                                        <Typography style={{ color: '#177BC9', fontSize: '13px', textAlign: 'end', marginTop: '5px', cursor: 'pointer' }}
                                                            onClick={() => this.props.history.push({ pathname: '/ConfirmReport', state: { OrderIDDetails: obj.ID, OrderNo: obj.OrderNo } })}>Configure Reports</Typography>
                                                        <Typography style={{ color: '#177BC9', fontSize: '13px', textAlign: 'end', marginTop: '5px', cursor: 'pointer' }}
                                                            onClick={() => this.props.history.push({ pathname: '/ReportData', state: { OrderID: obj.ID, OrderNo: obj.OrderNo } })}>Report Data</Typography>
                                                    </>}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    }) : <TableRow>
                                        <TableCell colSpan={this.state.headerList.length} className="not_found">
                                            {this.state.progress ?
                                                <CircularProgress /> :
                                                <label style={{ display: 'flex', justifyContent: 'center' }}>No Order Found</label>
                                            }
                                        </TableCell>
                                    </TableRow>}
                                </TableBody>
                            </Table>
                            <div className="table-pagination">
                                <div className="pagination-info">
                                    <p>Showing {from + 1} - {to > count ? count : to} of {count}</p>
                                </div>
                                <div className="pagination-control">
                                    <a href="#!" title="" className={`double-left-arrow ${currentPage === 1 ? 'disabled' : ''}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.handleChangeInCurrentPage(1)
                                        }}
                                    ><img src={double_left_arrow} alt="left" /></a>
                                    <a href="#!" title="" className={`simple-left-arrow ${currentPage === 1 ? 'disabled' : ''}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.handleChangeInCurrentPage(currentPage - 1)
                                        }}
                                    ><img src={left_arrow} alt="single_left" /></a>
                                    <span>Page</span>
                                    <input type="text" name="textbox" value={currentPage === 0 ? currentPage + 1 : currentPage} onChange={(e) => this.handleChangeInCurrentPage(e.target.value)} />
                                    <span>of {page}</span>
                                    <a href="#!" title="" className={`simple-right-arrow ${currentPage === page ? 'disabled' : ''}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.handleChangeInCurrentPage(currentPage + 1)
                                        }}
                                    ><img src={right_arrow} alt="right" /></a>
                                    <a href="#!" title="" className={`double-right-arrow ${currentPage === page ? 'disabled' : ''}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.handleChangeInCurrentPage(page)
                                        }}><img src={double_right_arrow} alt="single_right" /></a>
                                    <select onChange={(e) => this.handleRowsPerPageChange(e.target.value, this.state.Title, this.state.NotificationAt, this.state.TypeModule)} defaultValue={rowsPerPage}>
                                        {
                                            rowsPerPageOptions.map((obj, index) => {
                                                return <option value={obj} key={index}>{obj}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        </PerfectScrollbar>
                    </div>
                </section>
            </>
        )
    }
}

export default Order;