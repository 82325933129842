import React from "react";
import { useState, useEffect } from "react";
import * as APIController from "../../../api/APIController.js";
import * as AppConstants from "../../../config/AppConstant";
import CircularProgress from "../../../shared/components/CircularProgress/CircularProgress";

const InstaReturnURL = (props) => {

	const [IsLoading, setIsLoading] = useState(false);

	useEffect(() => {
		let instagraamCode = window.location.search.split('?code=').pop()
		if (instagraamCode != '' || instagraamCode != undefined) {
			wsGetInstagramAccessToken("1381812735665762", AppConstants.instagramConfig.client_secret, "authorization_code", AppConstants.instagramConfig.redirect_uri, instagraamCode)
		} else {
			window.close()
		}

	}, [props])

	const wsGetInstagramAccessToken = (client_id, client_secret, grant_type, redirect_uri, code) => {
		setIsLoading(true)
		APIController.GetInstagramAccessToken(client_id, client_secret, grant_type, redirect_uri, code).then((response) => {
			console.log('Received Access Token', response);
			setIsLoading(true)
			if (response.access_token != '' && response.access_token != undefined) {
				console.log('Inside Received Access Token For Next Call')
				localStorage.setItem("localStorageInstaPageData", response.access_token)
				console.log('Local Storage - 1 Done')
				wsGetInstagramUserData(response.access_token)
				setIsLoading(false)
			}
		});
	}
	const wsGetInstagramUserData = (access_token) => {
		setIsLoading(true)
		APIController.GetInstagramUserData(access_token).then((response) => {
			setIsLoading(true)
			console.log('Me Response', response);
			if (response != '') {
				console.log('Before Final Data Set');
				localStorage.setItem("localStorageInstaUserData", JSON.stringify(response))
				console.log('After Final Data Set');
				window.close()
			} else {
				alert("Error", response)
				// handleShowNotification(response.error.message, true);
				setIsLoading(false)
			}
		});
	}

	return (
		<CircularProgress />
	);
};

export default InstaReturnURL;
