import React from "react";
import * as APIController from "../../../api/APIController.js";
import CircularProgress from "../../../shared/components/CircularProgress/CircularProgress";
import "./welcome-screen.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MenuItem from '@material-ui/core/MenuItem';
import Slider from "react-slick";
import * as AppConstants from "../../../config/AppConstant";
import Timezone from './Timezone.json'
import ProductTabSelection from './product-tab-selection';

import FacebookLogin from 'react-facebook-login';
// import { InstagramLogin } from "@amraneze/react-instagram-login";
import { InstagramLogin } from "react-instagram-oauth";

import ModalComponent from "../../../shared/components/modal/modal";
import close_icon from "../../../assets/images/gray-close-ic.svg";
import MyiFrame from "../../../features/campaign/components/myiFrame";

// import Page from './indextemp.html'
// var htmlDoc = { __html: Page };

const SocialAutomation = (props) => {
	const [WhichSMClicked, setWhichSMClicked] = React.useState('');
	const [Description, setDescription] = React.useState('');
	const [PreviewImageURL, setPreviewImageURL] = React.useState('');
	const [Loading, setLoading] = React.useState(true)
	const [ProductImages, setProductImages] = React.useState([]);
	const [GetBrands, setGetBrands] = React.useState([]);
	const [ChildrenTabs, setChildrenTabs] = React.useState([]);
	const [PageTitle, setPageTitle] = React.useState(false);
	const [duration, setDuration] = React.useState('');
	const [FacebookPageName, setFacebookPageName] = React.useState('');
	const [FacebookAccessToken, setFacebookAccessToken] = React.useState('');
	const [InstagramAccessToken, setInstagramAccessToken] = React.useState('');
	const [InstagramPageName, setInstagramPageName] = React.useState('');
	const [isModalOpen, setIsModalOpen] = React.useState(false);
	const [facebookverified, setfacebookverified] = React.useState(false);
	const [facebookPopup, setfacebookPopup] = React.useState(true);
	const [instagramverified, setinstagramverified] = React.useState(false);
	const [SelectFBPageID, setSelectFBPageID] = React.useState([]);
	const [facebookPages, setFacebookPages] = React.useState([]);
	const [PreviewModalOpen, setPreviewModalOpen] = React.useState(false);
	const [AutomationSubscriptionID, setAutomationSubscriptionID] = React.useState(AppConstants.GetLocalStorage("localStorageAutomationSubscriptionID"));

	React.useEffect(() => {
		let data = AppConstants.GetLocalStorage("localStorageDuration") != '' && JSON.parse(AppConstants.GetLocalStorage("localStorageDuration"));
		wsGetProductByID(AppConstants.GetLocalStorage('localStorageProductID'))


		if (AppConstants.GetLocalStorage('localStorageChildren') != ""
			&& AppConstants.GetLocalStorage('localStorageChildren') != undefined
			&& AppConstants.GetLocalStorage('localStorageChildren') != null) {
			setChildrenTabs(JSON.parse(AppConstants.GetLocalStorage('localStorageChildren')))
		}
		else {
			setChildrenTabs([])
		}
		//code by Nilesh patel
		if (AppConstants.GetLocalStorage('IsDirectPageURL') != ""
			&& AppConstants.GetLocalStorage('IsDirectPageURL') != undefined
			&& AppConstants.GetLocalStorage('IsDirectPageURL') != null) {
			setPageTitle(JSON.parse(AppConstants.GetLocalStorage('IsDirectPageURL')))
		}

		setDuration(data != '' ? data[0].Duration : '')
		
		if (AppConstants.GetLocalStorage('localStorageFBPageData') != ""
			&& AppConstants.GetLocalStorage('localStorageFBPageData') != undefined
			&& AppConstants.GetLocalStorage('localStorageFBPageData') != null) {
			setfacebookverified(true)
			setFacebookPageName(JSON.parse(AppConstants.GetLocalStorage('localStorageFBPageData')).name)
		}

		if (AppConstants.GetLocalStorage('localStorageInstaUserData') != ""
			&& AppConstants.GetLocalStorage('localStorageInstaUserData') != undefined
			&& AppConstants.GetLocalStorage('localStorageInstaUserData') != null) {
			setinstagramverified(true)
			setInstagramPageName(JSON.parse(AppConstants.GetLocalStorage('localStorageInstaUserData')).username)
		}


		window.fbAsyncInit = function () {
			window.FB.init({
				appId: '307760978095007',
				cookie: false, // Enable cookies to allow the server to access the session.
				xfbml: true, // Parse social plugins on this webpage.
				version: 'v12.0' // Use this Graph API version for this call.
			})

			window.FB.getLoginStatus(function (response) { // Called after the JS SDK has been initialized.
				checkFacebookLoginStatusCallback(response); // Returns the login status.
			});
		};

	}, [props]);

	const timezonedata = Timezone.map((item) => {
		return (
			<MenuItem value={item.offset}>{item.text}</MenuItem>
		)
	})

	const wsGetProductByID = (ID) => {
		APIController.GetProductByID(ID)
			.then((response) => {
				if (response.error == null) {
					setDescription(response.object.data[0].Description)
					setPreviewImageURL(response.object.data[0].PreviewURL)
					wsGetProductImages(response.object.data[0].ID, AppConstants.GetLocalStorage('localStorageBrandID'))
				}
				else if (response.error.message && response.error.message.length > 0) {
					// handleNotification(response.error.message);
				}
			})
	}

	const wsGetProductImages = (ID, BrandID) => {
		APIController.GetProductImages(ID, BrandID)
			.then((response) => {
				if (response.error == null) {
					setProductImages(response.object.data)
					wsGetBrand()
				}
				else if (response.error.message && response.error.message.length > 0) {
					// handleNotification(response.error.message, true);
				}
			})
	}

	const wsGetBrand = () => {
		APIController.GetBrand("1", "AutomationOrderNo", "ASC", AutomationSubscriptionID)
			.then((response) => {
				if (response.error == null) {
					let Temp = []
					let selectedIDs = AppConstants.GetLocalStorage("localStorageBrandsData") != "" ? JSON.parse(AppConstants.GetLocalStorage("localStorageBrandsData")) : []
					if (selectedIDs.length == 0) {
						response.object.data.map((item) => {
							Temp.push({ 'Checked': item.IsSelected == 1 ? true : false, ID: item.ID, Name: item.Name })
						})
					} else {
						response.object.data.map((item) => {
							selectedIDs.map((inner) => {
								if (inner.ID == item.ID)
									Temp.push({ 'Checked': inner.Checked, ID: inner.ID, Name: item.Name })
							})
						})
					}
					setGetBrands(Temp)
				}
				setLoading(false)
			})
	}

	const handleCheckList = (ID) => {
		setGetBrands(GetBrands.map((item, index) => item.ID !== ID ? item : { ...item, Checked: !item.Checked }))
	};

	const settings = {
		dots: false,
		infinite: true,
		speed: 3000,
		slidesToShow: 1,
		slidesToScroll: 1,
		nav: false,
		arrows: false,
		autoplay: true,
		customPaging: i => (<div style={{ height: 10, width: 10, borderRadius: 50, backgroundColor: '#3232' }}></div>)
	};

	const wsResponseFacebook = (responseFacebook) => {
		setLoading(true)
		// https://graph.facebook.com/v12.0/me/accounts?access_token=
		// https://graph.facebook.com/v12.0/106069031986459?fields=page_token&access_token=
		if (responseFacebook.status == "unknown") {
			setIsModalOpen(false)
			setfacebookverified(false)
			setLoading(false)
		} else {
			AppConstants.SetLocalStorage("localStorageFacebookUserID", responseFacebook.id)
			APIController.GetFacebookPages(responseFacebook.accessToken)
				.then((response) => {
					setFacebookAccessToken(responseFacebook.accessToken)
					setFacebookPages(response.object.data)
					setIsModalOpen(true)
					setLoading(false)
				})
		}
	};
	const responseInstagram = (response) => {
		response.status == "unknown" && setinstagramverified(false)
	};

	const authHandler = (err, data) => {
	};

	function checkFacebookLoginStatusCallback(response) { // Called with the results from FB.getLoginStatus().
		if (response.status === 'connected') { // Logged into your webpage and Facebook.
			// getUserProfile();
			window.FB.logout(function (response) { })
		}
	}

	function checkLoginState() { // Called when a person is finished with the Login Button.
		window.FB.getLoginStatus(function (response) { // See the onlogin handler
			checkFacebookLoginStatusCallback(response);
		});
	}

	return (
		<section className='content-right-main'>
			{/* {AppConstants.ShowPageTitle()} */}
			{PageTitle ?
				<div className="content-right-heading">

					{AppConstants.GetLocalStorage('localStorageProductName')}
				</div> :
				<div className="content-right-heading">
					{AppConstants.GetLocalStorage('localStorageCampaignName').split('®')[0]}
					{AppConstants.GetLocalStorage('localStorageCampaignName').match(/®/g) ? <sup>&reg;</sup> : ''}
					{AppConstants.GetLocalStorage('localStorageCampaignName').split('®')[1]}{': '}
					{AppConstants.GetLocalStorage('localStorageProductName')}
				</div>}
			{ChildrenTabs.length > 1 &&
				<ProductTabSelection history={props.history} ChildrenTabs={ChildrenTabs} INDEX={props.history.location.state != undefined ? props.history.location.state.Index : 0} />}

			{Loading ? <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}><CircularProgress /></div> :
				<>
					<div className='two-col-sec digital-welcome white-box'>

						<div className='sec-left box-body'>
							<div style={{ letterSpacing: '0px' }}>
								<div dangerouslySetInnerHTML={{ __html: Description }} />
							</div>

							<label>Select the Brands You'd Like to Include in Your Social Posts</label>
							<div className="table-body">
								{GetBrands.length && GetBrands.map((item, index) => (
									<div className='custom-checkbox' style={{ marginTop: '10px' }} >
										<input type='checkbox'
											name={`${item.ID}`}
											id={`${item.ID}`}
											defaultChecked={item.Checked}
											onChange={(e) => { handleCheckList(item.ID); }}
										/>
										<label htmlFor={`${item.ID}`} style={{ paddingLeft: '40px', display: 'flex', alignContent: 'center' }}>
											{item.Name}</label>
									</div>
								))}

							</div>
						</div>

						<div className="sec-right" style={{ padding: '0px', margin: '0px', zIndex: '0' }} >
							<div className='image-slider' style={{ height: '350px' }} >
								<Slider  {...settings}>
									{!!ProductImages.length ? ProductImages.map((obj, index) => {
										return (<>
											<img style={{ maxHeight: '700px', width: '100%', resize: 'vertical' }} src={obj.ImageURL} alt='slider-2' />
											{PreviewImageURL != null && PreviewImageURL != undefined && PreviewImageURL != "" && <div style={{ display: 'flex', justifyContent: 'center' }}>
												<label style={{ cursor: 'pointer', color: '#1073c6', textDecoration: 'underline' }} onClick={() => setPreviewModalOpen(true)} >PREVIEW SOCIAL CALENDAR</label>
											</div>}
										</>)
									}) : ''}
								</Slider>
							</div>
						</div>
					</div>

					<div className='two-col-sec digital-welcome sec-left' style={{ display: 'grid' }}>
						{AppConstants.GetLocalStorage("ShowSMAuthentication") == "1" || AppConstants.GetLocalStorage("ShowInstaAutomation") == "1"
							? <>
								<div className='white-box'>
									<div className='box-head'> <h4>Social Media Credentials</h4> </div>
									<div className='box-body'>
										<p>Please verify your Facebook and/or Instagram credentials below so REACH can post approved Bausch + Lomb content to your BUSINESS pages on your behalf. Credential verification occurs directly with Facebook and Instagram. REACH does NOT store nor does it have access to these credentials.</p>
										<div className='table-body'>

											{AppConstants.GetLocalStorage("ShowSMAuthentication") == "1"
												&& <>
													<div className='field-full'>
														<div style={{ display: 'flex' }}>
															<span>Business Facebook Page: &nbsp;</span>
															<label>{FacebookPageName}</label>
														</div>
													</div>

													<div>
														<label style={facebookverified ? { paddingLeft: '40px', display: 'flex', alignContent: 'center' } : { paddingLeft: '40px', display: 'flex', alignContent: 'center', color: '#f00' }}>
															{facebookverified ? "Facebook Credentials Verified" : "Please Verify Facebook Credentials"}</label>

														<div className='custom-checkbox'>
															<input type='checkbox'
																name='facebook-verified'
																id={"FacebookCredentials"}
																value={facebookverified}
																defaultChecked={facebookverified}
																checked={facebookverified}
															/>
															<label htmlFor="FacebookCredentials" style={{ top: '7px' }}></label>

															{facebookPopup && <FacebookLogin
																appId={AppConstants.facebookConfig.client_id}
																autoLoad={false}
																fields={AppConstants.facebookConfig.fields}
																scope={AppConstants.facebookConfig.fbscope}
																callback={wsResponseFacebook}
																cssClass="my-facebook-button-class"
																onClick={() => { setWhichSMClicked('1') }}
																render={renderProps => (
																	<button onClick={renderProps.onClick}>This is my custom FB button</button>
																)}
															/>}
														</div>
													</div>
												</>}

											{AppConstants.GetLocalStorage("ShowInstaAutomation") == "1"
												&& <>
													<div className='field-full' style={{ marginTop: '10px' }}><br />
														<div style={{ display: 'flex' }}>
															<span>Business Instagram Page: &nbsp;</span>
															<label>{InstagramPageName}</label>
														</div>
													</div>

													<div>
														<label style={instagramverified ? { paddingLeft: '40px', display: 'flex', alignContent: 'center' } : { paddingLeft: '40px', display: 'flex', alignContent: 'center', color: '#f00' }}>
															{instagramverified ? "Instagram Credentials Verified" : "Please Verify Instagram Credentials"}</label>

														<div className='custom-checkbox' >
															<input type='checkbox'
																name=''
																id={"InstagramCredentials"}
																value={instagramverified}
																defaultChecked={instagramverified}
																checked={instagramverified}
															/>
															<label htmlFor="InstagramCredentials" style={{ top: '7px' }}></label>

															<FacebookLogin
																appId={AppConstants.facebookConfig.client_id}
																autoLoad={false}
																fields={AppConstants.facebookConfig.fields}
																scope={AppConstants.facebookConfig.instascope}
																callback={wsResponseFacebook}
																onClick={() => { setWhichSMClicked('2') }}
																cssClass="my-instagram-button-class"
																textButton="Login With Instagram"
															/>
														</div>
													</div>
												</>}
										</div>
									</div>
								</div>
							</> : <></>}

						<div className={`button-field 
						${(AppConstants.GetLocalStorage("ShowSMAuthentication") == "1" || AppConstants.GetLocalStorage("ShowInstaAutomation") == "1")
								&& AppConstants.GetLocalStorage("isSocialMediaRequired") == "0" ? "" : facebookverified || instagramverified
								? "" : "disable-btn"}`} style={{ marginTop: "40px", marginLeft: "2%" }}>
							<button onClick={() => {
								props.history.push("/digital-product/selection/location-selection")
								AppConstants.SetLocalStorage("localStorageBudgetDetail", JSON.stringify([{ "budget": 1, "duration": duration }]))
								AppConstants.SetLocalStorage("localStorageAutomation", "YES")
								AppConstants.SetLocalStorage("localStorageBrandsData", JSON.stringify(GetBrands))
							}}
							>{AppConstants.ButtonText.GetStarted}</button>
						</div>
					</div>
				</>
			}

			{isModalOpen &&
				<ModalComponent show={isModalOpen} >
					<div className="popup-box forgot-popup-box" style={{ minWidth: '700px' }} >
						<div className="box-heading">
							{/* <img src={forgot_user_ic} alt="icon" /> */}
							<h2 className="h1">{WhichSMClicked == '1' ? 'Select A Business Page' : 'Select Page Connected with Professional Instagram Account'}</h2>
							<button className="close-btn"><img src={close_icon} alt="icon" onClick={() => {
								setIsModalOpen(false);
								if (WhichSMClicked == '1') {
									setfacebookverified(false)
								} else if (WhichSMClicked == '2') {
									setinstagramverified(false)
								}

							}} /></button>
						</div>
						<div className="popup-wrap" style={{ margin: 0, padding: '0 20px', display: 'flex', flexWrap: 'wrap' }}>
							{facebookPages.length
								? facebookPages.map((item) =>
									<div className='location-inner-content' style={{ padding: '15px 0' }}>
										<div className='location-name'>
											<div className={'custom-radio'} >
												<input
													type={'radio'}
													name='location'
													id={`${item.id}`}
													// checked={!!selectedLocations.length && selectedLocations.find((ob) => ob.id === item.id)}
													// onChange={(e) => {
													// 	if (e.target.checked) { onSelectFBPageID(locationDetail); }
													// 	else { onDeSelectAddress(locationDetail); }
													// }}
													onClick={(e) => { setSelectFBPageID(item) }}
												/><label htmlFor={`${item.id}`} style={{ fontWeight: 400 }}>
													{item.name}
												</label>
											</div>
										</div>
									</div>
								)
								: <div style={{ padding: '20px', textAlign: 'center' }}>
									<label>We did not find any Business Page associated with your Facebook Account. Please create the Facebook Business Page and Authenticate Again.</label>
								</div>}

							<div className={SelectFBPageID != "" ? `form-field button-field` : `form-field button-field disable-btn`} style={{ textAlign: 'center', margin: '20px 0', justifySelf: 'flex-end' }} >
								{facebookPages.length ? <input type="submit" name="select-page" value="Select"
									onClick={() => {
										if (WhichSMClicked == '1') {	//Facebook Clicked
											AppConstants.SetLocalStorage("localStorageFBPageData", JSON.stringify(SelectFBPageID));
											setIsModalOpen(false);
											setfacebookverified(true)
											setFacebookPageName(SelectFBPageID.name)
										}
										else if (WhichSMClicked == '2')	//Instagram Cliked
										{
											APIController.GetInstaAccount(FacebookAccessToken, SelectFBPageID.id)
												.then((response) => {
													if (response != null && response.object != null) {
														setinstagramverified(true)
														localStorage.setItem("localStorageInstaPageData", FacebookAccessToken)
														localStorage.setItem("localStorageInstaUserData", JSON.stringify(response.object))
														setInstagramPageName(response.object.username)
														setIsModalOpen(false)
													} else {
														alert('We did not find any Instagram Business Account associated with your Facebook Page. Please Associate with Facebook Page and Authenticate Again.');
													}
												})
										}
									}} /> : <input type="submit" name="close-popup" value="Close" onClick={() => { setIsModalOpen(false) }} />}
							</div>
						</div>
					</div>
				</ModalComponent>}

			{PreviewModalOpen && (
				<ModalComponent show={PreviewModalOpen} handleClose={() => setPreviewModalOpen(false)}>
					<div className="popup-wrap">
						{PreviewImageURL != null || PreviewImageURL != undefined || PreviewImageURL != "" ?
							PreviewImageURL.indexOf('.mp4') != -1 || PreviewImageURL.indexOf('.pdf') != -1 ?
								<MyiFrame isFacbook={true} Height={'500px'} Width={'950px'} src={PreviewImageURL + "?" + Math.floor(Math.random() * (999999 - 100000 + 1) + 100000)} />
								: PreviewImageURL.indexOf('.gif') != -1 ?
									<img src={PreviewImageURL} alt="place" style={{ height: '550px' }} />
									: <img src={PreviewImageURL} alt="img" />
							: <></>
						}
						<a title="" className="gray-close-ic">
							<img src={close_icon} alt="icon" onClick={() => setPreviewModalOpen(false)} />
						</a>
					</div>
				</ModalComponent>)}
		</section>
	);
};

export default SocialAutomation;
