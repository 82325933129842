/*eslint-disable eqeqeq*/
import React from "react";
import * as APIController from "../../../../api/APIController.js";
import Notification from "../../../../shared/components/notification/notification";

import close_ic from '../../../../assets/images/close-ic.svg';
import forgot_user_ic from '../../../../assets/images/forgot-username-ic.svg';

import filtericon from '../../../../assets/images/ic_feather_filter.svg'
import CircularProgress from "../../../../shared/components/CircularProgress/CircularProgress";

import Table from "../../../../shared/components/table/table";
import TableHead from "../../../../shared/components/table/tableHead";
import TableRow from "../../../../shared/components/table/tableRow";
import TableBody from "../../../../shared/components/table/tableBody";
import TableCell from "../../../../shared/components/table/tableCell";

const SubscriptionForm = (props) => {
    const headerList = ["Campaign", "Year", "Month", "Practice", "Address", "Payment Status", "Amount", "Order Date"]
    const { currentPage, count, rowsPerPage, rowsPerPageOptions, list, to, from } = props;
    let countryList = list;
    countryList = list.slice(from, to)

    const [orderData, setOrderData] = React.useState([]);

    React.useEffect(() => {

        // wsGetBrandForDropdown()

        var pairs = [];
        for (let i = 0; i < 10; i++) {
            pairs.push({ ID: i + 1 });
        }
        setOrderData(pairs)
    }, []);

    const wsGetBrandForDropdown = () => {
        APIController.GetBrandForDropdown()
            .then((response) => {
                // setLoading(false)
                if (response.error == null) {

                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }


    const [message, setMessage] = React.useState("");
    const [showNotification, setShowNotification] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const handleNotification = (message, isError) => {
        setMessage(message);
        setShowNotification(true);
        setIsError(isError);

        setTimeout(() => {
            handleClearNotification();
        }, 4000);
    };

    const handleClearNotification = () => {
        setMessage("");
        setShowNotification(false);
        setIsError(false);
    };

    return (
        <div className="popup-box forgot-popup-box" >
            <div className="box-heading">
                <img src={forgot_user_ic} alt="icon" />
                <h2 className="h1">{props.titleName}</h2>
                <button className="close-btn"><img src={close_ic} alt="icon" onClick={props.closeModal} /></button>
            </div>
            <Table>
                <TableHead>
                    <TableRow>
                        {headerList.map((obj, index) => {
                            return <TableCell style={index == 0 ? { minWidth: "10%" } : index == index.length - 1 ? { width: '10%' } : { width: '80%' }} className={obj === "Is active ?" ? 'center' : ''} key={index}>{obj}</TableCell>;
                        })}
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <span><img src={filtericon} alt="delete" /></span>
                        </TableCell>
                        <TableCell>
                            <input type="text" name="" placeholder="Campaign" onChange={(e) => props.applyFilter('Name', e.target.value, 'input')} />
                        </TableCell>
                        <TableCell>
                            <input type="text" name="" placeholder="Year" onChange={(e) => props.applyFilter('Name', e.target.value, 'input')} />
                        </TableCell>
                        <TableCell>
                            <input type="text" name="" placeholder="Month" onChange={(e) => props.applyFilter('Name', e.target.value, 'input')} />
                        </TableCell>
                        <TableCell>
                            <input type="text" name="" placeholder="Practice" onChange={(e) => props.applyFilter('Name', e.target.value, 'input')} />
                        </TableCell>
                        <TableCell>
                            <input type="text" name="" placeholder="Address" onChange={(e) => props.applyFilter('Name', e.target.value, 'input')} />
                        </TableCell>
                        <TableCell>
                            <input type="text" name="" placeholder="Subscribed At" onChange={(e) => props.applyFilter('Name', e.target.value, 'input')} />
                        </TableCell>
                        <TableCell>
                            <input type="text" name="" placeholder="Status" onChange={(e) => props.applyFilter('Name', e.target.value, 'input')} />
                        </TableCell>
                        <TableCell>
                            <input type="text" name="" placeholder="Subscription At" onChange={(e) => props.applyFilter('Name', e.target.value, 'input')} />
                        </TableCell>
                        <TableCell>
                            <input type="text" name="" placeholder="Start At" onChange={(e) => props.applyFilter('Name', e.target.value, 'input')} />
                        </TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {!!countryList.length ? countryList.map((obj, idnex) => {
                        return (
                            <TableRow key={idnex}>
                                <TableCell>{obj.Name}</TableCell>
                                <TableCell>{obj.Name}</TableCell>
                                <TableCell>{obj.Name}</TableCell>
                                <TableCell>{obj.Name}</TableCell>
                                <TableCell>{obj.Name}</TableCell>
                                <TableCell>{obj.Name}</TableCell>
                                <TableCell>{obj.Name}</TableCell>
                                <TableCell>{obj.Name}</TableCell>
                                <TableCell>{obj.Name}</TableCell>
                            </TableRow>
                        );
                    }) : <TableRow>
                        <TableCell colSpan={headerList.length} className="not_found">
                            {props.progress ?
                                <CircularProgress /> :
                                <label>Not Currently Available</label>}
                        </TableCell>
                    </TableRow>}
                </TableBody>
            </Table>
            <Notification
                isError={isError}
                message={message}
                showNotification={showNotification}
                clearNotification={handleClearNotification.bind(this)}
                closeNotification={handleClearNotification.bind(this)}
            />
        </div >

    );
};

export default SubscriptionForm;
