/*eslint-disable eqeqeq*/
import React from "react";
import { Link } from "react-router-dom";
import { Formik, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import Slider from "../../../shared/components/slider/slick-slider";
import * as AppConstants from "../../../config/AppConstant";
import * as APIController from "../../../api/APIController.js";
import "../component/login.css";
import Notification from "../../../shared/components/notification/notification";
import TextField from "../../../shared/components/TextField/TextField";

class SetPassword extends React.Component {
	state = {
		password: "",
		newpassword: "",
		IsLoading: false,
		requestid: "",
	};
	componentDidMount() {
		this.setState({ requestid: this.props.location.search.split('=').pop() });
	}

	wsSetPassword(RequestID, Password) {
		this.setState({ progress: true, });
		APIController.SetPassword(RequestID, Password).then((response) => {
			this.setState({ IsLoading: false });
			if (response.error == null) {
				if (response.object.status == 1) {
					this.handleShowNotification(response.object.message);
					setTimeout(() => {
						this.props.history.push("/login");
					}, 4000);
				} else {
					this.handleShowNotification(response.object.message, true);
				}
			} else if (response.error.message) {
				this.handleShowNotification(response.error.message, true);
			}
		});
	}

	render() {
		return (
			<>
				<section className='login_page'>
					<div className='main'>
						<div className='inner-screen'>
							<div className='left-column'>
								<div className='logo'>
									<Link to='/setpassword'> <img src={AppConstants.SetPasswordLogo} alt={AppConstants.SetPasswordLogo} /> </Link>
								</div>
								<div className='middle-content'>
									<h1>Create New Password</h1>
									<Formik
										initialValues={{ password: "", newpassword: "", }}
										validationSchema={validateLoginForm}
										validateOnMount={false}
										onSubmit={(values) => {
											if (values.password.length <= 5) {
												this.handleShowNotification("Password should be minimum 6 characters long", true);
											} else if (values.newpassword.length <= 5) {
												this.handleShowNotification("Confirm Password should be minimum 6 characters long", true);
											} else if (values.password !== values.newpassword) {
												this.handleShowNotification("Password and Confirm Password does not Match", true);
											} else {
												this.setState({ IsLoading: true });
												this.wsSetPassword(this.state.requestid, values.newpassword)
											}
										}} >
										{({ values, handleSubmit, errors, touched }) => (
											<form onSubmit={handleSubmit}>
												<div className={`form-field ${errors && touched.password && errors.password && errors.password !== "" ? "error" : ""}`}>
													<div className='field_main'>
														<Field as={TextField}
															error={touched.password && errors.password && errors.password !== "" ? true : false}
															{...this.props}
															label='Password'
															type='password'
															placeholder='******'
															name='password'
															className='pwd-field'
														/>
														{errors && touched.password && errors.password && errors.password !== "" && (
															<span className='error-msg'>
																<ErrorMessage name='password' render={(err) => { return err; }} />
															</span>)}
													</div>
												</div>
												<div className={`form-field ${errors && touched.newpassword && errors.newpassword && errors.newpassword !== "" ? "error" : ""}`}>
													<div className='field_main'>
														<Field as={TextField}
															error={touched.newpassword && errors.newpassword && errors.newpassword !== "" ? true : false}
															{...this.props}
															label='Confirm Password'
															type='password'
															placeholder='******'
															name='newpassword'
															className='pwd-field'
														/>
														{errors && touched.newpassword && errors.newpassword && errors.newpassword !== "" && (
															<span className='error-msg'>
																<ErrorMessage name='newpassword' render={(err) => { return err; }} />
															</span>)}
													</div>
												</div>
												<div className={this.state.IsLoading ? 'button-field disable-btn' : 'button-field'}>
													<input type='submit' name='Create' value={this.state.IsLoading ? "Creating..." : "Create"} />
												</div>
											</form>
										)}
									</Formik>
								</div>
							</div>
							<div className='right-column' style={{ padding: '0px', margin: '0px', position: 'relative' }}>
								{/* <div className='col-head'>
									<h2>{AppConstants.BLSetPasswordSlider.HeadingText}</h2>
									<p>{AppConstants.BLSetPasswordSlider.PtagText}</p>
								</div> */}

								<div className='image-slider'>
									<Slider>
										{AppConstants.BLLoginSlider.Images.map((object, index) => {
											return (<div className='image-slider'>
												<img src={object.ImageUrl} alt={object.ImageUrl} />
											</div>)
										})}
									</Slider>
								</div>
							</div>
						</div>
					</div>
				</section>
				<Notification
					isError={this.state.isError}
					message={this.state.message}
					showNotification={this.state.showNotification}
					MessageLocationLogin={"LoginToast"}
					closeNotification={this.handleClearNotification}
				/>
			</>
		);
	}

	handleShowNotification = (message, isError) => {
		this.setState({
			message: message,
			showNotification: true,
			isError: isError,
		});

		setTimeout(() => {
			this.handleClearNotification();
		}, 4000);
	};
	handleClearNotification = () => {
		this.setState({
			message: "",
			showNotification: false,
			isError: false,
		});
	};
}

const validateLoginForm = yup.object().shape({
	password: yup.string().required("Password is required"),
	newpassword: yup.string().required("Confirm Password is required"),
});

export default SetPassword;