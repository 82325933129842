import React from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles, makeStyles } from '@material-ui/core/styles';

const CssTextField = withStyles({
  root: {
    '& label': {
      color: '#646464',
      marginTop: 1,
      fontFamily: "Raleway, sans- serif",
      fontWeight: 'bold'
    },

    '& label.Mui-focused': {
      color: '#646464',
      fontFamily: "Raleway, sans- serif",
      fontWeight: 'bold'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '8px',
        borderColor: '#d8dbe0',
      },
      '&:hover fieldset': {
        borderWidth: '2px',
        borderColor: '#d8dbe0',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#d8dbe0',
      },
      '&.MuiOutlinedInput-input': {
        padding: '14px 14px',
        fontFamily: "Raleway, sans- serif"
      }
    },
  },
})(TextField);
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  TextFieldStyle: {
    width: '100%',
    fontSize: 20,
    fontFamily: "Raleway, sans- serif",
    fontWeight: 'bold'
  }

}));

const CustomInputComponent = props => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CssTextField inputProps={{ maxLength: props.maxLength, style: { color: '#646464', fontSize: 16 } }} fullWidth className={classes.TextFieldStyle} variant="outlined"  {...props} />
    </div>
  )
}

export default CustomInputComponent;