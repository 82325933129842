/*eslint-disable eqeqeq*/
import React from 'react'
import BudgetCampaignDuration from '../component/budgetDetail';
import * as AppConstants from "../../../config/AppConstant";
import * as APIController from '../../../api/APIController.js';
import CircularProgress from "../../../shared/components/CircularProgress/CircularProgress";

class BudgetDetailController extends React.Component {
    state = {
        budgetDetail: [{ budget: '', duration: '', }],
        MonthlyBudgetData: [],
        isLoading: false
    }
    //Temporary Solution Of Budget And duration selection
    // componentWillUnmount() {
    //     AppConstants.SetLocalStorage("localStorageBudgetDetail", JSON.stringify(this.state.budgetDetail))
    // }
    // shouldComponentUpdate() {
    //     localStorage.removeItem("localStorageBudgetDetail")
    // }

    componentDidMount() {
        //Temporary Solution Of Budget And duration selection
        if (AppConstants.GetLocalStorage("localStorageBudgetDetail") != undefined) {
            let data = JSON.parse(AppConstants.GetLocalStorage("localStorageBudgetDetail"));
            this.setState(this.state.budgetDetail.map((item, index) => item.duration = data[0].duration))
            this.setState(this.state.budgetDetail.map((item, index) => item.budget = data[0].budget))
        }
        this.wsGetMonthlyBudget(AppConstants.GetLocalStorage("localStorageThemesID"))
    }

    wsGetMonthlyBudget = (CampaignThemeID) => {
        this.setState({ isLoading: true })
        APIController.GetMonthlyBudget(CampaignThemeID)
            .then((response) => {
                this.setState({ MonthlyBudgetData: response.object.data, isLoading: false })
            })
    }

    handleBudgetDetailSelection = ({ duration, budget }) => {
        //Set Value Of budget
        let data = this.state.budgetDetail;
        data[0].budget = budget
        data[0].duration = duration

        this.setState({ budgetDetail: data })
        AppConstants.SetLocalStorage("localStorageBudgetDetail", JSON.stringify(data))
        this.props.history.push('/digital-product/selection/location-selection')
    }
    render() {
        return (
            <section className='content-right-main'>
                {this.state.isLoading ? <CircularProgress />
                    : <BudgetCampaignDuration budgetDetail={this.state.budgetDetail} MonthlyBudgetData={this.state.MonthlyBudgetData}
                        submitBudget={this.handleBudgetDetailSelection} back={() => this.props.history.goBack()} />}
            </section>
        )
    }
}

export default BudgetDetailController