/*eslint-disable eqeqeq*/
import React from "react";
import AssetDownloadReportList from "./assetDownloadReportList";
import * as APIController from "../../../../api/APIController.js";
import Notification from "../../../../shared/components/notification/notification";
import CsvDownload from 'react-json-to-csv'
import * as AppConstants from "../../../../config/AppConstant";
import moment from 'moment';

import PageTitle from "../../../../config/pageTitle";
import TextField from "../../../../shared/components/TextField/TextField";

class AssetDownloadReport extends React.Component {
    state = {
        currentPage: 1,
        count: 0,
        rowsPerPage: 1000000,
        rowsPerPageOptions: ["All", 50, 100, 250, 500, 1000],
        list: [],
        from: 0,
        to: 1000000,
        downloadlist: [],
        progress: true,
        downloadDate: moment(new Date().toUTCString()).utcOffset(0, false).format('MMDDYY'),
        message: "",
        showNotification: false,
        isError: false,
        showMessage:
            this.props.history.location.state != null &&
            this.props.history.location.state.showMessage,
        FromDate: "2022-05-16",
        ToDate: moment(new Date().toUTCString()).utcOffset(0, false).format('YYYY-MM-DD'),
    };

    componentDidMount() {
        this.wsAssetDownloadReport(this.state.from, this.state.to, "", "", "", "", "", "", "", "", "", "", "", "", "", "", this.state.FromDate, this.state.ToDate, this.state.currentPage, this.state.rowsPerPage);
    }

    wsAssetDownloadReport(from, to, DownloadedAt, FullName, PracticeName, VS, VSP, ShipToNo, CampaignName, BrandName, AssetCategory, AssetName, CSRFirstName, CSRLastName, VeevaCode, VTM_Territory, StartDate, EndDate, CurrentPage, RecordPerPage) {
        this.setState({ progress: true, });
        APIController.AssetDownloadReport("1", DownloadedAt, "", ShipToNo, CampaignName, BrandName, "", PracticeName,
            "", "", "", "", "", "", VS, VSP, "", "", "", "", VTM_Territory, "",
            VeevaCode, "", "", "", StartDate, EndDate,
            FullName, AssetCategory, AssetName, CSRFirstName, CSRLastName, CurrentPage, RecordPerPage).then((response) => {
                if (response.error == null && response.object.data.length != 0) {
                    let downloaddata = []
                    response.object.data.map((item) =>
                        AppConstants.GetMainClassName() == "app-main" ? downloaddata.push({
                            "Dowloaded at": item.DownloadedAt, "User": item.FullName, "Practice Name": item.PracticeName, "Campaign Name": item.CampaignName, "Brand Name": item.BrandName, "Ship To No": item.ShipToNo,
                            "Asset Category": item.AssetCategory, "Asset Name": item.AssetName, "VTM First Name": item.CSRFirstName,
                            "VTM Last Name": item.CSRLastName, "VTM #": item.VTM_Territory, "Veeva Code": item.VeevaCode, "VS": item.VS, "VSP": item.VSP,
                        }) : downloaddata.push({
                            "Dowloaded at": item.DownloadedAt, "Coontact Name": item.FullName, "Practice Name": item.PracticeName, "Netsuite ID": item.ShipToNo,
                            "Asset Category": item.AssetCategory, "Asset Name": item.AssetName, "CSR First Name": item.CSRFirstName,
                            "CSR Last Name": item.CSRLastName
                        })
                    )
                    this.setState({
                        downloadlist: downloaddata,
                        to: isNaN(to) ? 1000000 : to,
                        from: from,
                        count: response.object.data[0].MaxRows,
                        list: response.object.data,
                        progress: false,
                    });
                } else { this.setState({ list: [], progress: false }) }
            });
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    render() {
        return (
            <>
                <Notification
                    isError={this.state.isError}
                    message={this.state.message}
                    showNotification={this.state.showNotification}
                    clearNotification={this.handleClearNotification}
                    closeNotification={this.handleClearNotification}
                />
                <section className='content-right-main'>

                    <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
                        <h1><PageTitle Title={AppConstants.ContactDetais.AssetDownloadReport} /></h1>
                        <div style={{ display: 'flex', alignItems: 'baseline' }}>
                            <TextField style={{ margin: '10px' }} label='From Date' type="date" name="FromDate" value={this.state.FromDate} onChange={this.handleChange} />

                            <TextField label='To Date' type="date" name="ToDate" value={this.state.ToDate} onChange={this.handleChange} />

                            <div className='button-field button-field-country' style={{ height: '40px', minWidth: '100px', textAlign: 'center' }}>
                                <button onClick={() => { this.wsAssetDownloadReport(this.state.from, this.state.to, "", "", "", "", "", "", "", "", "", "", "", "", "", "", this.state.FromDate, this.state.ToDate, this.state.currentPage, this.state.rowsPerPage); }}> Search </button>
                            </div>

                            <div className='button-field' style={{ height: '40px', width: '240px', textAlign: 'center' }}>
                                <CsvDownload data={this.state.downloadlist} filename={AppConstants.GetMainClassName() == "app-main" ? "REACH Asset Download Report " + this.state.downloadDate + ".csv" : "Yomi Download Report.csv"}>Download to CSV</CsvDownload>
                            </div>
                        </div>
                    </div>

                    <AssetDownloadReportList
                        {...this.state}
                        history={this.props.history}
                        handleChangeRowsPerPage={this.handleRowsPerPageChange}
                        handleChangePage={this.handlePageChange}
                        handleApplyFilter={this.handleApplyFilter}
                        handleNotification={this.handleNotification}
                    />
                    {/*  */}
                </section>
            </>
        );
    }

    handleRowsPerPageChange = (value, DownloadedAt, FullName, PracticeName, VS, VSP, ShipToNo, CampaignName, BrandName, AssetCategory, AssetName, CSRFirstName, CSRLastName, VeevaCode, VTM_Territory) => {
        let from = 0
        let to = value;
        this.setState({ rowsPerPage: value, currentPage: 1, to: to, from: from })
        this.wsAssetDownloadReport(from, to, DownloadedAt, FullName, PracticeName, VS, VSP, ShipToNo, CampaignName, BrandName, AssetCategory, AssetName, CSRFirstName, CSRLastName, VeevaCode, VTM_Territory, this.state.StartDate, this.state.EndDate, isNaN(value) ? '' : this.state.currentPage, isNaN(value) ? 1000000 : value, BrandName);
    }

    handlePageChange = (values, DownloadedAt, FullName, PracticeName, VS, VSP, ShipToNo, CampaignName, BrandName, AssetCategory, AssetName, CSRFirstName, CSRLastName, VeevaCode, VTM_Territory) => {
        let from = values === 1 ? 0 * this.state.rowsPerPage : (values - 1) * this.state.rowsPerPage
        let to = values === 1 ? this.state.rowsPerPage : (from + this.state.rowsPerPage);
        this.setState({ currentPage: Number(values) })
        this.wsAssetDownloadReport(from, to, DownloadedAt, FullName, PracticeName, VS, VSP, ShipToNo, CampaignName, BrandName, AssetCategory, AssetName, CSRFirstName, CSRLastName, VeevaCode, VTM_Territory, this.state.StartDate, this.state.EndDate, Number(values), this.state.rowsPerPage, BrandName);
    }

    handleApplyFilter = (DownloadedAt, FullName, PracticeName, VS, VSP, ShipToNo, CampaignName, BrandName, AssetCategory, AssetName, CSRFirstName, CSRLastName, VeevaCode, VTM_Territory) => {
        this.wsAssetDownloadReport(this.state.from, this.state.to, DownloadedAt, FullName, PracticeName, VS, VSP, ShipToNo, CampaignName, BrandName, AssetCategory, AssetName, CSRFirstName, CSRLastName, VeevaCode, VTM_Territory, this.state.StartDate, this.state.EndDate, this.state.currentPage, this.state.rowsPerPage, BrandName);
    };

    handleNotification = (message, isError) => {
        this.setState({
            message: message,
            showNotification: true,
            isError: isError,
        });

        setTimeout(() => {
            this.handleClearNotification();
        }, 4000);
    };

    handleClearNotification = () => {
        this.setState({
            message: "",
            showNotification: false,
            isError: false,
        });
    };
}

export default AssetDownloadReport;
