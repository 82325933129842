/*eslint-disable eqeqeq*/
import React from "react";
import biotrue_bundle from "../../../assets/images/biotrue_bundle_calendar.jpg";
import { Grid } from "@material-ui/core";
import "./budgetDetail.css";
import Dropdown from '../../../shared/components/Dropdown/Dropdown';
import MenuItem from '@material-ui/core/MenuItem';
import PageTitle from "../../../config/pageTitle";
import * as AppConsatnt from "../../../config/AppConstant";

const BudgetCampaignDuration = (props) => {

	const [budget, setBudget] = React.useState('');
	const [duration, setDuration] = React.useState('');
	const [MonthlyBudgetData, setMonthlyBudgetData] = React.useState([]);

	React.useEffect(() => {
		setBudget(props.budgetDetail[0].budget)
		setDuration(props.budgetDetail[0].duration)
		setMonthlyBudgetData(props.MonthlyBudgetData)
	}, [props])

	return (
		<>
			<PageTitle Title={AppConsatnt.ContactDetais.Step2Select} />
			<form action='#'>
				<div className='two-col-sec'>
					<div className='sec-left'>
						<div className='full-field budget_detail_field'>
							{/* <label>Select Your Budget</label> */}
							<Dropdown
								style={{ color: '#555555' }}
								label='Select Monthly Budget'
								id={'budget'}
								name='budget'
								placeholder='Select Monthly Budget'
								defaultValue={budget}
								value={budget}
								onChange={(e) => setBudget(e.target.value)}
								select>
								{MonthlyBudgetData.map((obj) =>
									<MenuItem value={obj.MonthlyBudget.replace("$", "").replaceAll(",", "")}>{obj.MonthlyBudget}</MenuItem>
								)}
								{/* <MenuItem value='2000'>$2,000.00</MenuItem>
								<MenuItem value='3000'>$3,000.00</MenuItem>
								<MenuItem value='4000'>$4,000.00</MenuItem>
								<MenuItem value='5000'>$5,000.00</MenuItem>
								<MenuItem value='10000'>$10,000.00</MenuItem> */}
							</Dropdown>
						</div>
						<div className='full-field budget_detail_field' style={{ marginTop: '30px' }}>
							{/* <label>Select your Campaign Duration</label> */}
							<Dropdown
								style={{ color: '#555555' }}
								label='Select Monthly Duration'
								id={'duration'}
								name='duration'
								placeholder='Select Monthly Duration'
								defaultValue={duration}
								value={duration}
								onChange={(e) => setDuration(e.target.value)}
								select>
								<MenuItem value='2'>2 Months</MenuItem>
								<MenuItem value='3'>3 Months</MenuItem>
								<MenuItem value='4'>4 Months</MenuItem>
								<MenuItem value='5'>5 Months</MenuItem>
								<MenuItem value='6'>6 Months</MenuItem>
							</Dropdown>
							<p>
								<small>* A minimum of 2 months required (3 months recommended) to optimize the performance of the campaign. </small>
							</p>
						</div>
						{/* <div className='location-name' style={{ width: '100%', marginTop: '30px' }}>
							<div className='custom-checkbox'>
								<input
									type='checkbox'
									name='postcardBudget'
									id={"postcardBudget"}
									checked={postcardBudget}
									value={postcardBudget}
									onChange={(e) => {
										if (e.target.checked) {
											setpostcardBudget(true)
										} else {
											setpostcardBudget(false)
										}
									}}
								/>
								<div className='full-field budget_detail_field' style={{ marginTop: '30px' }}> 								 </div>
								<label style={{ fontWeight: 700, fontFamily: 'Raleway, sans-serif', fontSize: 16 }} htmlFor={"postcardBudget"}> Add Postcard Retargeting to My Campaign	</label>
							</div>
						</div>
						<p align="justify" style={{ lineHeight: 1.2, marginTop: '20px', fontWeight: 300 }}> Retargeting is $2.00 per postcard and will only be mailed if a physical address is matched to a local consumer that has clicked on one of your campaign ads. This postcard incentivizes consumers to schedule an appointment and promotes a free trial certificate.	Artwork for this postcard can be seen during the design approval process in Step 4. </p> */}
						{/* <h3> Retargeting is $2.00 per postcard and will only be mailed if a physical address is matched to a local consumer that has clicked on one of your campaign ads. This postcard incentivizes consumers to schedule an appointment and promotes a free trial certificate.	Artwork for this postcard can be seen during the design approval process in Step 4. </h3> */}

						{/* <label>Select Your Monthly Postcard Budget</label> */}
						{/* <div className='full-field budget_detail_field'>
							<Dropdown
								style={{ color: '#555555' }}
								label='Select Monthly Postcard Budget'
								id={'postcardBudgetSelect'}
								name='postcardBudgetSelect'
								placeholder='Select Monthly Postcard Budget'
								defaultValue={postcardBudgetSelect}
								value={postcardBudgetSelect}
								onChange={(e) => setpostcardBudgetSelect(e.target.value)}
								select>
								<MenuItem value='100'>$100.00</MenuItem>
								<MenuItem value='200'>$200.00</MenuItem>
								<MenuItem value='300'>$300.00</MenuItem>
								<MenuItem value='400'>$400.00</MenuItem>
								<MenuItem value='500'>$500.00</MenuItem>
								<MenuItem value='1000'>$1,000.00</MenuItem>
							</Dropdown>
							<p>
								<small> * Any remaining monthly budget will roll over to the following months until the campaign ends.	</small>
							</p>
						</div> */}

						{/* <p style={{ lineHeight: 1.2 }}>
							This enhanced package of integrated marketing services promotes your practice locally to help attract patients and includes:
							</p>
						 <h3>Package includes: </h3> 
						<ul style={{ margin: '20px' }} >
							<li style={{ margin: 0, padding: '5px 0px 0px 15px', lineHeight: 1.2, fontSize: 19 }} >Pay-Per-Click Advertising on Google</li>
							<li style={{ margin: 0, padding: '5px 0px 0px 15px', lineHeight: 1.2, fontSize: 19 }} >Web Display, Facebook & Instagram Advertising</li>
							<li style={{ margin: 0, padding: '5px 0px 0px 15px', lineHeight: 1.2, fontSize: 19 }} >Custom Landing Pages Featuring Your Practice Info</li>
							<li style={{ margin: 0, padding: '5px 0px 0px 15px', lineHeight: 1.2, fontSize: 19 }} >Automated Emails to Encourage the Scheduling of Eye Exams</li>
							<li style={{ margin: 0, padding: '5px 0px 0px 15px', lineHeight: 1.2, fontSize: 19 }} >Keyword Research, Ad Group Creation, & Bid Management</li>
						</ul> */}

					</div>
					<div className='sec-right'>
						<img src={biotrue_bundle} alt='sd-1' />
						{/* <div className='blue-link'>
								<a href='#!' title='View Sample Artwork'>
									View Sample Artwork
								</a>
							</div> */}
					</div>
				</div>
				<Grid container flexDirection='row' justify='space-between' style={{ marginTop: '15px' }} >
					<div className='button-field back-btn'>
						<input
							type='button'
							name='button'
							value={AppConsatnt.ButtonText.Back}
							onClick={props.back}
						/>
					</div>
					<div
						className={`button-field ${duration == '' || budget == '' ? "disable-btn" : ""}`}>
						<input
							type='button'
							name='submit'
							value={AppConsatnt.ButtonText.Continue}
							onClick={() => props.submitBudget({ duration, budget })}
						/>
					</div>
				</Grid>
			</form>
		</>
	);
};

export default BudgetCampaignDuration;
