/*eslint-disable eqeqeq*/
import React from "react";

import * as APIController from '../../../api/APIController.js';

import PageTitle from "../../../config/pageTitle";
import * as AppConstants from "../../../config/AppConstant";
import Notification from '../../../shared/components/notification/notification';
import CustomCalendar from './CustomCalendar'

import CircularProgress from "../../../shared/components/CircularProgress/CircularProgress";

import { PayPalButton } from "react-paypal-button-v2";

const CalendarMain = (props) => {

    const [CalendarAssetsData, setCalendarAssetsData] = React.useState([]);
    const [AssetsDataFinal, setAssetsDataFinal] = React.useState([]);
    const [AssetsDataTemp, setAssetsDataTemp] = React.useState([]);// add by nilesh
    const [CalendarForPractice, setCalendarForPractice] = React.useState([]);
    const [CalendarBrands, setCalendarBrands] = React.useState([]);
    const [BrandIDs, setBrandIDs] = React.useState('');
    const [AutomationCalendarID, setAutomationCalendarID] = React.useState("");
    const [RefreshedCalenderData, setRefreshedCalenderData] = React.useState("");//add by nilesh
    const [DummyArray, setDummyArray] = React.useState("");//add by nilesh

    const [CalendarYear, setCalendarYear] = React.useState("");
    const [CalendarMonth, setCalendarMonth] = React.useState("");

    const [FBPageData, setFBPageData] = React.useState("");
    const [InstaPageData, setInstaPageData] = React.useState("");
    const [InstaUserData, setInstaUserData] = React.useState("");

    const [PMonthData, setPMonthData] = React.useState([]);
    const [AutomationSubscriptionID, setAutomationSubscriptionID] = React.useState(AppConstants.GetLocalStorage("localStorageAutomationSubscriptionID"));
    const [isLoading, setisLoading] = React.useState(false);
    const [PracticeID, setPracticeID] = React.useState(AppConstants.GetLocalStorage("localStoragepracticeId"));
    const [PracticeDetails, setPracticeDetails] = React.useState("");
    const [OrderByName, setOrderByName] = React.useState("");
    const [AddCartBtn, setAddCartBtn] = React.useState(false);
    const [calenderViewFromCart, setCalenderViewFromCart] = React.useState(AppConstants.GetLocalStorage("localStorageCalenderCart"));

    React.useEffect(() => {
        if (AppConstants.GetLocalStorage('localStorageFBPageData') != ""
            && AppConstants.GetLocalStorage('localStorageFBPageData') != undefined
            && AppConstants.GetLocalStorage('localStorageFBPageData') != null) {
            setFBPageData(JSON.parse(AppConstants.GetLocalStorage('localStorageFBPageData')))
        }
        if (AppConstants.GetLocalStorage('localStorageInstaPageData') != ""
            && AppConstants.GetLocalStorage('localStorageInstaPageData') != undefined
            && AppConstants.GetLocalStorage('localStorageInstaPageData') != null) {
            setInstaPageData(AppConstants.GetLocalStorage('localStorageInstaPageData'))
        }
        if (AppConstants.GetLocalStorage('localStorageInstaUserData') != ""
            && AppConstants.GetLocalStorage('localStorageInstaUserData') != undefined
            && AppConstants.GetLocalStorage('localStorageInstaUserData') != null) {
            setInstaUserData(JSON.parse(AppConstants.GetLocalStorage('localStorageInstaUserData')))
        }

        if (props.history.location.state.calendarIdEdit != ""
            && props.history.location.state.calendarIdEdit != undefined
            && props.history.location.state.calendarIdEdit != null) {
            setAutomationCalendarID(props.history.location.state.calendarIdEdit)
            wsGetAutomationCalendarAssets(props.history.location.state.calendarIdEdit, props.history.location.state.calendarYearEdit, props.history.location.state.calendarMonthEdit)
        } else {
            setCalendarBrands(AppConstants.GetLocalStorage('localStorageBrandsData').length && JSON.parse(AppConstants.GetLocalStorage('localStorageBrandsData')))
            wsGetAutomationCalendarForPractice(AppConstants.GetLocalStorage('localStorageBrandsData').length && JSON.parse(AppConstants.GetLocalStorage('localStorageBrandsData')), PracticeID, AutomationSubscriptionID)
        }
        let Data = []
        AppConstants.GetLocalStorage('localStorageBrandsData').length && JSON.parse(AppConstants.GetLocalStorage('localStorageBrandsData'))
            .map((item1) => {
                item1.Checked && Data.push(item1.ID)
            })
        setBrandIDs(Data)
    }, [props])

    const handlebind = (AutomationCalendarID, calendarYearEdit, calendarMonthEdit) => {
        wsGetAutomationCalendarAssets(AutomationCalendarID, calendarYearEdit, calendarMonthEdit);
    }
    const handleRefresh = (Refreshdata) => { // Add by Nilesh patel
        let TempRefreshData = [];
        let TempUnrefresh = [];
        let FinalTemp = [];
        RefreshedCalenderData.map((item, index) => {
            if (item.CalendarID == AutomationCalendarID) {
                TempRefreshData.push({
                    CalendarAssets: JSON.stringify(Refreshdata),
                    CalendarID: item.CalendarID,
                    CalendarMonth: item.CalendarMonth,
                    CalendarYear: item.CalendarYear,
                    Cost: item.Cost,
                })
            }
            else if (item.CalendarID != AutomationCalendarID) {
                TempUnrefresh.push(item)
            }
        })

        FinalTemp = TempRefreshData.concat(TempUnrefresh)
        FinalTemp.length && setCalendarForPractice(FinalTemp)
        // handleApi(FinalTemp, CalendarBrands)
    }

    const handleApi = (handleApi, BrandsData) => {
        // console.log('handleApi:', handleApi)
        // let arrayData = []
        // let Data = []

        // handleApi.map((item) => {
        //     BrandsData.length && BrandsData.map((item1) => {
        //         item1.Checked &&
        //             JSON.parse(item.CalendarAssets).map((itm) => {
        //                 item1.ID == itm.BrandID && Data.push(itm);
        //             })
        //     })
        // })
        // Data.map((item) => arrayData.push({ "ID": item.ID, "Status": item.Status }))
        // console.log('arrayData final :', arrayData)
        // setAssetsDataTemp(arrayData)

    }
    const handleAssetsData = (AssetsData) => {
        let arrayData = []
        AssetsData.map((item) => arrayData.push({ "ID": item.ID, "Status": item.Status }))

        DummyArray.forEach(function (element) {
            AssetsData.forEach(function (innerElement) {
                if (element.ID == innerElement.ID) {
                    element.Status = innerElement.Status
                }
            })
        });

        setAssetsDataFinal(DummyArray)
        setCalendarAssetsData(AssetsData)

    }

    const handleAssetsParMonthData = (currentMonth, currentYear) => {
        setRefreshedCalenderData(CalendarForPractice)
        let Data = []
        CalendarForPractice.map((item) => {
            CalendarBrands.length && CalendarBrands.map((item1) => {
                item1.Checked &&
                    JSON.parse(item.CalendarAssets).map((itm) => {
                        item1.ID == itm.BrandID && currentMonth == item.CalendarMonth && Data.push(itm);
                    })
            })
            setCalendarMonth(currentMonth)
            setCalendarYear(currentYear)
            setCalendarAssetsData(Data)
            currentMonth == item.CalendarMonth && setAutomationCalendarID(item.CalendarID)
        })
        let arrayData = []
        Data.map((item) => arrayData.push({ "ID": item.ID, "Status": item.Status }))
        // setAssetsDataFinal(arrayData)
        //props.handleAssetsData(arrayData)
        handleApi(CalendarForPractice, CalendarBrands)
        // setPayPalButtonShow(false)
    }

    const wsGetAutomationCalendarAssets = (AutomationCalendarID, calendarYearEdit, calendarMonthEdit) => {
        setisLoading(true)
        APIController.GetAutomationCalendarAssets(AutomationCalendarID)
            .then((response) => {
                if (response.error == null) {
                    setCalendarAssetsData(response.object.data)
                    setCalendarYear(calendarYearEdit)
                    setCalendarMonth(calendarMonthEdit)
                }
                else {
                    handleNotification(response.object.message, true);
                }
                setisLoading(false)
            })
    }

    const wsGetAutomationCalendarForPractice = (BrandsData, PracticeID, ID) => {
        setisLoading(true)
        let arrayData = []
        let DATA = []
        let monthData = []
        APIController.GetAutomationCalendarForPractice(PracticeID, ID)
            .then((response) => {
                if (response.object.data.length) {
                    setAutomationCalendarID(response.object.data[0].CalendarID)
                    setCalendarYear(response.object.data[0].CalendarYear)
                    setCalendarMonth(response.object.data[0].CalendarMonth)


                    BrandsData.length && BrandsData.map((item1) => {
                        item1.Checked && response.object.data.map((item) => {
                            JSON.parse(item.CalendarAssets).map((itm) => {
                                item1.ID == itm.BrandID && arrayData.push({ "ID": itm.ID, "Status": itm.Status });
                                item1.ID == itm.BrandID && DATA.push(itm)

                            })
                        })
                        // setAssetsDataFinal(arrayData)
                        setCalendarAssetsData(DATA)
                        //setDummyArray(arrayData)

                    })


                    let DummyDataInner = []
                    BrandsData.length && BrandsData.forEach(function (element) {
                        response.object.data.forEach(function (innerElement) {
                            JSON.parse(innerElement.CalendarAssets).forEach(function (item) {
                                if (element.ID == item.BrandID) {
                                    DummyDataInner.push({ "ID": item.ID, "Status": element.Checked ? item.Status : 3 })
                                }
                            })

                        })
                    });
                    setDummyArray(DummyDataInner)
                    setAssetsDataFinal(DummyDataInner)



                    if (response.object.data.length > 1) {
                        response.object.data.map((item, index) => {
                            monthData.push({ MonthData: item.CalendarMonth })
                        })

                        setPMonthData(monthData)
                        setCalendarForPractice(response.object.data)
                    }
                    setRefreshedCalenderData(response.object.data)//add by nilesh
                    handleApi(response.object.data, BrandsData)
                }
                setisLoading(false)
            })
    }

    const wsAddEditAutomationSubscription = (ID, AutomationCalendarID, CampaignID, PracticeID, FacebookUserID, FacebookID, Facebookusername, FacebookAccess_Token,
        InstagramID, Instagramusername, InstagramAccess_Token, BrandIDs, Assets) => {
        setAddCartBtn(true)
        APIController.AddEditAutomationSubscription(ID, AutomationCalendarID, CampaignID, PracticeID, FacebookUserID, FacebookID, Facebookusername, FacebookAccess_Token,
            InstagramID, Instagramusername, InstagramAccess_Token, BrandIDs, Assets)
            .then((response) => {
                if (response.object.status == "1") {
                    handleNotification(response.object.message);
                    AppConstants.SetLocalStorage("localStorageAutomationSubscriptionID", response.object.data[0].ID)
                    AppConstants.SetLocalStorage("localStorageSubscriptionPracticeID", PracticeID)
                    setTimeout(() => {
                        ID != 0 ? props.history.push('/subscriptions') : props.history.push('/cart')
                        setAddCartBtn(false)
                    }, 4000)
                    // setAutomationSubscriptionID(response.object.data[0].ID)
                    // wsGetPracticeByID(PracticeID)
                } else {
                    handleNotification(response.object.message, true);
                    // setPayPalButtonShow(false)
                    setAddCartBtn(false)
                }
            })
    }

    const wsGetPracticeByID = (ID) => {
        let Data = []
        APIController.GetPracticeByID(ID)
            .then((response) => {
                if (response.error == null) {

                    Data = {
                        "name": {
                            "given_name": response.object.data[0].FirstName,
                            "surname": response.object.data[0].LastName
                        },
                        "email_address": response.object.data[0].EmailAddress,
                        "shipping_address": {
                            "name": {
                                "full_name": response.object.data[0].FirstName + " " + response.object.data[0].LastName
                            },
                            "address": {
                                "address_line_1": response.object.data[0].Address1,
                                "address_line_2": response.object.data[0].Address2,
                                "admin_area_2": response.object.data[0].City,
                                "admin_area_1": response.object.data[0].State,
                                "postal_code": response.object.data[0].Postcode,
                                "country_code": "US"
                            }
                        }
                    }
                    setOrderByName(response.object.data[0].FirstName + " " + response.object.data[0].LastName)
                    setPracticeDetails(Data)
                    // setPayPalButtonShow(true)
                }
            })
    }

    const [message, setMessage] = React.useState("");
    const [showNotification, setShowNotification] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const handleNotification = (message, isError) => {
        setMessage(message)
        setShowNotification(true)
        setIsError(isError)

        setTimeout(() => {
            handleClearNotification();
        }, 4000)
    }

    const handleClearNotification = () => {
        setMessage('')
        setShowNotification(false)
        setIsError(false)
    }

    const paypalSubscribe = (data, actions) => {
        return actions.subscription.create({
            "plan_id": "P-9JP711594E0846536MIBGGVY",
            "custom_id": AutomationSubscriptionID,
            "subscriber": PracticeDetails
        });
    };

    const paypalOnError = (err) => {
        // console.log("Error", err)
    }
    const paypalOnApprove = (data, detail) => {
        // call the backend api to store transaction details
        props.history.push({
            pathname: '/paymentSuccess', state: { AutomationSubscriptionID: AutomationSubscriptionID, OrderByName: OrderByName }
        })
    };

    return (
        <section className='content-right-main'>
            <PageTitle Title={AppConstants.ContactDetais.CalendarHeading} />
            <div className="two-col-sec digital-welcome">
                <div style={{ width: '100%', height: "100%" }}>

                    {isLoading ? <CircularProgress></CircularProgress>
                        : <CustomCalendar
                            CalendarAssetsData={CalendarAssetsData}
                            CalendarBrands={CalendarBrands}
                            CalendarYear={CalendarYear}
                            CalendarMonth={CalendarMonth}
                            PMonthData={PMonthData}
                            AutomationCalendarID={AutomationCalendarID}
                            handlebind={handlebind.bind(this)}
                            handleRefresh={handleRefresh.bind(this)}
                            handleAssetsData={handleAssetsData.bind(this)}
                            handleAssetsParMonthData={handleAssetsParMonthData.bind(this)}
                            history={props.history} />
                    }
                </div>
                {/* // <>{PayPalButtonShow ? <div className='paypal-button-container'>
                    //     <PayPalButton
                    //         amount="0.01"
                    //         options={{
                    //             vault: true
                    //         }}
                    //         currency="USD"
                    //         createSubscription={paypalSubscribe}
                    //         onApprove={paypalOnApprove}
                    //         catchError={paypalOnError}
                    //         onError={paypalOnError}
                    //         onCancel={paypalOnError}
                    //         style={{ shape: 'rect', color: 'blue', layout: 'horizontal', label: 'subscribe', Size: 'medium', height: 43, tagline: false }}
                    //     />
                    // </div> :

                    // }</> */}

                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    {calenderViewFromCart == '1' || calenderViewFromCart == "" ?
                        <div className='form-field button-field' style={{ marginBottom: '20px' }}>
                            <input type='button' name='button' value={AppConstants.ButtonText.Back} onClick={() => props.history.goBack()} />
                        </div>
                        : <div className='form-field button-field' style={{ marginBottom: '20px' }}>
                            <input type='button' name='button' value={AppConstants.ButtonText.BackToCart} onClick={() => props.history.goBack()} />
                        </div>}
                    {localStorage.getItem("headerMenuAdmin") != 1 && calenderViewFromCart == '1' &&
                        <div className={`form-field button-field ${AddCartBtn ? "disable-btn" : ""}`} style={{ marginBottom: '20px' }}>
                            <input type="button" name="edit-profile"
                                value={
                                    AutomationSubscriptionID == 0 && AddCartBtn ?
                                        AppConstants.ButtonText.AddingtoCart :
                                        AutomationSubscriptionID != 0 && AddCartBtn ?
                                            AppConstants.ButtonText.UpdatingSubscription :
                                            AutomationSubscriptionID != 0 ?
                                                AppConstants.ButtonText.UpdateSubscription :
                                                AppConstants.ButtonText.AddToCart
                                }
                                onClick={() => {
                                    wsAddEditAutomationSubscription(AutomationSubscriptionID == "" ? "0" : AutomationSubscriptionID, AutomationCalendarID,
                                        AppConstants.GetLocalStorage('localStorageCampaignID'), PracticeID, AppConstants.GetLocalStorage('localStorageFacebookUserID'),
                                        FBPageData.id, FBPageData.name, FBPageData.access_token,
                                        InstaUserData.id, InstaUserData.username, InstaPageData, BrandIDs.toString(), AssetsDataFinal)
                                }}
                            />
                        </div>}
                </div>
            </div>
            <Notification
                isError={isError}
                message={message}
                showNotification={showNotification}
                clearNotification={handleClearNotification.bind(this)}
                closeNotification={handleClearNotification.bind(this)}
            />
        </section>
    );
};

export default CalendarMain;
