/*eslint-disable eqeqeq*/
import React from "react";
import SubscriptionsList from "./subscriptionsList";

// import { data } from './countrydata'
import * as APIController from "../../api/APIController.js";
import pageTitle from "../../config/pageTitle";
import Notification from "../../shared/components/notification/notification";
import * as AppConstants from "../../config/AppConstant";

class Subscriptions extends React.Component {
    state = {
        currentPage: 0,
        count: 0,
        rowsPerPage: 100,
        rowsPerPageOptions: [500, 100, 50, 25],
        list: [],
        list1: [],
        from: 0,
        to: 100,
        progress: true,
        message: "",
        showNotification: false,
        isError: false,
        showMessage:
            this.props.history.location.state != null &&
            this.props.history.location.state.showMessage,
        showOrderList: false,
    };

    componentDidMount() {
        // this.wsGetCampaignProducts(AppConstants.GetLocalStorage("localStorageCampaignID"))
        this.wsGetAutomationSubscriptions();
        this.state.showMessage != "" &&
            this.handleNotification(this.state.showMessage);
        this.props.history.replace("/subscriptions", null);
    }

    handlebind() {
        this.wsGetAutomationSubscriptions();
    }

    wsGetAutomationSubscriptions() {
        this.setState({ progress: true, });
        APIController.GetAutomationSubscriptions().then((response) => {
            if (response.error == null) {
                this.setState({
                    list: response.object.data,
                    list1: response.object.data,
                    count: response.object.data.length,
                    progress: false,
                });
            } else if (response.error.message && response.error.message.length > 0) {
                this.handleNotification(response.error.message, true);
            }
        });
    }

    wsGetCampaignProducts(ID, ProductID, FacebookID, FacebookUsername, InstagramID, InstagramUsername, FacebookUserID) {
        APIController.GetCampaignProducts(ID)
            .then((response) => {
                if (response.object.status == '1') {
                    // setdataSource(response.object.data)
                    response.object.data.map((obj, index) => {
                        if (obj.ID == ProductID) {
                            if (FacebookUserID != 'null' && FacebookUserID != null && FacebookUserID != '' && FacebookUserID != undefined) {
                                if (FacebookID != null && FacebookID != '' && FacebookID != undefined) {
                                    AppConstants.SetLocalStorage("localStorageFBPageData", JSON.stringify({ 'id': FacebookID, 'name': FacebookUsername }));
                                } else { AppConstants.SetLocalStorage("localStorageFBPageData", ''); }

                                if (InstagramID != null && InstagramID != '' && InstagramID != undefined) {
                                    AppConstants.SetLocalStorage("localStorageInstaUserData", JSON.stringify({ 'id': InstagramID, 'username': InstagramUsername }));
                                } else { AppConstants.SetLocalStorage("localStorageInstaUserData", ''); }
                            } else { AppConstants.SetLocalStorage("localStorageFBPageData", ''); AppConstants.SetLocalStorage("localStorageInstaUserData", ''); }

                            AppConstants.SetLocalStorage("localStorageProductID", obj.Children != null && obj.Children.length ? JSON.parse(obj.Children)[0].ID : obj.ID)
                            AppConstants.SetLocalStorage("localStorageMultiSelectPractice", "0")
                            AppConstants.SetLocalStorage("localStorageProductName", obj.Name)
                            AppConstants.SetLocalStorage("localStorageISDirectMail", obj.Name == "Direct Mail" ? 1 : 0)
                            AppConstants.SetLocalStorage("localStorageMenuPath", obj.MenuPath)
                            AppConstants.SetLocalStorage("localStorageShowAddToCart", obj.ShowAddToCart)
                            AppConstants.SetLocalStorage("localStorageShowCopyText", obj.ShowCopyText)
                            AppConstants.SetLocalStorage("localStorageThemeButtons", obj.ThemeButtons)
                            AppConstants.SetLocalStorage("localStorageFromCart", 1)
                            AppConstants.SetLocalStorage('localStorageOrderDetailsID', 0)
                            AppConstants.SetLocalStorage("localStorageBudgetDetail", obj.PageURL == "/digital-product/selection/location-selection" ? JSON.stringify([{ "budget": 1, "duration": 1 }]) : JSON.stringify([{ "budget": 1, "duration": "" }]))
                            AppConstants.SetLocalStorage("localStorageAutomation", "NO")
                            AppConstants.SetLocalStorage("localStorageChildren", obj.Children != null && obj.Children)
                        }
                    })
                    this.props.history.push('/digital-product/selection/social-automation');
                }
            })
    }

    render() {
        return (
            <>
                <Notification
                    isError={this.state.isError}
                    message={this.state.message}
                    showNotification={this.state.showNotification}
                    clearNotification={this.handleClearNotification}
                    closeNotification={this.handleClearNotification}
                />
                <section className='content-right-main'>

                    <SubscriptionsList
                        {...this.state}
                        history={this.props.history}
                        handlebind={this.handlebind.bind(this)}
                        handleChangeRowsPerPage={this.handleRowsPerPageChange}
                        handleChangePage={this.handlePageChange}
                        applyFilter={this.handleApplyFilter}
                        handleNotification={this.handleNotification}
                        handleShowList={this.handleShowList}
                        wsGetCampaignProducts={this.wsGetCampaignProducts.bind(this)}
                    />

                </section>
            </>
        );
    }

    handleShowList = (value) => {
        this.setState({ showOrderList: value })
    }

    /**
     * handleRowsPerPageChange method is used to update rows per page
     * it will reset page to 1
     */

    handleRowsPerPageChange = (value) => {
        let from = 0;
        let to = value;
        this.setState({ rowsPerPage: value, currentPage: 1, to: to, from: from });
    };

    /**
     * handlePageChange method is used to change page
     */

    handlePageChange = (values) => {
        let from =
            values === 1
                ? 0 * this.state.rowsPerPage
                : (values - 1) * this.state.rowsPerPage;
        let to =
            values == 1
                ? this.state.rowsPerPage
                : parseInt(from) + parseInt(this.state.rowsPerPage);
        this.setState({ currentPage: Number(values), to: to, from: from });
    };

    /**
     * handleApplyFilter method is used to apply filter on every columns
     */
    handleApplyFilter = (tag, values, type) => {
        if (values !== "") {
            let searchCountrys = [];
            if (type === "select") {
                searchCountrys = this.state.list1.filter(
                    (us) => us[tag].toLowerCase() === values.toLowerCase()
                );
            } else {
                searchCountrys = this.state.list.filter(
                    (us) => us[tag].toLowerCase().indexOf(values.toLowerCase()) > -1
                );
            }
            this.setState({
                list: searchCountrys,
                currentPage: 0,
                count: searchCountrys.length,
                rowsPerPage: 100,
                rowsPerPageOptions: [500, 100, 50, 25],
                from: 0,
                to: 100,
                progress: false,
            });
        } else {
            this.wsGetCountries();
            this.setState({
                currentPage: 0,
                rowsPerPage: 100,
                rowsPerPageOptions: [500, 100, 50, 25],
                from: 0,
                to: 100,
            });
        }
    };

    handleNotification = (message, isError) => {
        this.setState({
            message: message,
            showNotification: true,
            isError: isError,
        });

        setTimeout(() => {
            this.handleClearNotification();
        }, 4000);
    };

    handleClearNotification = () => {
        this.setState({
            message: "",
            showNotification: false,
            isError: false,
        });
    };
}

export default Subscriptions;
