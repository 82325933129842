/*eslint-disable eqeqeq*/
import React from 'react'
import LocationSelectionComponent from '../component/location-selection';
import * as APIController from "../../../api/APIController.js";
import Notification from '../../../shared/components/notification/notification';
import CircularProgress from "../../../shared/components/CircularProgress/CircularProgress";
import { Grid } from '@material-ui/core';

import * as AppConstants from "../../../config/AppConstant";

class LocationSelectionController extends React.Component {
    state = {
        // categories: category,
        categories: { locations: [] },
        selectedAdd: [],
        data: [],
        progress: true,
        practiceId: '',
        message: '',
        showNotification: false,
        isError: false,
        CampaignBusinessDetails: [],
        CampaignBusinessHours: [],
        OrderDetailsID: AppConstants.GetLocalStorage('localStorageOrderDetailsID'),
        DefaultOpenPanel: false,
        IsLoading: true,
        IsDatafound: true,
        PracticeIDs: [],
    }

    componentDidMount() {
        let PracticeIDs = [];
        PracticeIDs.push(AppConstants.GetLocalStorage("localStoragepracticeId"))
        this.setState({ PracticeIDs: PracticeIDs })

        this.wsGetLocationDetails();
    }

    handleNotification = (message, isError) => {
        this.setState({
            message: message,
            showNotification: true,
            isError: isError,
        });

        setTimeout(() => {
            this.handleClearNotification();
        }, 4000);
    };

    handleClearNotification = () => {
        this.setState({
            message: "",
            showNotification: false,
            isError: false,
        });
    };

    wsGetAllLocations() {
        APIController.GetAllLocations().then((response) => {
            if (response.error == null) {
                this.setState({
                    data: response.object.data,
                });
            } else if (response.error.message && response.error.message.length > 0) {
                // this.handleNotification(response.error.message, true);
            }
        });
    }

    wsGetLocationDetails() {
        this.setState({ progress: true, });
        APIController.GetLocationDetails().then((response) => {
            if (response.error == null && response.object.data.length != 0) {
                this.setState({
                    categories: response.object.data,
                    practiceId: response.object.data.id,
                    progress: false,
                });
                //this code for Default Selection when Only One Location Found
                if (response.object.data.locations.length == 1) {
                    this.setState({ DefaultOpenPanel: true, selectedAdd: [] })
                    this.handleSelectAddress(response.object.data.locations[0])
                }
                //this code for Default Selection when Edit Locations
                if (AppConstants.GetLocalStorage('localStoragepracticeId') != '') {
                    let Id = AppConstants.GetLocalStorage('localStoragepracticeId')
                    response.object.data.locations.map((obj, index) => {
                        if (obj.id == Id) {
                            this.handleSelectAddress(response.object.data.locations[index])
                        }
                    })
                    this.setState({ OrderDetailsID: AppConstants.GetLocalStorage('localStorageOrderDetailsID') })
                }
                this.setState({ IsLoading: false })
            } else if (response.object.message && response.object.message.length > 0) {
                this.handleNotification(response.object.message, true);
                this.setState({ IsLoading: false, IsDatafound: false })
            }
        });
    }

    wsUpdateLocation(PracticeID, BusinessName, Address, Address2, StateID, CityID, PostcodeID, BusinessPhoneNo,
        MobileNo, BusinessEmailAddress, MarketingEmailAddress, BusinessWebsite, Appointmenturl, FBLink, InstagramLink, SearchPostcode, SearchRadius, SearchSelection,
        EventDate, EventTime, RSVPDeadline, DoctorName, BusinessHours) {
        this.setState({ progress: true, });
        APIController.UpdateLocation(PracticeID, BusinessName, Address, Address2, StateID, CityID,
            PostcodeID, BusinessPhoneNo,
            MobileNo, BusinessEmailAddress, MarketingEmailAddress, BusinessWebsite, Appointmenturl, FBLink, InstagramLink,
            SearchPostcode, SearchRadius, SearchSelection, EventDate, EventTime, RSVPDeadline, DoctorName, BusinessHours)
            .then((response) => {
                if (response.object.status == 1) {
                    this.handleNotification(response.object.message);
                } else {
                    this.handleNotification(response.object.message, true);
                }
                this.wsGetLocationDetails()
            });
    }

    /**
     * handleSelectAddress method is used to push selected address key and index in selectedAddress
     */
    handleSelectAddress = (values) => {
        const selectedAddress = this.state.selectedAdd;
        selectedAddress.push(values);
        if (this.state.selectedAdd[0].id !== values.id) {
            this.setState({ selectedAdd: selectedAddress })
        }
        this.SetApiData(this.state.selectedAdd)
    }

    SetApiData(data) {
        let CampaignBusinessDetails = [];
        let CampaignReach = [];
        let CampaignBusinessHours = [];
        let PracticeIDs = [];
        for (var i = 0; i < data.length; i++) {
            PracticeIDs.push(data[i].id)
            CampaignReach.push(
                {
                    "PracticeID": data[i].id,
                    "Location": data[i].location,
                    "WithinMileRange": data[i].locationDetail.campaignReach.radius,
                    "Postcode": data[i].locationDetail.campaignReach.zipCode
                }
            )
        }
        this.setState({ PracticeIDs: PracticeIDs, CampaignBusinessDetails: CampaignBusinessDetails, CampaignReach: CampaignReach, CampaignBusinessHours: CampaignBusinessHours })
    }

    /**
     * handleDeSelectAddress method is used to remove selected key and index from state in selected address
     */
    handleDeSelectAddress = (values) => {
        const selectedAddress = this.state.selectedAdd;
        const index = selectedAddress.findIndex(ob => ob.id === values.id);
        selectedAddress.splice(index, 1);
        this.setState({
            selectedAdd: selectedAddress
        })
        this.SetApiData(this.state.selectedAdd)
    }
    SetPracticeId(id) {
        this.setState({ practiceId: id })
    }
    handleUpdateLocationDetail = (obj, key) => {
        let BusinessHours = [
            {
                "IsMondayClosed": obj.hoursOfOperation.Mon.closed,
                "IsTuesdayClosed": obj.hoursOfOperation.Tue.closed,
                "IsWednesdayClosed": obj.hoursOfOperation.Wed.closed,
                "IsThursdayClosed": obj.hoursOfOperation.Thu.closed,
                "IsFridayClosed": obj.hoursOfOperation.Fri.closed,
                "IsSaturdayClosed": obj.hoursOfOperation.Sat.closed,
                "IsSundayClosed": obj.hoursOfOperation.Sun.closed,
                "MondayStart": obj.hoursOfOperation.Mon.open,
                "MondayEnd": obj.hoursOfOperation.Mon.close,
                "TuesdayStart": obj.hoursOfOperation.Tue.open,
                "TuesdayEnd": obj.hoursOfOperation.Tue.close,
                "WednesdayStart": obj.hoursOfOperation.Wed.open,
                "WednesdayEnd": obj.hoursOfOperation.Wed.close,
                "ThursdayStart": obj.hoursOfOperation.Thu.open,
                "ThursdayEnd": obj.hoursOfOperation.Thu.close,
                "FridayStart": obj.hoursOfOperation.Fri.open,
                "FridayEnd": obj.hoursOfOperation.Fri.close,
                "SaturdayStart": obj.hoursOfOperation.Sat.open,
                "SaturdayEnd": obj.hoursOfOperation.Sat.close,
                "SundayStart": obj.hoursOfOperation.Sun.open,
                "SundayEnd": obj.hoursOfOperation.Sun.close
            }]

        this.wsUpdateLocation(
            this.state.practiceId,
            obj.businessInfo.name,
            obj.businessInfo.address1,
            obj.businessInfo.address2,
            "39cb45c4-b7f8-4bbc-9d3f-fe4d09a11a9c",
            "3a94bf2e-12fd-4d85-b149-f126db25c757",
            obj.businessInfo.postCode,
            obj.businessInfo.businessPhone,
            obj.businessInfo.phone,
            obj.businessInfo.email,
            obj.businessInfo.email2,
            obj.businessInfo.website,
            obj.businessInfo.appointmenturl,
            obj.socialMedia.facebookUrl,
            obj.socialMedia.instagramUrl,
            obj.campaignReach.zipCode,
            obj.campaignReach.radius,
            JSON.stringify(obj.campaignReach.selectedLocations),
            obj.businessInfo.eventdate,
            obj.businessInfo.eventtime,
            obj.businessInfo.rsvpdeadline,
            obj.businessInfo.doctorname,
            BusinessHours
        )
    }

    render() {
        if (this.state.IsLoading) {
            return <div style={{ marginTop: '10%' }}> <CircularProgress /> </div>
        }
        else if (!this.state.IsDatafound) {
            return <Grid container direction='row' style={{ justifyContent: 'center', alignItems: 'center', marginTop: '10%' }}>
                <h3>Not Currently Available</h3>
            </Grid>
        }
        return (
            <>
                <LocationSelectionComponent
                    progress={this.state.progress}
                    category={this.state.categories}
                    practiceId={this.state.practiceId}
                    onSelectAddress={this.handleSelectAddress}
                    selectAddress={this.state.selectedAdd}
                    DefaultOpenPanel={this.state.DefaultOpenPanel}
                    onDeSelectAddress={this.handleDeSelectAddress}
                    SetPracticeId={this.SetPracticeId.bind(this)}
                    budgetDetail={JSON.parse(AppConstants.GetLocalStorage('localStorageBudgetDetail'))}
                    fromCart={AppConstants.GetLocalStorage('localStorageFromCart')}
                    isSocialAutomation={AppConstants.GetLocalStorage('localStorageAutomation')}
                    ShowAddToCart={AppConstants.GetLocalStorage('localStorageShowAddToCart')}
                    updateLocationDetails={this.handleUpdateLocationDetail.bind(this)}
                    // pathname: "/digital-product/selection/campaign",
                    nextForm={() => {
                        AppConstants.SetLocalStorage('localStoragepracticeId', this.state.PracticeIDs)
                        if (AppConstants.GetLocalStorage('localStorageAutomation') == "YES") {
                            AppConstants.SetLocalStorage("localStorageCalenderCart", "1")

                            this.props.history.push({ pathname: "/calendar", state: { calendarIdEdit: "" } })
                        } else {
                            AppConstants.SetLocalStorage('localStorageCampaignReach', JSON.stringify(this.state.CampaignReach))
                            AppConstants.SetLocalStorage('localStorageOrderDetailsID', this.state.OrderDetailsID)
                            AppConstants.SetLocalStorage('localStoragePracticeIDs', JSON.stringify(this.state.PracticeIDs))
                            AppConstants.SetLocalStorage('localStorageFromCart', 1)
                            this.props.history.push("/digital-product/selection/adbreak")
                        }
                    }}
                    back={() => this.props.history.goBack()}
                />
                <Notification
                    isError={this.state.isError}
                    message={this.state.message}
                    showNotification={this.state.showNotification}
                    closeNotification={this.handleClearNotification}
                />
            </>
        )

    }
}

export default LocationSelectionController