/*eslint-disable eqeqeq*/
import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik'
import * as APIController from '../../../../api/APIController.js';
import * as yup from 'yup';

import uuid from 'react-uuid';
import file_upload_ic from "../../../../assets/images/file-upload-ic.svg";
import close_icon from "../../../../assets/images/close_icon.svg";

import Notification from '../../../../shared/components/notification/notification';
import TextField from "../../../../shared/components/TextField/TextField";

import moment from 'moment';

const ConfigurationTab = props => {
    const initialValues = {
        PageURL: props.GetProductData[0].PageURL, MenuPath: props.GetProductData[0].MenuPath, CustomizePath: props.GetProductData[0].CustomizePath, ThemeButtons: props.GetProductData[0].ThemeButtons,
        showaddtocart: props.GetProductData[0].ShowAddToCart, showcopytext: props.GetProductData[0].ShowCopyText,
        required: props.GetProductData[0].ShowAdBuilder ? 1 : 0, showdownload: props.GetProductData[0].ShowDownload ? 1 : 0,
        isprint: props.GetProductData[0].IsPrintProduct ? 1 : 0,
        ImageName: '', ImageData: '',
        ImageURL: props.GetProductData[0].PreviewURL != null && props.GetProductData[0].PreviewURL != undefined && props.GetProductData[0].PreviewURL != "" ? props.GetProductData[0].PreviewURL : '',
        BypassPayPalFlow: props.GetProductData[0].BypassPayPalFlow, FreeUntil: moment(props.GetProductData[0].FreeUntil).format('YYYY-MM-DD'), StartSubscribeFrom: moment(props.GetProductData[0].StartSubscribeFrom).format('YYYY-MM-DD')
    }
    // moment(new Date().toUTCString()).utcOffset(0, false).format('MMDDYY')
    const [IsLoading, setIsLoading] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [showNotification, setShowNotification] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const handleNotification = (message, isError) => {
        setMessage(message)
        setShowNotification(true)
        setIsError(isError)

        setTimeout(() => {
            handleClearNotification();
        }, 4000)
    }

    const handleClearNotification = () => {
        setMessage('')
        setShowNotification(false)
        setIsError(false)
    }

    const wsConfigureProduct = (ID, PageURL, MenuPath, CustomizePath, ThemeButtons, showaddtocart, showcopytext, required, showdownload, isprint, PreviewImageName, PreviewImageData, PreviewImageURL, BypassPayPalFlow, FreeUntil, StartSubscribeFrom) => {
        setIsLoading(true)
        APIController.ConfigureProduct(ID, PageURL, MenuPath, CustomizePath, ThemeButtons, showaddtocart, showcopytext, required, showdownload, isprint, PreviewImageName, PreviewImageData, PreviewImageURL, BypassPayPalFlow, FreeUntil, StartSubscribeFrom)
            .then((response) => {
                setIsLoading(false)
                if (response.error == null) {
                    handleNotification(response.object.message)
                    if (response.object.status == 1) {
                        props.history.push({ pathname: '/product', state: { showMessage: response.object.message } })
                    } else {
                        handleNotification(response.object.message, true);
                    }
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }

    const getBase64 = file => {
        return new Promise(resolve => {
            let fileInfo;
            let baseURL = "";
            // Make new FileReader
            let reader = new FileReader();

            // Convert the file to base64 text
            reader.readAsDataURL(file);

            // on reader load somthing...
            reader.onload = () => {
                // Make a fileInfo Object
                baseURL = reader.result;
                resolve(baseURL);
            };
        });
    };

    return (
        <>
            <section className="tab-content asset-tab-wrapper">
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={validateProductForm}
                    validateOnMount={false}
                    onSubmit={(values) => {
                        wsConfigureProduct(props.history.location.state.productIdEdit,
                            values.PageURL, values.MenuPath, values.CustomizePath, values.ThemeButtons,
                            values.showaddtocart, values.showcopytext, values.required, values.showdownload, values.isprint,
                            values.ImageName, values.ImageData, "", values.BypassPayPalFlow, values.FreeUntil, values.StartSubscribeFrom)
                    }}
                >
                    {({ values, handleSubmit, setFieldValue, errors, touched }) => {
                        return (
                            <div className="form-main" >
                                <form onSubmit={handleSubmit}>

                                    <div className={`form_field ${errors && touched.PageURL && errors.PageURL && errors.PageURL !== '' ? 'error' : ''}`}>
                                        <div className="field-left">
                                            <label>Page URL</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter Page URL" name="PageURL" value={values.PageURL} />
                                            {errors && touched.PageURL && errors.PageURL && errors.PageURL !== '' && (
                                                <span className='error-msg'>
                                                    <ErrorMessage name='PageURL' render={(err) => { return err; }} />
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className={`form_field ${errors && touched.MenuPath && errors.MenuPath && errors.MenuPath !== '' ? 'error' : ''}`}>
                                        <div className="field-left">
                                            <label>Menu Path</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter Menu Path" name="MenuPath" value={values.MenuPath} />
                                            {errors && touched.MenuPath && errors.MenuPath && errors.MenuPath !== '' && (
                                                <span className='error-msg'>
                                                    <ErrorMessage name='MenuPath' render={(err) => { return err; }} />
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className={`form_field`}>
                                        <div className="field-left"> <label>Customize Path</label> </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter Customize Path" name="CustomizePath" value={values.CustomizePath} />
                                        </div>
                                    </div>
                                    <div className={`form_field`}>
                                        <div className="field-left"> <label>Theme Buttons</label> </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter Theme Buttons" name="ThemeButtons" value={values.ThemeButtons} />
                                        </div>
                                    </div>

                                    <div className="form_field">
                                        <div className="field-left">
                                            <label>Show Add To Cart ?</label>
                                        </div>
                                        <div className="field-right">
                                            <div className="custom-radio">
                                                <input type="radio" name="showaddtocart" id="showaddtocart-yes-text"
                                                    defaultValue={values.showaddtocart}
                                                    defaultChecked={values.showaddtocart != 0 ? true : false}
                                                    onClick={(e) => setFieldValue('showaddtocart', 1)} />
                                                <label htmlFor="showaddtocart-yes-text">Yes</label>
                                            </div>
                                            <div className="custom-radio">
                                                <input type="radio" name="showaddtocart" id="showaddtocart-no-text"
                                                    defaultValue={values.showaddtocart}
                                                    defaultChecked={values.showaddtocart == 0 ? true : false}
                                                    onClick={(e) => setFieldValue('showaddtocart', 0)} />
                                                <label htmlFor="showaddtocart-no-text">No</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form_field">
                                        <div className="field-left">
                                            <label>Show Copy Text ?</label>
                                        </div>
                                        <div className="field-right">
                                            <div className="custom-radio">
                                                <input type="radio" name="showcopytext" id="showcopytext-yes-text"
                                                    defaultValue={values.showcopytext}
                                                    defaultChecked={values.showcopytext != 0 ? true : false}
                                                    onClick={(e) => setFieldValue('showcopytext', 1)} />
                                                <label htmlFor="showcopytext-yes-text">Yes</label>
                                            </div>
                                            <div className="custom-radio">
                                                <input type="radio" name="showcopytext" id="showcopytext-no-text"
                                                    defaultValue={values.showcopytext}
                                                    defaultChecked={values.showcopytext == 0 ? true : false}
                                                    onClick={(e) => setFieldValue('showcopytext', 0)} />
                                                <label htmlFor="showcopytext-no-text">No</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form_field">
                                        <div className="field-left">
                                            <label>Required AdBuilder ?</label>
                                        </div>
                                        <div className="field-right">
                                            <div className="custom-radio">
                                                <input type="radio" name="required" id="required-yes-text"
                                                    defaultValue={values.required}
                                                    defaultChecked={values.required != 0 ? true : false}
                                                    onClick={(e) => setFieldValue('required', 1)} />
                                                <label htmlFor="required-yes-text">Yes</label>
                                            </div>
                                            <div className="custom-radio">
                                                <input type="radio" name="required" id="required-no-text"
                                                    defaultValue={values.required}
                                                    defaultChecked={values.required == 0 ? true : false}
                                                    onClick={(e) => setFieldValue('required', 0)} />
                                                <label htmlFor="required-no-text">No</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form_field">
                                        <div className="field-left">
                                            <label>Show Download ?</label>
                                        </div>
                                        <div className="field-right">
                                            <div className="custom-radio">
                                                <input type="radio" name="showdownload" id="download-yes-text"
                                                    defaultValue={values.showdownload}
                                                    defaultChecked={values.showdownload != 0 ? true : false}
                                                    onClick={(e) => setFieldValue('showdownload', 1)} />
                                                <label htmlFor="download-yes-text">Yes</label>
                                            </div>
                                            <div className="custom-radio">
                                                <input type="radio" name="showdownload" id="download-no-text"
                                                    defaultValue={values.showdownload}
                                                    defaultChecked={values.showdownload == 0 ? true : false}
                                                    onClick={(e) => setFieldValue('showdownload', 0)} />
                                                <label htmlFor="download-no-text">No</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form_field">
                                        <div className="field-left">
                                            <label>Is Print Product ?</label>
                                        </div>
                                        <div className="field-right">
                                            <div className="custom-radio">
                                                <input type="radio" name="isprint" id="print-yes-text"
                                                    defaultValue={values.isprint}
                                                    defaultChecked={values.isprint != 0 ? true : false}
                                                    onClick={(e) => setFieldValue('isprint', 1)} />
                                                <label htmlFor="print-yes-text">Yes</label>
                                            </div>
                                            <div className="custom-radio">
                                                <input type="radio" name="isprint" id="print-no-text"
                                                    defaultValue={values.isprint}
                                                    defaultChecked={values.isprint == 0 ? true : false}
                                                    onClick={(e) => setFieldValue('isprint', 0)} />
                                                <label htmlFor="print-no-text">No</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form_field">
                                        <div className="field-left">
                                            <label>Bypass PayPal Flow ?</label>
                                        </div>
                                        <div className="field-right">
                                            <div className="custom-radio">
                                                <input type="radio" name="BypassPayPalFlow" id="bypass-yes-text"
                                                    defaultValue={values.BypassPayPalFlow}
                                                    defaultChecked={values.BypassPayPalFlow != 0 ? true : false}
                                                    onClick={(e) => setFieldValue('BypassPayPalFlow', 1)} />
                                                <label htmlFor="bypass-yes-text">Yes</label>
                                            </div>
                                            <div className="custom-radio">
                                                <input type="radio" name="BypassPayPalFlow" id="bypass-no-text"
                                                    defaultValue={values.BypassPayPalFlow}
                                                    defaultChecked={values.BypassPayPalFlow == 0 ? true : false}
                                                    onClick={(e) => setFieldValue('BypassPayPalFlow', 0)} />
                                                <label htmlFor="bypass-no-text">No</label>
                                            </div>
                                        </div>
                                    </div>
                                    {values.BypassPayPalFlow ?
                                        <div>
                                            <div className="form_field">
                                                <div className="field-left">
                                                    <label>Free Until</label>
                                                </div>
                                                <div className='form-file-input'>
                                                    <TextField style={{ margin: '10px' }} type="date" name="FreeUntil" value={values.FreeUntil} onChange={(e) => { setFieldValue('FreeUntil', e.target.value) }} />
                                                </div>
                                            </div>

                                            <div className="form_field">
                                                <div className="field-left">
                                                    <label>Start Subscribe From</label>
                                                </div>
                                                <div className='form-file-input'>
                                                    <TextField style={{ margin: '10px' }} type="date" name="StartSubscribeFrom" value={values.StartSubscribeFrom} onChange={(e) => { setFieldValue('StartSubscribeFrom', e.target.value) }} />
                                                </div>
                                            </div>
                                        </div> : <></>}

                                    <div className="form_field">
                                        <div className="field-left">
                                            <label>Product Preview</label>
                                        </div>
                                        <div className='form-file-input'>
                                            <input className='input-file' id='fileupload' name='files' type='file' accept=".svg,.png,.jpeg,.pdf,.mp4,.jpg,.zip,.gif" onChange={(e) => {
                                                const file = e.target.files[0];

                                                if (file && (file.type.includes("svg") || file.type.includes("png") || file.type.includes("jpeg") || file.type.includes("pdf") ||
                                                    file.type.includes("mp4") || file.type.includes("jpg") || file.type.includes("gif"))) {
                                                    let file1 = e.target.files[0]
                                                    file1['preview'] = URL.createObjectURL(e.target.files[0]);
                                                    let extension = file.type.split('/')[1].match("x-zip-compressed") ? "zip" : file.type.split('/')[1]
                                                    // setImageName(file1.name)
                                                    // setFileExtension(extension)
                                                    setFieldValue("ImageName", values.ImageName == "" ?
                                                        uuid() + "." + extension :
                                                        values.ImageName.split('.')[0] + "." + extension);

                                                    getBase64(file)
                                                        .then(result => {
                                                            file["base64"] = result;
                                                            setFieldValue("ImageData", result.split(',').pop())
                                                        }).catch(err => { });
                                                    setFieldValue('ImageURL', file1.preview);
                                                }
                                            }} />
                                            {values.ImageURL == '' || values.ImageURL == null || values.ImageURL == undefined ?
                                                <label htmlFor='fileupload' className='input-file-trigger'>
                                                    <img src={file_upload_ic} alt='file-uplaod' /> Drag and drop your file here </label> :
                                                <div className='logo-preview' style={{ marginTop: '0px' }}>
                                                    <a title="" className="close-ic"><img src={close_icon} alt="close-ic" onClick={() => { setFieldValue('ImageURL', '', 'ImageData', '') }} /></a>
                                                    <img src={values.ImageURL} style={{ height: '170px', width: '230px' }} alt='' />
                                                </div>}
                                        </div>
                                        <p> Image size: 800X800 px. Accepted files : .jpg, .jpeg, .png, .svg, .pdf, .gif, .mp4</p>
                                    </div>

                                    <Notification
                                        isError={isError}
                                        message={message}
                                        showNotification={showNotification}
                                        clearNotification={handleClearNotification.bind(this)}
                                        closeNotification={handleClearNotification.bind(this)}
                                    />

                                    <div className="form-submit">
                                        <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                            <input type="submit" value={IsLoading ? "Saving..." : "save"} name="save" />
                                        </div>

                                        <div className="button-field ">
                                            <input type="button" value="close" name="close" onClick={() => props.history.goBack()} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        )
                    }}
                </Formik>
            </section>
        </>
    )
}
const validateProductForm = yup.object().shape({
    PageURL: yup.string().required('Page URL is required'),
    MenuPath: yup.string().required('Menu Path is required'),
});
export default ConfigurationTab;