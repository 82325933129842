/*eslint-disable eqeqeq*/
import React from "react";
import { NavLink } from "react-router-dom";
// import { jwt_decode } from 'jwt-decode';
import { Formik, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import Modal from "../../../shared/components/modal/modal";
import Slider from "../../../shared/components/slider/slick-slider";
import authService from "../../../shared/services/auth-service";
import VerifyOtp from "../component/verify-otp";
import ForgotPassword from "../component/forgot-password";
import "../component/login.css";
import { API, HTTPMethod } from "../../../api/API";
import { URLs } from "../../../api/URLs";

import * as APIController from '../../../api/APIController.js';
import * as AppConstants from "../../../config/AppConstant";
import TextField from "../../../shared/components/TextField/TextField";
import Notification from "../../../shared/components/notification/notification";
// const CustomInputComponent = (props) => (
// 	<TextField style={{backgroundColor:'white'}} placeholder='name@domain.com' label='FirstName'   name='bamekjkj'   type="text" {...props} />
//   );

class LoginContainer extends React.Component {
	state = {
		isModalOpen: false,
		tag: "",
		username: "",
		password: "",
		IsLoading: false,
		RememberMe: false,
	};


	render() {

		return (
			<>
				<section className='login_page'>
					<div className='main'>
						<div className='inner-screen'>
							<div className='left-column'>
								<div className='logo'>
									{/* <img className='App-logo' src={AppConstants.HeaderLogo} alt={AppConstants.HeaderLogo} /> */}
									<img src={AppConstants.LoginLogo} alt={AppConstants.LoginLogo} />
									{/* <img src={AppConstants.VSPPremierLogo} style={{ maxWidth: "120px", marginLeft: '5%' }} /> */}
								</div>
								<div className='middle-content'>
									<h1>Login</h1>
									<Formik
										initialValues={{
											username: "",
											password: "",
										}}
										validationSchema={validateLoginForm}
										validateOnMount={false}
										// match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
										onSubmit={(values) => {
											if (!values.username.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
												this.handleShowNotification("Please enter valid Email Address.", true);
											} else if (values.username.length > 100) {
												this.handleShowNotification("Email Address length should be 100 only", true);
											} else if (values.password.length > 50) {
												this.handleShowNotification("Password length should be 50 only", true);
											}
											else {
												this.handleLogin(values)
											}
										}}
									// onSubmit={(values) => this.handleLogin(values)}
									>
										{({ values, handleSubmit, errors, touched }) => (
											<form onSubmit={handleSubmit}>
												<div
													className={`form-field ${errors &&
														touched.username &&
														errors.username &&
														errors.username !== ""
														? "error"
														: ""
														}`}>
													{/* <label>Email Address</label> */}
													<div className='field_main'>
														<Field
															as={TextField}
															error={touched.username &&
																errors.username &&
																errors.username !== "" ? true : false}
															{...this.props}
															label='Email Address'
															placeholder='name@domain.com'
															name='username'
														// className='user-field'
														/>
														{errors &&
															touched.username &&
															errors.username &&
															errors.username !== "" && (
																<span className='error-msg'>
																	<ErrorMessage
																		name='username'
																		render={(err) => {
																			return err;
																		}}
																	/>
																</span>
															)}
													</div>
												</div>
												<div className={`form-field ${errors && touched.password && errors.password && errors.password !== "" ? "error" : ""}`}>
													{/* <label>Password</label> */}
													<div className='field_main'>
														<Field
															as={TextField}
															{...this.props}
															type='password'
															error={touched.password && errors.password && errors.password !== "" ? true : false}
															label={'Password'}
															placeholder='******'
															name='password'
														// className='pwd-field'
														/>
														{errors && touched.password && errors.password && errors.password !== "" && (<span className='error-msg'>
															<ErrorMessage name='password' render={(err) => { return err; }} />
														</span>
														)}
													</div>
												</div>
												<div className='form-field custom-checkbox'>
													<input type='checkbox'
														name=''
														id='checkme'
														value={this.state.RememberMe}
														onChange={(e) => this.setState({ RememberMe: e.target.checked })}
													/>
													<label htmlFor='checkme'> Remember me </label>
												</div>
												<div className="form-field">
													<div className={this.state.IsLoading ? 'button-field disable-btn' : 'button-field'}>
														<input
															type='submit'
															value={this.state.IsLoading ? "Logging In..." : "Login"}
															name='sign-in'
														/>
													</div>

													{AppConstants.GetMainClassName() == "app-main" &&
														<div className='button-field'>
															<input
																type='button'
																value='Register'
																name='Register'
																onClick={() => { window.open("/clinic-registration"); }}
															/>
														</div>}
												</div>
											</form>
										)}
									</Formik>

									{AppConstants.GetMainClassName() == "app-main" &&
										<div className='bottom-links'>
											<button onClick={() => this.handleOpenModal("password")}> Forgot Password?</button>
										</div>}
								</div>
							</div>
							<div className='right-column' style={{ padding: '0px', margin: '0px', position: 'relative' }}>
								<div className='col-head'>
									<h2>{AppConstants.BLLoginSlider.HeadingText}</h2>
									<p>{AppConstants.BLLoginSlider.PtagText}</p>
								</div>

								<div className='image-slider'>
									<Slider>
										{AppConstants.BLLoginSlider.Images.map((object, index) => {
											return (
												<div className='image-slider'>
													<img src={object.ImageUrl} alt={object.ImageUrl} />
												</div>
											)
										})}

									</Slider>
								</div>
							</div>
						</div>
					</div>
				</section>
				<Notification
					isError={this.state.isError}
					message={this.state.message}
					showNotification={this.state.showNotification}
					MessageLocationLogin={"LoginToast"}
					closeNotification={this.handleClearNotification}
				/>
				{this.state.isModalOpen && (
					<Modal
						show={this.state.isModalOpen}
						handleClose={this.handleCloseModal}>
						{this.state.tag === "username" ? (
							<VerifyOtp
								{...this.props}
								closeModal={this.handleCloseModal}
								handleNotification={this.handleShowNotification}
							/>
						) : this.state.tag !== "" ? (
							<ForgotPassword
								{...this.props}
								closeModal={this.handleCloseModal}
								// sendUserName={this.handleSendUserName}
								handleNotification={this.handleShowNotification}
								UserID={0}
							/>
						) : null}
					</Modal>
				)}
			</>
		);
	}

	/**
	 * handleInputChange method is used to handle input change method
	 */
	handleInputChange = (name, value) => {
		this.setState({ [name]: value });
	};

	/**
	 * handle login method is called when user click on login
	 */
	handleLogin = (value) => {
		this.setState({ IsLoading: true });
		if (value.password.length <= 5) {
			this.setState({ IsLoading: false })
			this.handleShowNotification("Password should be minimum 6 characters long", true);
		} else {
			this.wsGetAccessToken(value);
		}

	};

	wsGetAccessToken(value) {
		var details = {
			username: value.username,
			password: value.password,
			grant_type: "password",
		};

		var formBody = [];
		for (var property in details) {
			var encodedKey = encodeURIComponent(property);
			var encodedValue = encodeURIComponent(details[property]);
			formBody.push(encodedKey + "=" + encodedValue);
		}
		formBody = formBody.join("&");

		API.shared
			.request(HTTPMethod.post, URLs.base + URLs.Gettoken, formBody)
			.then((response) => {

				// this.setState({ IsLoading: false })
				if (response && response.StatusCode === 401) {
					this.handleShowNotification(response.StatusCode.toString() + " " + response.Message, true);
				} else if (response.error == undefined) {
					sessionStorage.setItem("Token", JSON.stringify(response));
					sessionStorage.setItem("Access_Token", response.access_token);

					authService.setAuthData('response');

					AppConstants.SetLocalStorage("sideBarMenuAdmin", 1);
					if (this.state.RememberMe == true) {
						AppConstants.SetLocalStorage("RememberMe", JSON.stringify(response))
					}

					const jwt_decode = require("jwt-decode");
					var decoded = jwt_decode(response.jwt);

					AppConstants.SetLocalStorage("profilelogo", decoded.ImageURL);
					AppConstants.SetLocalStorage("ShowSMAuthentication", decoded.ShowSMAuthentication);
					AppConstants.SetLocalStorage("ShowInstaAutomation", decoded.ShowInstaAutomation);
					AppConstants.SetLocalStorage("TotalOrders", decoded.TotalOrders);
					if (decoded.IsCoOpEligible != 0) {
						AppConstants.SetLocalStorage("headerMenuCoOp", 1);
						AppConstants.SetLocalStorage("headerMenuCoOpAmount", decoded.CoOpAmount);
					} else {
						AppConstants.SetLocalStorage("headerMenuCoOp", 0);
					}

					if (AppConstants.RoleID == decoded.RoleID) {
						AppConstants.SetLocalStorage("isSocialMediaRequired", 1);
					} else {
						AppConstants.SetLocalStorage("isSocialMediaRequired", 0);
					}
					// if (decoded.LeftNav != []) {
					AppConstants.SetLocalStorage("headerMenuAdmin", 0);

					// Navigation And Get SideBar Data
					this.wsGetCart(decoded)

					AppConstants.LogEvent('Login', {
						'id': decoded.ID,
						'emailaddress': value.username
					})
				} else {
					this.handleShowNotification(response.error, true);
					this.setState({ IsLoading: false });
				}

			})
			.catch((error) => {
				this.handleShowNotification(error.toString(), true);
				this.setState({ IsLoading: false });
			});
	}

	wsGetCart(decoded) {
		APIController.GetCart()
			.then((response) => {
				if (response.object.message == "") {
					AppConstants.SetLocalStorage("localStorageCartCount", response.object.data.Products.length + response.object.data.Subscriptions.length)
				} else {
					AppConstants.SetLocalStorage("localStorageCartCount", 0)
				}
				this.wsGetNav(decoded)
			})
	}

	wsGetNav(decoded) {
		APIController.GetNav()
			.then((response) => {
				if (response.error == null) {
					AppConstants.SetLocalStorage('GetNav', JSON.stringify(response));
					this.setState({ IsLoading: false });

					//Navigate home Screen When Api Call is Done
					AppConstants.SetLocalStorage('localStorageBrandID', response.object.data.BrandsNav[0].BrandID);
					AppConstants.SetLocalStorage('localStorageBrandName', response.object.data.BrandsNav[0].BrandName);
					AppConstants.SetLocalStorage('localStorageCampaignID', JSON.parse(response.object.data.BrandsNav[0].Campaigns)[0].CampaignID);
					AppConstants.SetLocalStorage('localStorageCampaignName', JSON.parse(response.object.data.BrandsNav[0].Campaigns)[0].CampaignName);
					if (decoded.SendToOTP == 0) {
						if (AppConstants.GetMainClassName() == "app-main") {
							this.props.history.push("/home");
						} else {
							this.props.history.push("/digital-product/selection");
						}
					} else {
						this.handleOpenModal("username");
					}
				}

			}).catch((err) => { })
	}



	/**
	 * handleOpenModal method is used to set modal
	 */
	handleOpenModal = (tag) => {
		this.setState({
			tag: tag,
			isModalOpen: true,
		});
	};

	/**
	 * handleSendUserName method is used to listen when user click on send username button
	 */
	handleSendUserName = (username) => {
		if (username !== "") {
			this.setState({
				tag: "",
				isModalOpen: false,
			});
		}
	};

	/**
	 * handleCloseModal method is used to close modal
	 */
	handleCloseModal = () => {
		this.setState({ isModalOpen: false });
	};

	/**
	 * handleVerifyOtp method is used when user clicks on verify otp
	 */
	handleVerifyOtp = (otp) => {
		if (otp !== "") {
			this.setState({
				isModalOpen: false,
				tag: "",
			});
		}
	};

	handleShowNotification = (message, isError) => {
		this.setState({
			message: message,
			showNotification: true,
			isError: isError,
		});

		setTimeout(() => {
			this.handleClearNotification();
		}, 4000);
	};
	handleClearNotification = () => {
		this.setState({
			message: "",
			showNotification: false,
			isError: false,
		});
	};
}

const validateLoginForm = yup.object().shape({
	username: yup.string().required("Email Address is required"),
	password: yup.string().required("Password is required"),
});

export default LoginContainer;
