/*eslint-disable eqeqeq*/
import React from "react";
import VerifyOtp from "../../login/component/verify-otp";
import Modal from "../../../shared/components/modal/modal";
import PageTitle from "../../../config/pageTitle";
import * as AppConsatnt from "../../../config/AppConstant";
import Slider from "../../../shared/components/slider/slick-sliderHome";
import * as AppConstants from "../../../config/AppConstant";
import "./dashboard.css";

const Dashboard = (props) => {

	const [isModalOpen, setisModalOpen] = React.useState(false);
	const handleCloseModal = () => { setisModalOpen(false) };

	return (
		<>
			<section className='content-right-main'>
				<PageTitle Title={AppConsatnt.ContactDetais.HomePageTitle} />
				<div className='two-col-sec'>
					<div className='sec-left'>
						<p align="justify" style={{ lineHeight: 1.4, fontWeight: 900, color: '#4b4c4c' }} >Marketing Resources to Engage with Patients and Help Grow Your Practice.</p>
						<p align="justify" style={{ lineHeight: 1.4 }}>Tools and resources available in the REACH Portal include:</p>
						{/* <p align="justify" style={{ lineHeight: 1.4 }}>What’s in the Portal?</p> */}
						<ul style={{ margin: '20px 0px 20px 20px' }}>
							<li style={{ padding: '5px 0px 5px 15px', lineHeight: 1.4 }}><strong>Free Bausch + Lomb Assets</strong> featuring Social Posts, Videos, Website Banners, Print Materials . . . and more.</li>
							<li style={{ padding: '5px 0px 5px 15px', lineHeight: 1.4 }}><strong>Email Marketing</strong> to keep your practice top-of-mind with patients.</li>
							<li style={{ padding: '5px 0px 5px 15px', lineHeight: 1.4 }}><strong>Direct Mail</strong> A turnkey service for sending Biotrue, INFUSE and Bausch + Lomb ULTRA postcards to your practice's mailing list.</li>
							<li style={{ padding: '5px 0px 5px 15px', lineHeight: 1.4 }}><strong>Digital Campaigns</strong> to locally promote your practice on Facebook, Instagram and Google.</li>
							<li style={{ padding: '5px 0px 5px 15px', lineHeight: 1.4 }}><strong>Social Media Automation</strong> to keep your Facebook & Instagram business pages refreshed with new engaging content.</li>
						</ul>
						<p align="justify" style={{ lineHeight: 1.4 }} ><strong><em>Click on the left menu to get started!</em></strong></p>
					</div>
					<div className='sec-right image-slider' style={{ display: 'relative' }}>
						{/* <div className='image-slider'> */}
						<Slider>
							{AppConstants.BLHomeSlider.Images.map((object, index) => {
								return (
									<div className='image-slider'>
										<img src={object.ImageUrl} alt={object.ImageUrl} />
									</div>
								)
							})}

						</Slider>
						{/* </div> */}
						{/* <img src={home_img} alt='' /> */}
					</div>
				</div>
			</section>
			{isModalOpen && (<Modal
				show={isModalOpen}
				handleClose={handleCloseModal}>
				<VerifyOtp {...props} closeModal={handleCloseModal} />
			</Modal>)}
		</>
	);
};

export default Dashboard;
