/*eslint-disable eqeqeq*/
import React from "react";
import file_upload_ic from "../../../assets/images/file-upload-ic.svg";
import * as APIController from "../../../api/APIController.js";
import Notification from "../../../shared/components/notification/notification";
import { Formik, Field } from 'formik';
import { Grid } from '@material-ui/core';
import uuid from 'react-uuid';
import close_icon from "../../../assets/images/close_icon.svg";
import close_ic from '../../../assets/images/close-ic.svg';
import forgot_user_ic from '../../../assets/images/forgot-username-ic.svg';
import Editor from './editor';
import CircularProgress from '@material-ui/core/CircularProgress';
import MyiFrame from "../../../features/campaign/components/myiFrame";
import * as AppConstants from "../../../config/AppConstant";

const UploadFreeAsset = (props) => {
    const [ProductID, setProductID] = React.useState(props.campaignAsset.ProductID);
    const [IsLoading, setIsLoading] = React.useState(false);
    const [Loading, setLoading] = React.useState(false);

    const initialValues = {
        CampaignID: "", ThemeID: "", VeevaCode: "", ExpirationDate: "", AssetName: "", AssetTypeID: props.campaignAsset.AssetTypeId, OrderNo: "0",
        Segment: "", ExternalURL: "", Customizable: 0, DownloadType: 1, Description: "", ImageName: "", ImageData: "", PreviewName: "",
        ThumbnailImageData: "", ImageURL: "", PreviewURL: "", AdContent: "", PrintAdContent: ""
    }

    const [Campaigns, setCampaigns] = React.useState([]);
    const [Themes, setThemes] = React.useState([]);
    const [orderData, setOrderData] = React.useState([]);
    const [OriginalImageName, setOriginalImageName] = React.useState('');
    const [ThumbnailMessageImage, setThumbnailMessageImage] = React.useState('');
    const [MessageImage, setMessageImage] = React.useState('');
    const [descriptionLoader, setDescriptionLoader] = React.useState(true);
    const [description, setDescription] = React.useState('');

    const [previewContent, setPreviewContent] = React.useState('');
    const [previewPrintContent, setPreviewPrintContent] = React.useState('');

    React.useEffect(() => {
        if (props.campaignAsset.AssetEditID == 1) { }
        else if (props.campaignAsset.AssetEditID != 0) { wsGetCampaignFreeAssetByID(props.campaignAsset.AssetEditID) }
        else {
            wsGetCampaignForDropdown()
            setDescriptionLoader(false)
        }

        var pairs = [];
        for (let i = 0; i < 500; i++) {
            pairs.push({ ID: i + 1 });
        }
        setOrderData(pairs)
    }, []);

    const wsGetCampaignForDropdown = () => {
        APIController.GetCampaignForDropdown()
            .then((response) => {
                if (response.error == null) {
                    setCampaigns(response.object.data)
                } else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message)
                }
            })
    }

    const wsGetCampaignThemesForDropdown = (CampaignID) => {
        APIController.GetCampaignThemesForDropdown(CampaignID).then((response) => {
            if (response.error == null) {
                setThemes(response.object.data)
            } else if (response.error.message && response.error.message.length > 0) {
                handleNotification(response.error.message)
            }
        })
    }

    const wsAddEditCampaignFreeAsset = (ID, ProductID, CampaignThemeID, CampaignID, VeevaCode, ExpirationDate, AssetName, OrderNo, ImageName, ImageData, AssetTypeID,
        Description, Segment, ExternalURL, Customizable, DownloadType, ThumbnailImageName, ThumbnailImageData, OriginalImageName, AdContent, PrintAdContent) => {
        setIsLoading(true)
        APIController.AddEditCampaignFreeAsset(ID, ProductID, CampaignThemeID, CampaignID, VeevaCode, ExpirationDate, AssetName, OrderNo, ImageName, ImageData,
            AssetTypeID, Description, Segment, ExternalURL, Customizable, DownloadType, ThumbnailImageName, ThumbnailImageData,
            OriginalImageName, AdContent, PrintAdContent)
            .then((response) => {
                setIsLoading(false)
                if (response.error == null) {
                    if (response.object.status == 1) {
                        handleNotification(response.object.message);
                        setTimeout(() => {
                            props.closeModal()
                        }, 4000);
                    } else {
                        handleNotification(response.object.message, true);
                    }
                } else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }

    const wsReSequenceCampaignFreeAssets = (AssetTypeID, ProductID, TotalNewAssets, IDs) => {
        setIsLoading(true)
        APIController.ReSequenceCampaignFreeAssets(AssetTypeID, ProductID, TotalNewAssets, IDs)
            .then((response) => {
                setIsLoading(false)
                if (response.error == null) {
                    if (response.object.status == 1) {
                        handleNotification(response.object.message);
                        setTimeout(() => {
                            props.closeModal()
                        }, 4000);
                    } else {
                        handleNotification(response.object.message, true);
                    }
                } else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }

    const wsDownloadCampaignAssetFile = (ID, TypeID, IsFreeAsset) => {
        APIController.DownloadCampaignAssetFile(ID, TypeID, IsFreeAsset)
            .then((response) => {
                if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }

    const wsGetCampaignFreeAssetByID = (ID) => {
        setLoading(true)
        APIController.GetCampaignFreeAssetByID(ID)
            .then((response) => {
                if (response.error == null) {
                    initialValues.CampaignID = response.object.data[0].CampaignID
                    initialValues.ThemeID = response.object.data[0].CampaignThemeID
                    initialValues.VeevaCode = response.object.data[0].VeevaCode
                    initialValues.ExpirationDate = response.object.data[0].ExpirationDate
                    initialValues.AssetName = response.object.data[0].Name
                    initialValues.AssetTypeID = response.object.data[0].AssetTypeID
                    initialValues.OrderNo = response.object.data[0].OrderNo
                    initialValues.Segment = response.object.data[0].Segment
                    initialValues.ExternalURL = response.object.data[0].ExternalURL
                    initialValues.Customizable = response.object.data[0].IsCustomizable ? 1 : 0
                    initialValues.DownloadType = response.object.data[0].DownloadType == "1" ? 1 : 2
                    initialValues.Description = response.object.data[0].Description
                    initialValues.ImageName = response.object.data[0].ImageName
                    initialValues.ImageURL = response.object.data[0].ImageURL
                    initialValues.AdContent = response.object.data[0].AdContent == null && response.object.data[0].AdContent == undefined ? "" : new Buffer(response.object.data[0].AdContent).toString('base64')
                    initialValues.PrintAdContent = response.object.data[0].PrintAdContent == null && response.object.data[0].PrintAdContent == undefined ? "" : new Buffer(response.object.data[0].PrintAdContent).toString('base64')
                    initialValues.PreviewName = response.object.data[0].PreviewName
                    initialValues.PreviewURL = response.object.data[0].PreviewURL

                    setDescription(response.object.data[0].Description)
                    setThemes(JSON.parse(response.object.data[0].CampaignThemes));
                    setCampaigns(JSON.parse(response.object.data[0].Campaigns));
                    setPreviewContent(response.object.data[0].AdContent)
                    setPreviewPrintContent(response.object.data[0].PrintAdContent)
                    setDescriptionLoader(false)
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
                setLoading(false)
            })
    }

    const [message, setMessage] = React.useState("");
    const [showNotification, setShowNotification] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const handleNotification = (message, isError) => {
        setMessage(message);
        setShowNotification(true);
        setIsError(isError);

        setTimeout(() => {
            handleClearNotification();
        }, 4000);
    };

    const handleClearNotification = () => {
        setMessage("");
        setShowNotification(false);
        setIsError(false);
    };

    const getBase64 = file => {
        return new Promise(resolve => {
            let fileInfo;
            let baseURL = "";
            // Make new FileReader
            let reader = new FileReader();

            // Convert the file to base64 text
            reader.readAsDataURL(file);

            // on reader load somthing...
            reader.onload = () => {
                // Make a fileInfo Object
                baseURL = reader.result;
                resolve(baseURL);
            };
        });
    };

    return (
        <div className="popup-box forgot-popup-box" >
            <div className="box-heading">
                <img src={forgot_user_ic} alt="icon" />
                <h2 className="h1">{props.titleName}</h2>
                <button className="close-btn"><img src={close_ic} alt="icon" onClick={props.closeModal} /></button>
            </div>
            <div style={{ padding: '5%', width: '100%' }}>
                <Formik
                    initialValues={initialValues}
                    // validationSchema={validateAssetForm}
                    validateOnMount={false}
                    onSubmit={(values) => {
                        if (props.campaignAsset.AssetEditID == 1 && values.OrderNo == '0') {
                            handleNotification("Please Select Total New Assets", true);
                        } else if (props.campaignAsset.AssetEditID == 1) {
                            wsReSequenceCampaignFreeAssets(props.campaignAsset.AssetTypeId, ProductID, values.OrderNo, props.campaignAsset.IDs)
                        } else if (values.VeevaCode == '') {
                            handleNotification("Please Enter Veeva Code", true);
                        } else if (values.Customizable == 0 && values.ImageURL == '' && MessageImage == '') {
                            handleNotification("Please select image", true);
                        } else if (values.PreviewURL == '' && ThumbnailMessageImage == '') {
                            handleNotification("Please select thumbnail image", true);
                        } else {
                            wsAddEditCampaignFreeAsset(props.campaignAsset.AssetEditID, ProductID, values.ThemeID, values.CampaignID, values.VeevaCode,
                                values.ExpirationDate, values.AssetName, values.OrderNo, values.ImageName, MessageImage, values.AssetTypeID, description,
                                values.Segment, values.ExternalURL, values.Customizable, values.DownloadType, values.PreviewName,
                                ThumbnailMessageImage, OriginalImageName, values.AdContent, values.PrintAdContent)
                        }
                    }}
                >
                    {({
                        values,
                        handleSubmit,
                        setFieldValue,
                        errors,
                        touched,
                    }) => (
                        <form onSubmit={handleSubmit} >
                            {props.campaignAsset.AssetEditID == 1 ?
                                <div style={{ padding: '0px' }}>
                                    <div className='form_field'>
                                        <div className="field-left">
                                            <label>Re Sequence #</label>
                                        </div>
                                        <div className="field-right">
                                            <select
                                                id={'OrderNo'}
                                                name="OrderNo"
                                                defaultValue={values.OrderNo}
                                                value={values.OrderNo == "0" ? "Select Total New Assets" : values.OrderNo}
                                                onChange={(e) => setFieldValue('OrderNo', e.target.value)}>
                                                <option value="0">Select Total New Assets</option>
                                                {orderData != null && orderData.length && orderData.map((obj, index) => {
                                                    return <option selected={obj.ID == values.OrderNo ? obj.ID : obj.ID} value={obj.ID} key={index}>{obj.ID}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </div> :
                                <div style={{ padding: '0px' }}>
                                    <div className='form_field'>
                                        <div className="field-left">
                                            <label>Campaign</label>
                                        </div>
                                        <div className="field-right">
                                            <select
                                                name="CampaignID"
                                                defaultValue={values.CampaignID}
                                                value={values.CampaignID}
                                                onChange={(e) => {
                                                    setFieldValue('CampaignID', e.target.value)
                                                    wsGetCampaignThemesForDropdown(e.target.value)
                                                }}>
                                                <option value="">Select Campaign</option>
                                                {Campaigns != null && Campaigns.length && Campaigns.map((obj, index) => {
                                                    return <option selected={obj.ID == values.CampaignID ? obj.ID : obj.ID} value={obj.ID} key={index}>{obj.Name}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>

                                    <div className='form_field'>
                                        <div className="field-left">
                                            <label>Theme</label>
                                        </div>
                                        <div className="field-right">
                                            <select
                                                id={'ThemeID'}
                                                name="Theme"
                                                defaultValue={values.ThemeID}
                                                value={values.ThemeID == '0' || values.ThemeID == null ? "Select Theme" : values.ThemeID}
                                                onChange={(e) => setFieldValue('ThemeID', e.target.value)}>
                                                <option value="">Select Theme</option>
                                                {Themes != null && Themes.length && Themes.map((obj, index) => {
                                                    return <option selected={obj.ID == values.ThemeID ? obj.ID : obj.ID} value={obj.ID} key={index}>{obj.Name}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>

                                    <div className='form_field'>
                                        <div className="field-left"> <label>Veeva Code</label> </div>
                                        <div className="field-right">
                                            <Field name="VeevaCode" type="text" placeholder="Veeva Code" value={values.VeevaCode} />
                                        </div>
                                    </div>

                                    {AppConstants.GetMainClassName() == "app-main" && <div className='form_field'>
                                        <div className="field-left"> <label>Expiration Date</label> </div>
                                        <div className="field-right">
                                            <Field type='date' placeholder='Expiration Date' name='ExpirationDate' value={values.ExpirationDate} />
                                        </div>
                                    </div>}

                                    <div className='form_field'>
                                        <div className="field-left"> <label>Asset Name</label> </div>
                                        <div className="field-right">
                                            <Field name="AssetName" type="text" placeholder="Asset Name" value={values.AssetName} />
                                        </div>
                                    </div>

                                    <div className='form_field'>
                                        <div className="field-left"> <label>Sequence #</label> </div>
                                        <div className="field-right">
                                            <select
                                                id={'OrderNo'}
                                                name="OrderNo"
                                                defaultValue={values.OrderNo}
                                                value={values.OrderNo == "0" ? "Select Sequence #" : values.OrderNo}
                                                onChange={(e) => setFieldValue('OrderNo', e.target.value)}>
                                                <option value="0">Select Sequence #</option>
                                                {orderData != null && orderData.length && orderData.map((obj, index) => {
                                                    return <option selected={obj.ID == values.OrderNo ? obj.ID : obj.ID} value={obj.ID} key={index}>{obj.ID}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>

                                    <div className='form_field'>
                                        <div className="field-left"> <label>Segment</label> </div>
                                        <div className="field-right">
                                            <select
                                                id={'segment'}
                                                name="reportType"
                                                defaultValue={values.Segment}
                                                value={values.Segment == '0' ? "Select Segment" : values.Segment}
                                                onChange={(e) => setFieldValue('Segment', e.target.value)}>
                                                <option value=''>Select Segment</option>
                                                <option value='Standard'>Standard</option>
                                                <option value='Vision Source'>Vision Source</option>
                                                <option value='VSP Premier'>VSP Premier</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className='form_field'>
                                        <div className="field-left"> <label>External URL</label> </div>
                                        <div className="field-right">
                                            <Field name="ExternalURL" type="text" placeholder="External URL" value={values.ExternalURL} />
                                        </div>
                                    </div>

                                    {Loading ? <div style={{ textAlign: 'center' }}> <CircularProgress style={{ color: '#0a5c7f', alignSelf: 'center' }} /> </div>
                                        : <div className="form_field">
                                            <div className="field-left"> <label>Is Customizable Product ?</label> </div>
                                            <div className="field-right" style={{ width: '27%' }}>
                                                <div className="custom-radio">
                                                    <input type="radio" name="Customizable" id="yes-text"
                                                        defaultValue={values.Customizable}
                                                        defaultChecked={values.Customizable != 0 ? true : false}
                                                        onClick={(e) => setFieldValue('Customizable', 1)} />
                                                    <label htmlFor="yes-text">Yes</label>
                                                </div>
                                                <div className="custom-radio">
                                                    <input type="radio" name="Customizable" id="no-text"
                                                        defaultValue={values.Customizable}
                                                        defaultChecked={values.Customizable == 0 ? true : false}
                                                        onClick={(e) => setFieldValue('Customizable', 0)} />
                                                    <label htmlFor="no-text">No</label>
                                                </div>
                                            </div>
                                            {values.Customizable == 1 && <>
                                                <div className="field-left" style={{ width: '20%' }}>
                                                    <label>Download File Type</label>
                                                </div>
                                                <div className="field-right" style={{ width: '27%', textAlign: 'end' }}>
                                                    <div className="custom-radio">
                                                        <input type="radio" name="DownloadType" id="img-text"
                                                            defaultValue={values.DownloadType}
                                                            defaultChecked={values.DownloadType != 0 ? true : false}
                                                            onClick={(e) => setFieldValue('DownloadType', 1)} />
                                                        <label htmlFor="img-text">Image</label>
                                                    </div>
                                                    <div className="custom-radio">
                                                        <input type="radio" name="DownloadType" id="pdf-text"
                                                            defaultValue={values.DownloadType}
                                                            defaultChecked={values.DownloadType == 2 ? true : false}
                                                            onClick={(e) => setFieldValue('DownloadType', 2)} />
                                                        <label htmlFor="pdf-text">PDF</label>
                                                    </div>
                                                </div>
                                            </>}
                                        </div>}

                                    <div className='form_field'>
                                        <div className="field-left"> <label>Description</label> </div>
                                        {!descriptionLoader &&
                                            <div className="field-right" style={{ border: '1px solid #000', borderRadius: '7px' }}>
                                                <Editor content={description} setContent={setDescription} />
                                            </div>}
                                    </div>

                                    <Grid container direction='row' style={{ width: '100%', justifyContent: 'space-between', }}>
                                        {values.Customizable == 0 ?
                                            <Grid container direction='row' style={{ width: '33%', }}>
                                                <Grid className="form_field">
                                                    <div className='form-file-input'>
                                                        <label>Select Free Asset</label>
                                                        <input className='input-file' id='fileupload' name='files' type='file' accept=".svg,.png,.jpeg,.pdf,.mp4,.jpg,.zip,.gif" onChange={(e) => {
                                                            const file = e.target.files[0];

                                                            if (file && (file.type.includes("svg") || file.type.includes("png") || file.type.includes("jpeg") || file.type.includes("pdf") ||
                                                                file.type.includes("mp4") || file.type.includes("jpg") || file.type.includes("zip") || file.type.includes("gif"))) {
                                                                let file1 = e.target.files[0]
                                                                file1['preview'] = URL.createObjectURL(e.target.files[0]);
                                                                let extension = file.type.split('/')[1].match("x-zip-compressed") ? "zip" : file.type.split('/')[1]
                                                                setOriginalImageName(file1.name)
                                                                // setFileExtension(extension)
                                                                setFieldValue("ImageName", values.ImageName == "" ?
                                                                    uuid() + "." + extension :
                                                                    values.ImageName.split('.')[0] + "." + extension);

                                                                getBase64(file)
                                                                    .then(result => {
                                                                        file["base64"] = result;
                                                                        setMessageImage(result.split(',').pop())
                                                                    }).catch(err => { });
                                                                setFieldValue('ImageURL', file1.preview);
                                                            }
                                                        }} />
                                                        {values.ImageURL == '' || values.ImageURL == null || values.ImageURL == undefined ?
                                                            <label htmlFor='fileupload' className='input-file-trigger'>
                                                                <img src={file_upload_ic} alt='file-uplaod' /> Drag and drop your file here </label> :
                                                            <div className='logo-preview' style={{ marginTop: '0px' }}>
                                                                <a title="" className="close-ic"><img src={close_icon} alt="close-ic" onClick={() => { setFieldValue('ImageURL', ''); setMessageImage('') }} /></a>
                                                                <img src={values.ImageURL} style={{ height: '170px', width: '230px' }} alt='' />
                                                            </div>}
                                                    </div>
                                                    <p> Image size: 800X800 px. Accepted files : .jpg, .jpeg, .png, .svg, .pdf, .zip, .gif, .mp4</p>
                                                </Grid>
                                            </Grid>
                                            : <>
                                                <Grid container direction='row' style={{ width: '33%', }}>
                                                    <Grid className="form_field" style={{ flexDirection: 'column', width: '97%' }}>
                                                        <div className='form-file-input' style={{ width: '100%' }} >
                                                            <label>Select Free Asset</label>
                                                            <input className='input-file' id='fileupload' name='files' type='file' accept=".html" onChange={(e) => {
                                                                const file = e.target.files[0];
                                                                if (file && file.type === "text/html") {
                                                                    getBase64(file)
                                                                        .then(result => {
                                                                            file["base64"] = result;
                                                                            setFieldValue('AdContent', result.split(',').pop())
                                                                        }).catch(err => { });

                                                                    const reader = new window.FileReader();
                                                                    reader.onload = (data) => { setPreviewContent(data.target.result); };
                                                                    reader.readAsText(file);
                                                                }
                                                            }} />
                                                            {values.AdContent == '' || values.AdContent == null || values.AdContent == undefined ?
                                                                <label htmlFor='fileupload' className='input-file-trigger'>
                                                                    <img src={file_upload_ic} alt='file-uplaod' /> Drag and drop your file here </label> :
                                                                <div className='logo-preview' style={{ marginTop: '0px' }}>
                                                                    {props.campaignAsset.AssetEditID != 0 && values.AdContent != null &&
                                                                        <label style={{ justifyContent: 'flex-start' }} className="DirectMail-download-file-label" onClick={() => wsDownloadCampaignAssetFile(props.campaignAsset.AssetEditID, "1", "1")}>Download Asset</label>}
                                                                    <a title="" className="close-ic"><img src={close_icon} alt="close-ic" onClick={() => { setFieldValue('AdContent', ''); setPreviewContent('') }} /></a>
                                                                    <div style={{ minHeight: "150px", maxHeight: '300px', overflowX: 'hidden' }}> <MyiFrame HTMLContent={previewContent} /> </div>
                                                                </div>}
                                                        </div>
                                                        <p> Accepted files : .html</p>
                                                    </Grid>
                                                </Grid>
                                                <Grid container direction='row' style={{ width: '33%', }}>
                                                    <Grid className="form_field" style={{ flexDirection: 'column', width: '100%' }}>
                                                        <div className='form-file-input' style={{ width: '96%' }} >
                                                            <label>Select Print Free Asset</label>
                                                            <input className='input-file' id='fileupload' name='files' type='file' accept=".html" onChange={(e) => {
                                                                const file = e.target.files[0];
                                                                if (file && file.type === "text/html") {

                                                                    getBase64(file)
                                                                        .then(result => {
                                                                            file["base64"] = result;
                                                                            setFieldValue('PrintAdContent', result.split(',').pop())
                                                                        }).catch(err => { });

                                                                    const reader = new window.FileReader();
                                                                    reader.onload = (data) => { setPreviewPrintContent(data.target.result); };
                                                                    reader.readAsText(file);
                                                                }
                                                            }} />
                                                            {values.PrintAdContent == '' || values.PrintAdContent == null || values.PrintAdContent == undefined ?
                                                                <label htmlFor='fileupload' className='input-file-trigger'>
                                                                    <img src={file_upload_ic} alt='file-uplaod' /> Drag and drop your file here </label> :
                                                                <div className='logo-preview' style={{ marginTop: '0px' }}>
                                                                    {props.campaignAsset.AssetEditID != 0 &&
                                                                        <label style={{ justifyContent: 'flex-start' }} className="DirectMail-download-file-label" onClick={() => wsDownloadCampaignAssetFile(props.campaignAsset.AssetEditID, "2", "1")}>Download Print Asset</label>}
                                                                    <a title="" className="close-ic"><img src={close_icon} alt="close-ic" onClick={() => { setFieldValue('PrintAdContent', ''); setPreviewPrintContent('') }} /></a>
                                                                    <div style={{ minHeight: "150px", maxHeight: '300px', overflowX: 'hidden' }}> <MyiFrame HTMLContent={previewPrintContent} /> </div>
                                                                </div>}
                                                        </div>
                                                        <p> Accepted files : .html</p>
                                                    </Grid>
                                                </Grid>
                                            </>}

                                        <Grid container direction='row' style={{ width: '33%' }}>
                                            <Grid className="form_field" style={{ justifyContent: 'flex-end', alignItems: 'baseline' }} >
                                                <div className='form-file-input' style={{ width: '97%' }}>
                                                    <label> Select Thumbnail Image </label>
                                                    <input className='input-file' id='fileupload' name='files' type='file' accept=".svg,.png,.jpeg,.jpg,.gif" onChange={(e) => {
                                                        const file = e.target.files[0];
                                                        if (file && (file.type.includes("svg") || file.type.includes("png") || file.type.includes("jpeg") || file.type.includes("jpg") || file.type.includes("gif"))) {
                                                            let file1 = e.target.files[0]
                                                            file1['preview'] = URL.createObjectURL(e.target.files[0]);
                                                            setFieldValue("PreviewName", values.PreviewName == "" ?
                                                                uuid() + "." + file.type.split('/')[1] :
                                                                values.PreviewName.split('.')[0] + "." + file.type.split('/')[1]);

                                                            const image2base64 = require('image-to-base64');
                                                            image2base64(file1.preview).then((response) => { setThumbnailMessageImage(response) }).catch((error) => { })
                                                            setFieldValue('PreviewURL', file1.preview);
                                                        }
                                                    }} />
                                                    {values.PreviewURL == '' || values.PreviewURL == null || values.PreviewURL == undefined ?
                                                        <label htmlFor='fileupload' className='input-file-trigger'>
                                                            <img src={file_upload_ic} style={{ padding: '10px' }} alt='file-uplaod' /> Drag and drop your file here </label> :
                                                        <div className='logo-preview' style={{ marginTop: '10px' }}>
                                                            <a title="" className="close-ic"><img src={close_icon} alt="close-ic" onClick={() => { setFieldValue('PreviewURL', ''); setThumbnailMessageImage('') }} /></a>
                                                            <img src={values.PreviewURL} style={{ height: '170px', width: '230px' }} alt='' />
                                                        </div>}
                                                </div>
                                                <p> Image size: 250X250 px. Accepted files : .jpg, .jpeg, .png, .svg .gif</p>
                                            </Grid>
                                        </Grid>
                                    </Grid>


                                </div>}
                            <Grid container direction='row' style={{ width: '100%', justifyContent: 'center', }}>
                                <div className={IsLoading ? 'form-field button-field disable-btn' : 'form-field button-field'} style={{ textAlign: 'center', }} >
                                    <input type="submit" name="edit-profile" value="Save" />
                                </div>
                            </Grid>
                        </form>
                    )}
                </Formik>
            </div>
            <Notification
                isError={isError}
                message={message}
                showNotification={showNotification}
                clearNotification={handleClearNotification.bind(this)}
                closeNotification={handleClearNotification.bind(this)}
            />
        </div>

    );
};

export default UploadFreeAsset;
