/*eslint-disable eqeqeq*/
import React from "react";
import * as APIController from '../../api/APIController.js';
import Table from "../../shared/components/table/table";
import TableHead from "../../shared/components/table/tableHead";
import TableRow from "../../shared/components/table/tableRow";
import TableBody from "../../shared/components/table/tableBody";
import TableCell from "../../shared/components/table/tableCell";
import double_left_arrow from '../../assets/images/left-doble-arow.svg';
import left_arrow from '../../assets/images/left-arrow.svg';
import double_right_arrow from '../../assets/images/right-doble-arow.svg';
import right_arrow from '../../assets/images/right-arrow.svg';
import "react-perfect-scrollbar/dist/css/styles.css";
import PageTitle from "../../config/pageTitle";
import PerfectScrollbar from "react-perfect-scrollbar";

import filtericon from '../../assets/images/ic_feather_filter.svg'
import editIcon from '../../assets/images/edit.svg'
import deleteIcon from '../../assets/images/close-button.png'
import paypalicon from '../../assets/images/icon/paypalicon.svg'

import Modal from '../../shared/components/modal/modal';
import ConfirmtionDialog from '../ConfirmDialog/ConfirmationDialog';
import PaypalPopup from './paypalPopup';
import Notification from '../../shared/components/notification/notification';
import CircularProgress from "../../shared/components/CircularProgress/CircularProgress";
import * as AppConstants from "../../config/AppConstant";
import { PayPalButton } from "react-paypal-button-v2";
import moment from 'moment';

const SubscriptionsList = (props) => {
	const headerList = ['action', "Subscription #", "Practice Name", "Address", "Subscribed At", "Start Date", "Next Renewal", "Cost", "Status"]
	const { currentPage, count, rowsPerPage, rowsPerPageOptions, list, to, from } = props;
	let countryList = list;
	countryList = list.slice(from, to)
	const [LoginDialog, setLoginDialog] = React.useState(false);
	const [DeleteId, setDeleteId] = React.useState('');
	const [CalendarId, setCalendarId] = React.useState(0);

	const page = Math.floor(count / rowsPerPage) + 1;

	/**
	 * handleChangeInCurrentPage method is used to change page number
	 * it will check that page number does not exist last page
	 * @param {*} value 
	 */
	const handleChangeInCurrentPage = (value) => {
		if (value <= page && Number(parseInt(value))) {
			props.handleChangePage(Number(value));
		}
	}

	const wsDeleteCalendar = (ID) => {
		APIController.DeleteAutomationSubscription(ID)
			.then((response) => {
				if (response.error == null) {
					if (response.object.status == 1) {
						props.handleNotification(response.object.message);
					} else {
						props.handleNotification(response.object.message, true);
					}
					props.handlebind()
					setTimeout(() => {
						setLoginDialog(false)
					}, 4000);
				}
				else if (response.error.message && response.error.message.length > 0) {
					props.handleNotification(response.error.message)
				}
			})
	}

	React.useEffect(() => {
		// wsGetPracticeByID()
	}, [props])

	const [PlanID, setPlanID] = React.useState('P-6R1190367V376963JMJZWEKA');
	const [PracticeDetails, setPracticeDetails] = React.useState([]);
	const [OrderByName, setOrderByName] = React.useState('');
	const [PracticeID, setPracticeID] = React.useState('0');
	const [paypalDialog, setPaypalDialog] = React.useState(false);


	const wsGetPracticeByID = (ID, PlanID) => {
		// this.setState({ PlanID: PlanID })
		setPracticeID(ID);
		let Data = []
		APIController.GetPracticeByID(ID)
			.then((response) => {
				if (response.object.message == "") {
					Data = {
						"name": {
							"given_name": response.object.data[0].FirstName,
							"surname": response.object.data[0].LastName
						},
						"email_address": response.object.data[0].EmailAddress,
						"shipping_address": {
							"name": {
								"full_name": response.object.data[0].FirstName + " " + response.object.data[0].LastName
							},
							"address": {
								"address_line_1": response.object.data[0].Address1,
								"address_line_2": response.object.data[0].Address2,
								"admin_area_2": response.object.data[0].City,
								"admin_area_1": response.object.data[0].State,
								"postal_code": response.object.data[0].Postcode,
								"country_code": "US"
							}
						}
					}
					setPracticeDetails(Data)
					setOrderByName(response.object.data[0].FirstName + " " + response.object.data[0].LastName)
					setPaypalDialog(true)
					// this.setState({ PracticeDetails: Data, OrderByName: response.object.data[0].FirstName + " " + response.object.data[0].LastName })
				}
			})
	}

	const [message, setMessage] = React.useState("");
	const [showNotification, setShowNotification] = React.useState(false);
	const [isError, setIsError] = React.useState(false);

	const handleNotification = (message, isError) => {
		setMessage(message)
		setShowNotification(true)
		setIsError(isError)

		setTimeout(() => {
			handleClearNotification();
		}, 4000);
	};

	const handleClearNotification = () => {
		setMessage('')
		setShowNotification(false)
		setIsError(false)
	}


	return (
		<>
			<h1><PageTitle Title="View Subscriptions" /></h1> <div className="table-main-wrap">
				<PerfectScrollbar component='div'>
					<Table>
						<TableHead>
							<TableRow> {headerList.map((obj, index) => { return <TableCell style={index == 0 ? { minWidth: "10%" } : index == headerList.length - 2 ? { minWidth: '100px', textAlign: 'right' } : { width: '10%', minWidth: '110px' }} key={index}>{obj}</TableCell>; })} </TableRow>
							<TableRow>
								<TableCell> <span><img src={filtericon} alt="delete" /></span> </TableCell>
								<TableCell style={{ minWidth: "110px" }}> <input type="text" name="" placeholder="Subscription No" onChange={(e) => props.applyFilter('SubscriptionNo', e.target.value, 'input')} /> </TableCell>
								<TableCell> <input type="text" name="" placeholder="Practice Name" onChange={(e) => props.applyFilter('Practice', e.target.value, 'input')} /> </TableCell>
								<TableCell> <input type="text" name="" placeholder="Address" onChange={(e) => props.applyFilter('Address', e.target.value, 'input')} /> </TableCell>
								<TableCell> <input type="text" name="" placeholder="Subscribed At" onChange={(e) => props.applyFilter('SubscribedAt', e.target.value, 'input')} /> </TableCell>
								<TableCell style={{ minWidth: "100px" }}> <input type="text" name="" placeholder="Start Date" onChange={(e) => props.applyFilter('StartDate', e.target.value, 'input')} /> </TableCell>
								<TableCell style={{ minWidth: "130px" }}> <input type="text" name="" placeholder="Next Renewal Date" onChange={(e) => props.applyFilter('NextRenewalDate', e.target.value, 'input')} /> </TableCell>
								<TableCell style={{ minWidth: "100px" }}> <input type="text" name="" placeholder="Cost" onChange={(e) => props.applyFilter('PaidAmount', e.target.value, 'input')} style={{ textAlign: 'right' }} /> </TableCell>
								<TableCell> <input type="text" name="" placeholder="Status" onChange={(e) => props.applyFilter('SubscriptionStatus', e.target.value, 'input')} /> </TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{!!countryList.length ? countryList.map((obj, idnex) => {
								return (
									<TableRow key={idnex}>
										<TableCell style={{ minWidth: "100px" }}> <span>
											{obj.StatusID == "1" || obj.StatusID == "2" ?
												<>
													<img src={editIcon} alt="edit" style={{ cursor: 'pointer', marginRight: '8px' }}
														onClick={() => {
															props.wsGetCampaignProducts(obj.CampaignID, obj.ProductID, obj.FacebookID, obj.FacebookUsername, obj.InstagramID, obj.InstagramUsername, obj.FacebookUserID)
															AppConstants.SetLocalStorage("localStorageAutomationSubscriptionID", obj.ID)
															AppConstants.SetLocalStorage("localStoragepracticeId", obj.PracticeID)
															AppConstants.SetLocalStorage("localStorageBrandsData", [])
															AppConstants.SetLocalStorage("localStorageFacebookUserID", obj.FacebookUserID)
														}} />
													<img src={deleteIcon} alt="delete" style={{ cursor: 'pointer', marginRight: '8px', width: '16px' }}
														onClick={() => { setDeleteId(obj.ID); setLoginDialog(true) }} />
												</> : null}

											{obj.StatusID == "2" &&
												obj.StartSubscribeFrom != null &&
												obj.StartSubscribeFrom != undefined &&
												moment(obj.StartSubscribeFrom).format('YYYY-MM-DD') <= moment(new Date()).format('YYYY-MM-DD') &&
												<img src={paypalicon} alt="delete" style={{ cursor: 'pointer', marginRight: '8px', width: '16px' }}
													onClick={() => { wsGetPracticeByID(obj.PracticeID); }} />}

											{/* <img src={ic_orders} alt="delete" style={{ cursor: 'pointer', width: "17px" }}
												onClick={() => { props.history.push({ pathname: '/order', state: { countryIdEdit: obj.ID, countryNameEdit: obj.Name, countryActiveEdit: obj.IsActiveLABEL === "YES" ? 1 : 0 } }) }} /> */}
										</span>
										</TableCell>
										<TableCell style={{ minWidth: "135px" }}>{obj.SubscriptionNo}</TableCell>
										<TableCell style={{ minWidth: "230px" }}>{obj.Practice}</TableCell>
										<TableCell style={{ minWidth: "230px" }}>{obj.Address}</TableCell>
										<TableCell style={{ minWidth: "100px" }}>{obj.SubscribedAt}</TableCell>
										<TableCell style={{ minWidth: "100px" }}>{obj.StartDate}</TableCell>
										<TableCell style={{ minWidth: "130px" }}>{obj.RenewalDate}</TableCell>
										<TableCell style={{ minWidth: "100px", textAlign: 'right' }}>{obj.PaidAmount}</TableCell>
										<TableCell style={{ textAlign: 'center' }} className={obj.SubscriptionStatus === "Started" ? 'green' : obj.SubscriptionStatus === "Cancelled" ? 'red' : ''}>
											<span>{obj.SubscriptionStatus}</span></TableCell>
									</TableRow>
								);
							}) : <TableRow>
								<TableCell colSpan={headerList.length} className="not_found">
									{props.progress ?
										<CircularProgress /> :
										<label>No Subscription Found</label>}
								</TableCell>
							</TableRow>}
						</TableBody>
					</Table>
				</PerfectScrollbar>
			</div>
			<Notification
				isError={isError}
				message={message}
				showNotification={showNotification}
				closeNotification={handleClearNotification.bind(this)}
			/>
			{LoginDialog && (
				<Modal
					show={LoginDialog}
					handleClose={() => setLoginDialog(false)}>

					<ConfirmtionDialog
						title={"Cancel Subscription"}
						description={"Are you sure you want to Cancel this Subscription?"}
						deletePress={() => wsDeleteCalendar(DeleteId)}
						closeModal={() => setLoginDialog(false)}
						btnText={"Cancel"}
					/>
				</Modal>
			)}

			{paypalDialog && (
				<Modal
					show={paypalDialog}
					handleClose={() => setPaypalDialog(false)}>

					<PaypalPopup
						title={"Subscription"}
						description={"Are you sure you want to Subscribe this Subscription?"}
						// deletePress={() => wsDeleteCalendar(DeleteId)}
						closeModal={() => setPaypalDialog(false)}
						PracticeDetails={PracticeDetails}
						PlanID={PlanID}
						OrderByName={OrderByName}
					/>
				</Modal>
			)}

			<div className="table-pagination">
				<div className="pagination-info">
					<p>Showing {from + 1} - {to > count ? count : to} of {count}</p>
				</div>
				<div className="pagination-control">
					<a href="#!" title="" className={`double-left-arrow ${currentPage === 1 || currentPage === 0 ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(1)
						}}
					><img src={double_left_arrow} alt="left" /></a>
					<a href="#!" title="" className={`simple-left-arrow ${currentPage === 1 || currentPage === 0 ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(currentPage - 1)
						}}
					><img src={left_arrow} alt="single_left" /></a>
					<span>Page</span>
					<input type="text" name="textbox" value={currentPage === 0 ? currentPage + 1 : currentPage} onChange={(e) => handleChangeInCurrentPage(e.target.value)} />
					<span>of {page}</span>
					<a href="#!" title="" className={`simple-right-arrow ${currentPage === page ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(currentPage + 1)
						}}
					><img src={right_arrow} alt="right" /></a>
					<a href="#!" title="" className={`double-right-arrow ${currentPage === page ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(page)
						}}><img src={double_right_arrow} alt="single_right" /></a>
					<select onChange={(e) => props.handleChangeRowsPerPage(e.target.value)} defaultValue={rowsPerPage}>
						{
							rowsPerPageOptions.map((obj, index) => {
								return <option value={obj} key={index}>{obj}</option>
							})
						}
					</select>
				</div>
			</div>
		</>
	);
};

export default SubscriptionsList;
