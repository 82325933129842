import React from 'react';
import logo from "../../../assets/images/thank_you_page_header_logo.png";
import green_check_ic from "../../../assets/images/green-check-ic.svg";

import "../container/registration.css";

const ThankYouPage = props => {

	return (
		<>
			<Header />
			<section className="thank-you-page">
				<img src={green_check_ic} alt="ic" />
				<h1>Thank you for Registering!</h1>
				{props.history.location.state.ThankYouMsg == 1 ?
					<p> Congratulations!! Your registration is completed. <br />
						An email will be sent to your registered email address with your login credentials to the REACH Portal.</p> :
					<p>Upon verificaiton of your practice infomration,<br />We will forward your secure login credentials to the Portal by email</p>}
				<div className="button-field">
					<input type="button" value="Login" name="login" onClick={() => props.history.push("/login")} />
				</div>
			</section>
		</>
	)
}


const Header = (props) => {
	return (
		<>
			<header className='header-logo' style={{ padding: '20px' }}>
				<img src={logo} alt='logo' style={{ width: '225px' }} />
			</header>
		</>
	);
};
export default ThankYouPage;