
import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik'

import * as APIController from '../../../../api/APIController.js';
import * as yup from 'yup';
import { Grid } from '@material-ui/core';
import close_ic from '../../../../assets/images/close-ic.svg';
import Notification from '../../../../shared/components/notification/notification';
import TextField from "../../../../shared/components/TextField/TextField";
import forgot_user_ic from '../../../../assets/images/forgot-username-ic.svg';
import { ExcelRenderer } from 'react-excel-renderer';

const ReportDataForm = props => {
    let initialValues = { OrderDetailID: '', ReportTypeID: '', PracticeID: '', OrderID: '', LocationID: '', ReportDate: '' }
    const [IsLoading, setIsLoading] = React.useState(true);
    const [DataForDropDown, setDataForDropDown] = React.useState([]);
    const [LocationDataForDropDown, setLocationDataForDropDown] = React.useState([]);

    React.useEffect(() => {
        wsGetOrderListForDashboard()
    }, [])

    const [JSONData, setJSONData] = React.useState([]);
    const [TempConst, setTempConst] = React.useState("");
    const [ReportType, setReportType] = React.useState("");


    const [message, setMessage] = React.useState("");
    const [showNotification, setShowNotification] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const handleNotification = (message, isError) => {
        setMessage(message)
        setShowNotification(true)
        setIsError(isError)

        setTimeout(() => {
            handleClearNotification();
        }, 4000)
    }

    const handleClearNotification = () => {
        setMessage('')
        setShowNotification(false)
        setIsError(false)
    }

    const wsGetOrderListForDashboard = () => {
        APIController.GetOrderListForDashboard(props.OrderID, '1').then((response) => {
            setDataForDropDown(response.object.data)
            setLocationDataForDropDown(JSON.parse(response.object.data[0].Locations))
            setIsLoading(false)
        });
    }
    const wsImportReportData = (PracticeID, OrderID, OrderDetailID, ReportTypeID, LocationID, JSONData) => {
        APIController.ImportReportData(PracticeID, OrderID, OrderDetailID, ReportTypeID, LocationID, JSONData).then((response) => {
            setIsLoading(false)
            if (response.error == null) {
                if (response.object.status == 1) {
                    handleNotification(response.object.message);
                } else {
                    handleNotification(response.object.message, true);
                }
                props.RenderData()
                props.closeModal()
            }
            else if (response.error.message && response.error.message.length > 0) {
                handleNotification(response.error.message, true);
            }
        });
    }

    const ExcelDateToJSDate = (date) => {
        let converted_date = new Date(Math.round((date - 25569) * 864e5));
        converted_date = String(converted_date).slice(4, 15)
        date = converted_date.split(" ")
        let day = date[1];
        let month = date[0];
        month = "JanFebMarAprMayJunJulAugSepOctNovDec".indexOf(month) / 3 + 1
        if (month.toString().length <= 1)
            month = '0' + month
        let year = date[2];
        return String(month + '-' + day + '-' + year)
    }

    const fileHandler = (event) => {
        let fileObj = event.target.files[0];
        ExcelRenderer(fileObj, (err, resp) => {
            var obj = {};
            var mainobj = []

            console.log('ReportType:', ReportType)
            if (ReportType == "Facebook") {
                for (var i = 1; i < resp.rows.length; i++) {
                    for (var j = 0; j < resp.cols.length; j++) {
                        obj[resp.rows[0][j]] = resp.rows[i][j];
                    }
                    mainobj.push({
                        "ReportDate": ExcelDateToJSDate(obj.Date), "Clicks": obj.Clicks, "Impression": obj.Impressions, "CTR": obj.CTR, "CPC": obj.CPC, "Cost": obj.Cost,
                        "Certificate_Email": obj.Certificate_Emails, "Certificate_Download": obj.Certificate_Downloads,
                        "Appointment_Clicks": obj.Schedule_An_Appointment
                    });
                    obj = {};
                }
            } else if (ReportType == "MailChimp") {
                for (var i = 1; i < resp.rows.length; i++) {
                    for (var j = 0; j < resp.cols.length; j++) {
                        obj[resp.rows[0][j]] = resp.rows[i][j];
                    }
                    mainobj.push({ "ReportDate": ExcelDateToJSDate(obj.Date), "EmailAddress": obj.Email });
                    obj = {};
                }
            } else if (ReportType == "Display") {
                for (var i = 1; i < resp.rows.length; i++) {
                    for (var j = 0; j < resp.cols.length; j++) {
                        obj[resp.rows[0][j]] = resp.rows[i][j];
                    }
                    mainobj.push({
                        "ReportDate": ExcelDateToJSDate(obj.Date), "Clicks": obj.Clicks, "Impression": obj.Impressions, "CTR": obj.CTR, "CPC": obj.CPC, "Cost": obj.Cost,
                        "Certificate_Email": obj.Certificate_Emails, "Certificate_Download": obj.Certificate_Downloads,
                        "Appointment_Clicks": obj.Schedule_An_Appointment
                    });
                    obj = {};
                }
            } else if (ReportType == "Search") {
                for (var i = 1; i < resp.rows.length; i++) {
                    for (var j = 0; j < resp.cols.length; j++) {
                        obj[resp.rows[0][j]] = resp.rows[i][j];
                    }
                    mainobj.push({
                        "ReportDate": obj.ReportDate, "Clicks": obj.Clicks, "Impression": obj.Impressions, "CTR": obj.CTR, "CPC": obj.CPC, "Cost": obj.Cost,
                        "Certificate_Email": obj.Certificate_Emails, "Certificate_Download": obj.Certificate_Downloads,
                        "Appointment_Clicks": obj.Schedule_An_Appointment
                    });
                    obj = {};
                }
            }
            setJSONData(mainobj)
        });
    }

    return (
        <>
            <div className="popup-box forgot-popup-box" style={{ width: '10%' }}>
                <div className="box-heading">
                    <img src={forgot_user_ic} alt="icon" />
                    <h2 className="h1">{props.title}</h2>
                    <button className="close-btn"><img src={close_ic} alt="icon" onClick={props.closeModal} /></button>
                </div>


                <Formik
                    enableReinitialize={false}
                    initialValues={initialValues}
                    validationSchema={validateCountryForm}
                    validateOnMount={false}
                    onSubmit={(values) => { wsImportReportData(TempConst.PracticeID, TempConst.ID, values.OrderDetailID, values.ReportTypeID, values.LocationID, JSONData) }} >
                    {({
                        values,
                        handleSubmit,
                        setFieldValue,
                        errors,
                        touched,
                    }) => {
                        return (
                            <div className="form-main">
                                <form onSubmit={handleSubmit}>
                                    <div className={`form_field ${errors && touched.OrderDetailID && errors.OrderDetailID && errors.OrderDetailID !== '' ? 'error' : ''}`}>
                                        <div className="field-left">
                                            <label>Campaign </label>
                                        </div>
                                        <div className="field-right">
                                            {IsLoading ? <h2>loading....</h2> :
                                                <select name='OrderDetailID' value={values.OrderDetailID} onChange={(e, index) => {
                                                    setFieldValue('OrderDetailID', e.target.value);
                                                    setTempConst(DataForDropDown.find((obj) => obj.OrderDetailsID == e.target.value))
                                                }}>
                                                    <option value={''}>{'Select Campaign'}</option>
                                                    {DataForDropDown != null && DataForDropDown.map((obj, index) => {
                                                        return <option key={index} selected={obj.OrderDetailsID == values.OrderDetailsID ? obj.OrderDetailsID : obj.Name} value={obj.OrderDetailsID}>{obj.Name}</option>
                                                    })}
                                                </select>}

                                            {errors && touched.OrderDetailID && errors.OrderDetailID && errors.OrderDetailID !== '' && (
                                                <span className='error-msg'>
                                                    <ErrorMessage name='OrderDetailID' render={(err) => { return err; }} />
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    <div className={`form_field ${errors && touched.LocationID && errors.LocationID && errors.LocationID !== '' ? 'error' : ''}`}>
                                        <div className="field-left">
                                            <label>Campaign </label>
                                        </div>
                                        <div className="field-right">
                                            {IsLoading ? <h2>loading....</h2> :
                                                <select name='LocationID' value={values.LocationID} onChange={(e, index) => {
                                                    setFieldValue('LocationID', e.target.value);
                                                }}>
                                                    <option value={''}>{'Select Location'}</option>
                                                    {LocationDataForDropDown != null && LocationDataForDropDown.map((obj, index) => {
                                                        return <option key={index} selected={obj.ID == values.LocationID ? obj.ID : obj.Location} value={obj.ID}>{obj.Location}</option>
                                                    })}
                                                </select>}

                                            {errors && touched.LocationID && errors.LocationID && errors.LocationID !== '' && (
                                                <span className='error-msg'>
                                                    <ErrorMessage name='LocationID' render={(err) => { return err; }} />
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    <div className={`form_field ${errors && touched.ReportTypeID && errors.ReportTypeID && errors.ReportTypeID !== '' ? 'error' : ''}`}>
                                        <div className="field-left">
                                            <label>Report Type</label>
                                        </div>
                                        <div className="field-right">
                                            <select

                                                name='ReportTypeID'
                                                value={values.ReportTypeID}
                                                onChange={(e, index) => { setFieldValue('ReportTypeID', e.target.value); setReportType(e.target.value) }}>
                                                <option value=''>Select Report Type</option>
                                                <option value='Facebook'>Facebook</option>
                                                <option value='MailChimp'>MailChimp</option>
                                                <option value='Display'>Display</option>
                                                <option value='Search'>Search</option>
                                            </select>

                                            {errors && touched.ReportTypeID && errors.ReportTypeID && errors.ReportTypeID !== '' && (
                                                <span className='error-msg'>
                                                    <ErrorMessage name='ReportTypeID' render={(err) => { return err; }} />
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    <div className={`form_field`}>
                                        <div className="field-left">
                                            <label>Select CSV</label>
                                        </div>
                                        <div className="field-right">
                                            <div className={values.ReportTypeID == '' ? "button-field file-upload-btn disable-btn" : "button-field file-upload-btn"} style={{ textAlign: 'center', minWidth: '155px' }}>
                                                <input type="file" id='fileupload' name='files' accept=".csv" onChange={fileHandler.bind(this)} />
                                                <label>{"Upload"}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <Grid container direction={'row'} style={{ textAlign: 'center', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                        <div className={IsLoading ? 'form-field button-field disable-btn' : 'form-field button-field'}>
                                            <input type="submit" name="save" value={IsLoading ? "Saving..." : "Save"} />
                                        </div>
                                    </Grid>

                                    <Notification
                                        isError={isError}
                                        message={message}
                                        showNotification={showNotification}
                                        clearNotification={handleClearNotification.bind(this)}
                                        closeNotification={handleClearNotification.bind(this)}
                                    />
                                </form>
                            </div>
                        )
                    }}
                </Formik>
            </div>
        </>
    )
}
const validateCountryForm = yup.object().shape({
    OrderDetailID: yup.string().required('Select Campaign'),
    LocationID: yup.string().required('Select Location'),
    ReportTypeID: yup.string().required('Select Report Type')
});


export default ReportDataForm;