import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import '../../home/container/DashboardStyle.css'
import 'react-daterange-picker/dist/css/react-calendar.css' // For some basic styling. (OPTIONAL)
import styles from './analytics.style';
import moment from 'moment';
import '../../home/container/DashboardStyle.css'
import { Line } from 'react-chartjs-2';
import scss from './components/tabbed-chart-widget/tabbed-chart-widget.module.scss';
import { Doughnut } from 'react-chartjs-2';

const legendOptions = {
    display: false
};
class Analytics extends React.Component {
    state = {
        StartDate: 'Feb/25/2021',
        EndDate: 'Feb/28/2021',
        open: false,
        IsCard: '',
        doughnutChartData: {
            labels: ['Facebook         ', 'Instagram', 'Google Display ', ' Google search',],
            datasets: [{
                data: this.props.Engagement.slice(1),
                borderWidth: 0,
                backgroundColor: [
                    '#1073C6',
                    '#02ABAE',
                    '#6EC496',
                    '#0A5C7F',
                ],
                hoverBackgroundColor: '#CCCCCC'
            }]
        },
        doughnutChartOptions: {

            responsive: true,
            maintainAspectRatio: false,
            tooltips: {
                enabled: true,
                backgroundColor: '#1588ba',
                titleFontColor: '#fff',
                bodyFontColor: '#fff',
                xPadding: 20,
                yPadding: 20,
                displayColors: false
            }
        },

    }


    onSelect = dates => {
        this.setState({ StartDate: moment(dates.start._d).format("DD/MM/YYYY"), EndDate: moment(dates.end._d).format("DD/MM/YYYY"), open: false })
    }

    render() {
        const { StartDate, EndDate, open, IsCard } = this.state;

        return (
            <div  >
                <Paper style={{ padding: "15px", margin: '5px' }}>
                    <Grid container direction={'row'} style={{ justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        <Typography className={'title-main'} variant="subheading">{'Campaign Performance by Channels'}</Typography>
                        {/* <Grid container xs={3} sm={3} style={{ padding: '8px', position: 'relative', border: '1px solid #cccc', justifyContent: 'space-between', alignItems: 'center' }} >
              <Typography style={{ fontSize: '15px', }} className={'title-content'} variant="subheading">{StartDate}</Typography>
              {'-'}
              <Typography style={{ fontSize: '15px', }} className={'title-content'} variant="subheading">{EndDate}</Typography>
              <DateRangeIcon style={{ color: '#CCCCCC' }} onClick={() => this.setState({ open: !this.state.open })} />
              {open &&
                <div style={{ border: '1px solid #cccc', zIndex: 1000, position: 'absolute', top: 25, backgroundColor: '#fff', right: 20 }}>
                  <DateRangePicker
                    selectionType='range'
                    onSelect={this.onSelect}
                  />
                </div>}
            </Grid> */}
                    </Grid>

                    <Grid style={{ marginBottom: '10px', marginTop: '10px', borderBottom: '2.5px solid #F5F7FA', width: '100%' }} />
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container justify="space-between" >
                            <Grid item key={1} xs={12} sm={12} md={9} lg={9} xl={9}>

                                <div >
                                    <Line
                                        height={90}
                                        data={{
                                            labels: this.props.AllWeeks,
                                            datasets: [{
                                                label: "Google",
                                                fill: true,
                                                lineTension: 0.1,// The main line color
                                                borderColor: "#669999",
                                                backgroundColor: "rgb(102, 153, 153,0.5)",
                                                borderCapStyle: 'square',
                                                borderDash: [], // try [5, 15] for instance
                                                borderDashOffset: 0.0,
                                                borderJoinStyle: 'miter',
                                                borderWidth: '30px',
                                                data: this.props.GoogleData,
                                                spanGaps: true,
                                                pointBorderColor: "#669999",
                                                pointBackgroundColor: "#669999",
                                                pointBorderWidth: 0,
                                                pointHoverRadius: 0,
                                                pointHoverBackgroundColor: "yellow",
                                                pointHoverBorderColor: "brown",
                                                pointHoverBorderWidth: 0,
                                                pointRadius: 1,
                                                pointHitRadius: 1,
                                            }
                                                , {
                                                label: "Social",
                                                fill: true,
                                                lineTension: 0.1,
                                                borderColor: '#02ABAE',
                                                borderWidth: '30px',
                                                backgroundColor: "rgb(2, 171, 174,0.5)",
                                                borderCapStyle: 'square',
                                                borderDash: [],
                                                borderDashOffset: 0.0,
                                                borderJoinStyle: 'miter',
                                                data: this.props.SearchData,
                                                spanGaps: true,
                                                pointBorderColor: "#02ABAE",
                                                pointBackgroundColor: "#02ABAE",
                                                pointBorderWidth: 0,
                                                pointHoverRadius: 0,
                                                pointHoverBackgroundColor: "yellow",
                                                pointHoverBorderColor: "brown",
                                                pointHoverBorderWidth: 0,
                                                pointRadius: 1,
                                                pointHitRadius: 1,
                                            },
                                            ]
                                        }}
                                        options={this.state.lineChartOptions}
                                        legend={legendOptions}
                                    />
                                </div>

                            </Grid>

                            {/* <TabbedChartWidget IsCard={this.props.IsCard} /> */}
                            <Grid item key={2} xs={12} sm={12} md={3} lg={3} xl={3}>
                                <div style={{ padding: "10px", backgroundColor: '#F5F7FA' }}>
                                    <Typography className={'title-main'} variant="subheading">{'Top Campaign Performance'}</Typography>
                                    <Grid style={{ marginBottom: '10px', marginTop: '10px', borderBottom: '2.5px solid #6464641A', width: '100%' }} />
                                    <div className={scss['portal-doughnut-widget']}>
                                        <div className={scss['portal-doughnut-widget__chart']} >
                                            <Doughnut
                                                height={200}
                                                data={{
                                                    labels: ['Facebook          ', 'Display        '],
                                                    datasets: [{
                                                        data: this.props.Engagement.slice(1),
                                                        borderWidth: 0,
                                                        backgroundColor: [
                                                            '#1073C6',
                                                            '#02ABAE',
                                                            '#6EC496',
                                                            '#0A5C7F',
                                                        ],
                                                        hoverBackgroundColor: '#CCCCCC'
                                                    }],
                                                    doughnutChartOptions: {
                                                        responsive: true,
                                                        maintainAspectRatio: true,
                                                        tooltips: {
                                                            enabled: true,
                                                            backgroundColor: '#1588ba',
                                                            titleFontColor: '#fff',
                                                            bodyFontColor: '#fff',
                                                            xPadding: 20,
                                                            yPadding: 20,
                                                            displayColors: false
                                                        }
                                                    }
                                                }}
                                                options={{
                                                    animateRotate: true,
                                                    animateScale: false,
                                                    animation: {
                                                        duration: 2000,
                                                    },
                                                    legend: {
                                                        position: "bottom", align: "center", labels: {
                                                            boxWidth: 30,
                                                            fontSize: 12,
                                                            fontColor: '#646464'
                                                        }
                                                    },
                                                    datalabels: {
                                                        display: true,
                                                        color: "white",
                                                    },
                                                    tooltips: {
                                                        backgroundColor: "#5a6e7f",
                                                    },
                                                }}

                                            />
                                        </div>
                                    </div>

                                </div>
                            </Grid>

                        </Grid>
                    </Grid>
                </Paper>
            </div>
        );
    };
}

let final = Analytics.propTypes = {
    classes: PropTypes.shape({}).isRequired
};

export default withStyles(styles, { withTheme: true })(Analytics);

