import React from 'react';
import PageTitle from "../../../../config/pageTitle";

import ModalComponent from "../../../../shared/components/modal/modal";
import close_icon from "../../../../assets/images/gray-close-ic.svg";

import Modal from '../../../../shared/components/modal/modal';
import ConfirmtionDialog from '../../../../features/ConfirmDialog/ConfirmationDialog';
import AddEditCalendarPopup from '../../../../features/admin/container/calendar/addEditCalendarPopup';
import * as APIController from '../../../../api/APIController.js';
import * as AppConstants from "../../../../config/AppConstant";

import close_ic from '../../../../assets/images/close-ic.svg';
import forgot_user_ic from '../../../../assets/images/forgot-username-ic.svg';
import alarm from '../../../../assets/images/icon/alarm.png';
import tick from '../../../../assets/images/icon/tick.png';
import deleteIcon from '../../../../assets/images/icon/delete.png'
import hold from '../../../../assets/images/icon/hold.png';
import error from '../../../../assets/images/icon/error.png';

const SubscriptionOrderCalendar = props => {
    const DAY = new Date();
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const [MonthData, setWeekDay] = React.useState([{ "ID": 1, "Name": "Sun" }, { "ID": 2, "Name": "Mon" }, { "ID": 3, "Name": "Tue" }, { "ID": 4, "Name": "Wed" }, { "ID": 5, "Name": "Thu" }, { "ID": 6, "Name": "Fri" }, { "ID": 7, "Name": "Sat" }]);

    const [IsLoading, setIsLoading] = React.useState(true);
    const [CurrentDate, setCurrentDate] = React.useState(DAY.getDate());
    const [CurrentMonth, setCurrentMonth] = React.useState(DAY.getMonth() + 1);
    const [CurrentYear, setCurrentYear] = React.useState(DAY.getFullYear());
    const [CurrentMonthName, setCurrentMonthName] = React.useState(monthNames[DAY.getMonth()]);
    const [CurrentDayName, setCurrentDayName] = React.useState(DAY.getDay());
    const [TotalDayOfMonth, setTotalDayOfMonth] = React.useState([]);
    const [TotalCards, setTotalCards] = React.useState([]);
    const [CalendarAssetsData, setCalendarAssetsData] = React.useState([]);
    const [PracticeMonthData, setPracticeMonthData] = React.useState([]);

    const [firstDateWeek, setfirstDateWeek] = React.useState(new Date(CurrentYear, CurrentMonth - 1, 1).getDay() + 1);

    const [PreviewModalData, setPreviewModalData] = React.useState(null);
    const [LoginDialog, setLoginDialog] = React.useState(false);
    const [DeleteId, setDeleteId] = React.useState('');
    const [DeleteStatus, setDeleteStatus] = React.useState('');
    const [EditID, setEditID] = React.useState(0);
    const [loadingImage, setloadingImage] = React.useState(true);

    React.useEffect(() => {
        var pairs = [];
        if (props.CalendarMonth != "" || props.CalendarYear != '') {
            if (props.isSetYear) {
                setCurrentYear(props.CalendarYear)
                setCurrentMonth(parseInt(props.CalendarMonth))
            }
            setCurrentMonthName(monthNames[props.CalendarMonth - 1])
            setfirstDateWeek(new Date(props.CalendarYear, props.CalendarMonth - 1, 1).getDay() + 1)

            for (let i = 0; i < new Date(CurrentYear, props.CalendarMonth, 0).getDate(); i++) {
                pairs.push({ Date: i + 1 });
            }
        } else {
            for (let i = 0; i < new Date(CurrentYear, CurrentMonth + 1, 0).getDate(); i++) {
                pairs.push({ Date: i + 1 });
            }
        }
        setTotalDayOfMonth(pairs)

        var cards = [];
        for (let i = 0; i < 42; i++) {
            if (i < pairs.length + new Date(props.CalendarYear, props.CalendarMonth - 1, 1).getDay()) {
                cards.push({ ID: i + 1 });
            }

        }
        setTotalCards(cards)

        // setPracticeMonthData(props.PMonthData)
        setCalendarAssetsData(props.CalendarAssetsData)

        setIsLoading(false)

    }, [props])

    const nextMonth = (date, currentMonth, currentYear, isAdmin) => {
        setIsLoading(true)
        setCurrentMonth(currentMonth + 1)
        var current = ''
        if (currentMonth == 11) {
            current = new Date(date.getFullYear() + 1, -1, 1);
            setfirstDateWeek(current.getDay() + 1)
            setCurrentMonthName(monthNames[current.getMonth()])
            setCurrentYear(current.getFullYear())
        } else {
            current = new Date(date.getFullYear(), currentMonth, 1);
            setfirstDateWeek(current.getDay() + 1)
            setCurrentMonthName(monthNames[current.getMonth()])
            setCurrentYear(current.getFullYear())
        }
        var daysInMonth = new Date(current.getFullYear(), current.getMonth() + 1, 0).getDate();
        var pairs = [];
        for (let i = 0; i < daysInMonth; i++) {
            pairs.push({ Date: i + 1 });
        }
        setTotalDayOfMonth(pairs)

        props.handlebindAssetsData(current.getMonth() + 1, current.getFullYear())

        setIsLoading(false)
    }

    const previousMonth = (date, currentMonth, currentYear, isAdmin) => {
        setIsLoading(true)
        setCurrentMonth(currentMonth - 1)
        var current = ''
        if (currentMonth == 1) {
            current = new Date(currentYear, -1, 1);
            setfirstDateWeek(current.getDay() + 1)
            setCurrentMonthName(monthNames[current.getMonth()])
            setCurrentYear(current.getFullYear())
        } else {
            current = new Date(date.getFullYear(), currentMonth - 2, 1);
            setfirstDateWeek(current.getDay() + 1)
            setCurrentMonthName(monthNames[current.getMonth()])
            setCurrentYear(current.getFullYear())
        }

        var daysInMonth = new Date(current.getFullYear(), current.getMonth() + 1, 0).getDate();
        var pairs = [];
        for (let i = 0; i < daysInMonth; i++) {
            pairs.push({ Date: i + 1 });
        }
        setTotalDayOfMonth(pairs)
        props.handlebindAssetsData(current.getMonth() + 1, current.getFullYear())

        setIsLoading(false)
    }

    return (
        <div className="popup-box forgot-popup-box" style={{ minWidth: '1250px' }}>
            <div className="box-heading">
                <img src={forgot_user_ic} alt="icon" />
                {/* {props.history.location.state.countryIdEdit == 0 ? <h1>Add Country</h1> : <h1>Edit Country</h1>} */}
                <h2 className="h1">{"Calendar " + CurrentYear + ", " + CurrentMonthName}</h2>
                <button className="close-btn"><img src={close_ic} alt="icon" onClick={props.closeModal} /></button>
            </div>

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div className='form-field button-field' style={{ marginBottom: '20px' }}>
                    <input type="button" name="edit-profile" value="Previous" onClick={() => { previousMonth(new Date(), CurrentMonth, CurrentYear, true); }} />
                </div>
                <div className='form-field button-field' style={{ marginBottom: '20px' }}>
                    <input type="button" name="edit-profile" value="Next" onClick={() => nextMonth(new Date(), CurrentMonth, CurrentYear, true)} />
                </div>
            </div>

            <div style={{ border: '1px solid #D3D3D3', minWidth: "900px", overflow: 'auto', padding: '15px' }}>
                <div style={{ display: 'flex', width: '100%', gridGap: '5px' }}>{MonthData.map((Item) => {
                    return (
                        <div style={{ width: '100%', textAlign: 'center', padding: '2px 4px', border: '1px solid #D3D3D3' }}>{Item.Name}</div>
                    )
                })}
                </div>

                <div style={{ display: 'grid', height: "1225px", gridTemplateColumns: '13.95% 13.93% 13.91% 13.89% 13.95% 13.95% 13.91%', gridTemplateRows: '224px 224px 224px 224px 224px 224px', gridGap: '5px' }}>
                    {!IsLoading && TotalCards.map((Item, Index) => {
                        return (
                            <div style={{ textAlign: 'center', padding: '2px 4px', border: '1px solid #D3D3D3', backgroundColor: '#fcfcfc', }}>

                                {TotalDayOfMonth.map((item, index) =>
                                    index == Index - firstDateWeek + 1 &&
                                    <>
                                        <div className="daygrid-day-top"> {item.Date} </div>
                                        {CalendarAssetsData.map((itm, ind) => {
                                            return (
                                                itm.ScheduledAt.split("-")[2] == CurrentYear &&
                                                itm.ScheduledAt.split("-")[0] == CurrentMonth &&
                                                itm.ScheduledAt.split("-")[1] == item.Date &&
                                                <div className="two-col-sec-calendar" key={ind}>
                                                    <div className="content-box-calendar">

                                                        {itm.Status == 1 ? <img src={alarm} alt='icon' />
                                                            : itm.Status == 2 ? <img src={tick} alt='icon' />
                                                                : itm.Status == 3 ? <img src={deleteIcon} alt='icon' />
                                                                    : itm.Status == 4 ? <img src={hold} alt='icon' />
                                                                        : itm.Status == 5 ? <img src={error} alt='icon' />
                                                                            : <></>}

                                                        <div className="box-image-calendar calendar-image-box" style={{ backgroundImage: `url(${itm.ImageURL} )` }}>
                                                            {/* <img src={itm.ImageURL} alt="itm.ImageURL" /> */}
                                                            <div className="button-hover" style={AppConstants.GetLocalStorage("headerMenuAdmin") == 1 ? { padding: '15% 10px' } : { padding: '25% 10px' }}>
                                                                {AppConstants.GetLocalStorage("headerMenuAdmin") == 1 && itm.Remarks != null && itm.Remarks != '' &&
                                                                    <div className="button-field" >
                                                                        <button className="calendar-hover-button" onClick={() => {
                                                                            setPreviewModalData({
                                                                                isModalOpen: true,
                                                                                content: { image: "", contentText: itm.Remarks }
                                                                            }); setloadingImage(false)
                                                                        }}>{"Remarks"}</button>
                                                                    </div>
                                                                }
                                                                <div className="button-field">
                                                                    <button className="calendar-hover-button" onClick={() => {
                                                                        setPreviewModalData({
                                                                            isModalOpen: true,
                                                                            content: { image: itm.ImageURL, contentText: itm.Description }
                                                                        }); setloadingImage(false)
                                                                    }}>{"Preview"}</button>
                                                                </div>
                                                                {AppConstants.GetLocalStorage("localStorageCalenderCart") == "1" && <div className="button-field">
                                                                    <button className="calendar-hover-button"
                                                                        onClick={() => {
                                                                            setEditID(0)
                                                                            setDeleteId(itm.ID)
                                                                            setDeleteStatus(itm.Status)
                                                                            setLoginDialog(true)
                                                                        }}>{"Delete"}</button>
                                                                </div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="box-content-info-calendar" >
                                                            <label htmlFor={"Test Dta text on biotrue  30-08-2021 Test Dta text on biotrue"} style={{ fontSize: '14px' }}>{"Test Dta text on biotrue  30-08-2021 Test Dta text on biotrue"}</label>
                                                                        </div> */}
                                                </div>
                                            )
                                        })}
                                    </>
                                    // :
                                    // <>{!index == Index - firstDateWeek + 1 && <div className="daygrid-day-top">{"2"}</div>}</>
                                )}
                            </div>

                        )
                    })}
                </div>

            </div>

            {PreviewModalData && PreviewModalData.isModalOpen && (
                <ModalComponent
                    show={PreviewModalData.isModalOpen}
                    handleClose={() => setPreviewModalData(null)}>
                    <div className="popup-wrap">
                        <div className="two-col-sec digital-welcome" style={{ padding: '0px', marginTop: '0px', boxShadow: 'none' }}>
                            {PreviewModalData.content.image != "" ? <>
                                <div className="sec-left" style={{ marginTop: '0px' }} >
                                    <div style={{ letterSpacing: '0px' }}>
                                        <div dangerouslySetInnerHTML={{ __html: PreviewModalData.content.contentText }} />
                                    </div>
                                </div>
                                <div className="sec-right" style={{ padding: '0px', marginBottom: '10px' }}>
                                    <img src={PreviewModalData.content.image} alt="image" />
                                </div> </>
                                : <div className="sec-left" style={{ marginTop: '0px' }} >
                                    <div style={{ letterSpacing: '0px' }}>
                                        <div dangerouslySetInnerHTML={{ __html: PreviewModalData.content.contentText }} />
                                    </div>
                                </div>}
                        </div>
                        <a title="" className="gray-close-ic">
                            <img src={close_icon} alt="icon" onClick={() => setPreviewModalData(null)} />
                        </a>
                    </div>
                </ModalComponent>
            )}
        </div >
    )
}

export default SubscriptionOrderCalendar;